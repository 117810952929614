import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { TaxDocumentDataForm, TaxDocumentFilters } from '../../models/TaxDocument';
import { ServiceEvents } from '../shared/interfaces';

const useTaxDocumentService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetTaxDocuments, setFetchingGetTaxDocuments] = useState(false);
    const [fetchingShowTaxDocument, setFetchingShowTaxDocument] = useState(false);
    const [fetchingStoreTaxDocument, setFetchingStoreTaxDocument] = useState(false);
    const [fetchingStoreNote, setFetchingStoreNote] = useState(false);
    const [fetchingEditTaxDocument, setFetchingEditTaxDocument] = useState(false);
    const [fetchingCreateTaxDocument, setFetchingCreateTaxDocument] = useState(false);
    const [fetchingCreateNote, setFetchingCreateNote] = useState(false);
    const [fetchingUpdateTaxDocument, setFetchingUpdateTaxDocument] = useState(false);
    const [fetchingDeleteTaxDocument, setFetchingDeleteTaxDocument] = useState(false);
    const [fetchingActiveTaxDocument, setFetchingActiveTaxDocument] = useState(false);
    const [fetchingGetTaxDocument, setFetchingGetTaxDocument] = useState(false);
    const [fetchingDraftTaxDocument, setFetchingDraftTaxDocument] = useState(false);
    const [fetchingAnulledTaxDocument, setFetchingAnulledTaxDocument] = useState(false);
    const [fetchingAccountedTaxDocument, setFetchingAccountedTaxDocument] = useState(false);
    const [fetchingGetTaxDocumentDetails, setFetchingGetTaxDocumentDetails] = useState(false);
    const [fetchingGetCauseNote, setFetchingGetCauseNote] = useState(false);
    const [fetchingUpdateNote, setFetchingUpdateNote] = useState(false);
    const [fetchingCalculateTotalNote, setFetchingCalculateTotalNote] = useState(false);
    const [fetchingGetPaymentStatusesByClientSociety, setFetchingGetPaymentStatusesByClientSociety] = useState(false);
    
    const getTaxDocuments = (filters: TaxDocumentFilters,  events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TAX_DOCUMENT.GET_TAX_DOCUMENT}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTaxDocuments
        });
    };

    const showTaxDocument = (id: number,  events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX_DOCUMENT.SHOW_TAX_DOCUMENT.replace(':id', id.toString()),
            setFetching: setFetchingShowTaxDocument
        });
    };

    const storeTaxDocument = (taxDocument: TaxDocumentDataForm,  events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TAX_DOCUMENT.STORE_SALES_INVOICE,
            body: taxDocument,
            setFetching: setFetchingStoreTaxDocument
        });
    };

    const storeNote = (taxDocument: TaxDocumentDataForm,  events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TAX_DOCUMENT.STORE_NOTE,
            body: taxDocument,
            setFetching: setFetchingStoreNote
        });
    };

    const editTaxDocument = (id: number,  events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX_DOCUMENT.EDIT_TAX_DOCUMENT.replace(':id', id.toString()),
            setFetching: setFetchingEditTaxDocument
        });
    };

    // const createTaxDocument = ( events: ServiceEvents = {}) => {
    //     doGet({
    //         ...events,
    //         url: EndPoints.CREDIT_NOTE.CREATE_CREDIT_NOTE,
    //         setFetching: setFetchingCreateTaxDocument
    //     });
    // };

    const createTaxDocument = ( events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX_DOCUMENT.CREATE_SALES_INVOICE,
            setFetching: setFetchingCreateTaxDocument
        });
    };

    const createNote = ( events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX_DOCUMENT.CREATE_NOTE,
            setFetching: setFetchingCreateNote
        });
    };

    const updateTaxDocument = (
        id: number,
        taxDocument: TaxDocumentDataForm,
        events: ServiceEvents = {},
        isFolioAndDateOnly: boolean  // Se agrega este parámetro opcional
    ) => {
        doPut({
            ...events,
            url: EndPoints.TAX_DOCUMENT.UPDATE_SALES_INVOICE.replace(':id', id.toString()),
            body: { ...taxDocument, isFolioAndDateOnly }, // Agregar `isFolioAndDateOnly` al cuerpo de la solicitud
            setFetching: setFetchingUpdateTaxDocument
        });
    };

    const updateNote = (
        id: number,
        taxDocument: TaxDocumentDataForm,
        events: ServiceEvents = {},
        isFolioAndDateOnly: boolean  // Se agrega este parámetro opcional
    ) => {
        doPut({
            ...events,
            url: EndPoints.TAX_DOCUMENT.UPDATE_NOTE.replace(':id', id.toString()),
            body: { ...taxDocument, isFolioAndDateOnly }, // Agregar `isFolioAndDateOnly` al cuerpo de la solicitud
            setFetching: setFetchingUpdateNote
        });
    };

    const deleteTaxDocument = (id: number,  events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TAX_DOCUMENT.DELETE_TAX_DOCUMENT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteTaxDocument
        });
    };

    const activeTaxDocument = (id: number, active: boolean,  events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TAX_DOCUMENT.ACTIVE_TAX_DOCUMENT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveTaxDocument
        });
    };

    const getTaxDocument = (id: number,  events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX_DOCUMENT.GET_TAX_DOCUMENT.replace(':id', id.toString()),
            setFetching: setFetchingGetTaxDocument
        });
    };

    const getCauseNote = (noteType: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX_DOCUMENT.GET_CAUSE_NOTE.replace(':note_type', noteType),
            setFetching: setFetchingGetCauseNote
        });
    };

    const taxDocumentDraft = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TAX_DOCUMENT.DRAFT.replace(':id', id.toString()),
            setFetching: setFetchingDraftTaxDocument
        });
    };

    const taxDocumentAccounted = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TAX_DOCUMENT.ACCOUNTED.replace(':id', id.toString()),
            setFetching: setFetchingAccountedTaxDocument
        });
    };

    const taxDocumentAnnulled = (id: number, data: { reason: string }, events: ServiceEvents = {}) => {
        // Hacemos un PUT enviando la razón de anulación en el cuerpo de la solicitud
        doPut({
            ...events,
            url: EndPoints.TAX_DOCUMENT.ANNULLED.replace(':id', id.toString()),
            body: data,  // Enviamos el objeto completo
            setFetching: setFetchingAnulledTaxDocument
        });
    };

    const getTaxDocumentDetails = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TAX_DOCUMENT_DETAIL.GET_TAX_DOCUMENT_DETAILS.replace(':id', id.toString()),
            setFetching: setFetchingGetTaxDocumentDetails,
        });
    };

    const getPaymentStatusesByClientSociety = (societyId: number, clientId: number, events: ServiceEvents = {}) => {
        // const url = `${EndPoints.CREDIT_NOTE.GET_PAYMENT_STATUSES_BY_CLIENT_SOCIETY}?society_id=${societyId}&client_id=${clientId}`;
        const url = `${EndPoints.TAX_DOCUMENT.GET_PAYMENT_STATUSES_BY_CLIENT_SOCIETY.replace(':society_id', societyId.toString()).replace(':client_id', clientId.toString())}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetPaymentStatusesByClientSociety
        });
    };

    const calculateTotalNote = (taxDocument: { total_unit: number, cant: number, taxes: any[] }, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TAX_DOCUMENT.CALCULATE_TOTAL_NOTE,
            body: taxDocument,
            setFetching: setFetchingCalculateTotalNote
        });
    };


    return {
        fetchingGetTaxDocuments,
        fetchingShowTaxDocument,
        fetchingStoreTaxDocument,
        fetchingStoreNote,
        fetchingEditTaxDocument,
        fetchingUpdateTaxDocument,
        fetchingDeleteTaxDocument,
        fetchingActiveTaxDocument,
        fetchingCreateTaxDocument,
        fetchingCreateNote,
        fetchingGetTaxDocument,
        fetchingDraftTaxDocument,
        fetchingAnulledTaxDocument,
        fetchingAccountedTaxDocument,
        fetchingGetTaxDocumentDetails,
        fetchingGetPaymentStatusesByClientSociety,
        fetchingGetCauseNote,
        fetchingUpdateNote,
        fetchingCalculateTotalNote,
        getTaxDocuments,
        showTaxDocument,
        storeTaxDocument,
        storeNote,
        createTaxDocument,
        createNote,
        editTaxDocument,
        updateTaxDocument,
        deleteTaxDocument,
        activeTaxDocument,
        getTaxDocument,
        taxDocumentDraft,
        taxDocumentAnnulled,
        taxDocumentAccounted,
        getTaxDocumentDetails,
        getPaymentStatusesByClientSociety,
        getCauseNote,
        updateNote,
        calculateTotalNote,
    };
};

export default useTaxDocumentService;
