import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { CauseNoteDataForm, NoteTypes } from '../../../app/models/CauseNote';
import CauseNoteForm from './forms/CauseNoteForm';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    causeNote: CauseNoteDataForm;
    setCauseNote: (causeNote: CauseNoteDataForm) => void;
    noteTypes: NoteTypes[];
    setNoteTypes: (note: any) => void;
    errorFields?: any;
}

const CauseNoteFormContainer = ({
    fetching,
    action,
    cancel,
    causeNote,
    setCauseNote,
    noteTypes,
    setNoteTypes,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CauseNoteForm
                        causeNoteDataForm={causeNote}
                        setCauseNoteDataForm={setCauseNote}
                        noteTypes={noteTypes}
                        setNoteTypes={setNoteTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default CauseNoteFormContainer;
