import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import LazyLoading from '../LazyLoading';
import Paginator from './Paginator';
import '../../assets/scss/custom/components/_datatable.scss';
import { customStyles } from '../../template/MainTheme/layouts/Sidebar/utils/dataTableStyles';

interface DefaultDataTableProps {
    title?: string;
    columns: any[];
    data: any[];
    paginationTotalRows: number;
    progressPending?: boolean;
    filter: any;
    setFilter: (filter: any) => void;
    selectableRows?: boolean;
    selectableRowsSingle?: boolean;
    selectableRowsHighlight?: boolean;
    onSelectedRowsChange?: (selectedRows: any) => void;
    clearSelectedRows?: boolean;
    contextActions?: any;
    pagination?: boolean;
    resetContextActions?: () => void;
    contextMessage?: any;
    expandableRows?: boolean;
    expandableIcon?: any;
    expandableRowsComponent?: React.FC<{ data: any }>;
    expandableRowsComponentProps?: any;
}

const paginationRowsPerPageOptions: any = [10, 20, 30, 50, 100];

const DefaultDataTable = ({
    title = '',
    columns,
    data,
    paginationTotalRows,
    progressPending,
    filter,
    setFilter,
    selectableRows,
    selectableRowsSingle,
    selectableRowsHighlight,
    onSelectedRowsChange,
    resetContextActions,
    contextMessage,
    clearSelectedRows,
    contextActions,
    pagination,
    expandableRows = false,
    expandableIcon,
    expandableRowsComponent,
    expandableRowsComponentProps
}: DefaultDataTableProps) => {

    if (pagination !== false) {
        pagination = true;
    }

    // Ajuste columna "id"
    const processedColumns = useMemo(() => {
        return columns.map((col) => {
            const columnName = typeof col.name === 'string' ? col.name.toLowerCase() : '';
    
            return {
                ...col,
                width: columnName === 'id' ? '100px' : col.width,
                minWidth: columnName === 'id' ? '100px' : col.minWidth,
                maxWidth: columnName === 'id' ? '100px' : col.maxWidth,
            };
        });
    }, [columns]);
    

    const handlePageChange = (page: number) => {
        if (resetContextActions) resetContextActions();
        setFilter({ ...filter, ['page']: page });
    };

    const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
        if (resetContextActions) resetContextActions();
        setFilter({ ...filter, ['page']: currentPage, ['per_page']: newPerPage });
    };

    const handleSort = async (column: any, sortDirection: string) => {
        setTimeout(() => {
            setFilter({
                ...filter,
                ['order']: sortDirection,
                ['sort']: column.sortField,
                ['page']: 1
            });
        }, 100);
    };

    return (
        <DataTable
            title={title}
            columns={processedColumns}
            data={data}
            sortServer
            onSort={handleSort}
            progressPending={progressPending}
            progressComponent={<LazyLoading height={300} />}
            pagination={pagination}
            paginationServer
            paginationTotalRows={paginationTotalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationComponent={Paginator}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            noDataComponent="No hay registros disponibles"
            expandableRows={expandableRows}
            expandableIcon={expandableIcon}
            customStyles={customStyles}
            expandableRowsComponent={expandableRowsComponent}
            expandableRowsComponentProps={expandableRowsComponentProps}
            selectableRows={selectableRows}
            selectableRowsSingle={selectableRowsSingle}
            onSelectedRowsChange={onSelectedRowsChange}
            selectableRowsHighlight={selectableRowsHighlight}
            clearSelectedRows={clearSelectedRows}
            contextMessage={contextMessage}
            contextActions={contextActions}
        />
    );
};

export default DefaultDataTable;