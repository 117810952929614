import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useCauseNoteService from '../../../app/services/hooks/useCauseNoteService';
import { CauseNoteDataForm, NoteTypes } from '../../../app/models/CauseNote';
import CauseNoteFormContainer from './CauseNoteFormContainer';


interface Props {
    causeNoteId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CauseNoteEdit = ({ causeNoteId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditCauseNote, editCauseNote, fetchingUpdateCauseNote, updateCauseNote } =
        useCauseNoteService();
    const [noteTypes, setNoteTypes] = useState<NoteTypes[]>([]);
    const [causeNote, setCauseNote] = useState<CauseNoteDataForm>({ name: '', description:''});
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando motivo de notaso...');
        editCauseNote(causeNoteId, {
            onSuccess: (response: ServiceResponse) => {
                setCauseNote(response.data.cause_note);
                setNoteTypes(response.data.note_types || []);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando motivo de notas...');
        updateCauseNote(causeNoteId, causeNote, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCauseNote ? (
        <LazyLoading height="300" />
    ) : (
        <CauseNoteFormContainer
            fetching={fetchingUpdateCauseNote}
            action={update}
            cancel={cancel}
            causeNote={causeNote}
            setCauseNote={setCauseNote}
            noteTypes={noteTypes}
            setNoteTypes={setNoteTypes}
            errorFields={errorFields}
        />
    );
};

export default CauseNoteEdit;
