import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import { AppContext } from '../../../contexts/AppContext';
import { TaxDocumentHistoryDataForm, TaxDocumentHistoryFilters } from '../../../app/models/TaxDocumentHistory';
import TaxDocumentHistoryDataTable from './tables/TaxDocumentHistoryDataTable';
import useTaxDocumentHistoryService from '../../../app/services/hooks/useTaxDocumentHistoryService';

interface Props {
    taxDocumentId: number;
    taxDocumentHistory: TaxDocumentHistoryDataForm[]
    totalRowsHistory: number;
    filterHistory: TaxDocumentHistoryFilters;
    setFilterHistory: (filter: TaxDocumentHistoryFilters) => void;
    setTaxDocumentHistoryTable?: (taxDocumentHistory: TaxDocumentHistoryDataForm[]) => void;
    reloadTaxDocumentHistoryTable: () => void;
    readOnly?: boolean;
}

const TaxDocumentHistoryContainer = ({ 
    taxDocumentId,
    taxDocumentHistory,
    totalRowsHistory,
    filterHistory,
    setFilterHistory,
    setTaxDocumentHistoryTable,
    reloadTaxDocumentHistoryTable,
    

}: Props) => {

    const { getAllTaxDocumentHistoriesByInvoice, fetchingGetTaxDocumentHistoriesByInvoice } = useTaxDocumentHistoryService();

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <TaxDocumentHistoryDataTable
                            taxDocumentId={taxDocumentId}
                            taxDocumentHistories={taxDocumentHistory}
                            loading={fetchingGetTaxDocumentHistoriesByInvoice}
                            totalRows={totalRowsHistory}
                            filter={filterHistory}
                            setFilter={setFilterHistory}
                            callbackSuccess={reloadTaxDocumentHistoryTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default TaxDocumentHistoryContainer;
