import React, { useEffect } from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { Interlocutor, InterlocutorFilters, interlocutorStatuses } from '../../../../app/models/Interlocutor';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { Link } from 'react-router-dom';

// import ChangeStatusRows from '../../../../components/ChangeStatusRows';
// import InterlocutorChangeStatus from '../InterlocutorChangeStatus';
// import useInterlocutorService from '../../../../app/services/hooks/useInterlocutorService';


interface Props {
    interlocutors: Interlocutor[];
    filter: InterlocutorFilters;
    setFilter: (filter: InterlocutorFilters) => void;
    loading?: boolean;
    totalRows: number;
    destroy?: (interlocutorId: number) => void;

    // canChangeStatus?: boolean;
    reloadModule?: () => void;
    callbackSuccess?: () => void;
    isShow: boolean;
}

const InterlocutorsDataTable = ({ 
    interlocutors,
    filter,
    setFilter,
    loading,
    destroy,
    totalRows,
    isShow
    // canChangeStatus,
    // callbackSuccess,
}: Props) => {
    
    const columns = [
        {
            name: 'id',
            selector: (row: Interlocutor) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => <span className="">{row.id}</span>

        },
        {
            name: 'Posición',
            selector: (row: Interlocutor) => row.position,
            sortable: true,
            sortField: 'position',
            cell: (row: any, index: any, column: any) => <span className="">{row.position}</span>

        },
        {
            name: 'Servicio',
            selector: (row: Interlocutor) => row.service?.name || 'Sin Servicio',
            sortable: true,
            sortField: 'service',
            cell: (row: any, index: any, column: any) => <span className="">{row.service?.name ?? 'Sin Servicio'}</span>

        },
        {
            name: 'RUT (Proveedor)',
            selector: (row: Interlocutor) => row.provider_rut,
            sortable: true,
            sortField: 'provider_rut',
            cell: (row: any, index: any, column: any) => <span className="">{row.provider_rut}</span>

        },
        {
            name: 'Razón Social (Proveedor)',
            selector: (row: Interlocutor) => row.provider?.name,
            sortable: true,
            sortField: 'provider_name',
            cell: (row: any, index: any, column: any) => <span className="">{row.provider?.name ?? 'Sin Nombre'}</span>

        },
        {
            name: 'Estado',
            selector: (row: any) => row.status,
            sortable: true,
            sortField: 'status',
            cell: (row: any, index: any, column: any) => {
                const selectedStatus = interlocutorStatuses.find(
                    (status) => status.value === row.status
                );
                return <span>{selectedStatus ? selectedStatus.label : 'Estado no definido'}</span>;
            },
        },
        {
            name: 'Hoja de Entrada',
            selector: (row: Interlocutor) => row.service_manifest_id,
            sortable: true,
            sortField: 'service_manifest_id',
            cell: (row: any, index: any, column: any) =>(
                <Link
                    to={
                        '/service-manifests/' +
                        row?.service_manifest_id + '/edit'
                    }
                >
                    <span className="text-primary">{row.service_manifest_id && `#${row.service_manifest_id}`}</span>
                </Link>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="nowrap-cell nowrap-cell-no-min">
                    {(destroy && !isShow) && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];


    return (
        <DefaultDataTable
            columns={columns}
            data={interlocutors}
            progressPending={loading}
            paginationTotalRows={totalRows}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default InterlocutorsDataTable;
