import React from 'react';
import { QuoteShowDataForm } from '../../../../app/models/Quote';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface GeneralQuoteFormProps {
    quoteForm: QuoteShowDataForm;
}

const QuoteShowForm = ({ quoteForm }: GeneralQuoteFormProps) => {
    if (!quoteForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        className="form-control"
                        name="society"
                        value={quoteForm.society_name}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cliente</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        className="form-control"
                        name="client"
                        value={quoteForm.client_name}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Contacto</label>
                <div className="col-md-9">
                    <input
                        type="email"
                        className="form-control"
                        name="contact_email"
                        value={quoteForm.contact_email}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tipo de oferta</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        className="form-control"
                        name="offer_type"
                        value={quoteForm.offer_type}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Forma de Pago</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        className="form-control"
                        name="payment_method"
                        value={quoteForm.payment_method}
                        readOnly={true}
                    />
                </div>
            </div>
            {quoteForm.days_count && quoteForm.days_count > 0 ? (
                <>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Fecha de referencia</label>
                        <div className="col-md-9">
                            <div className="row g-1">
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="payment_condition"
                                        value={quoteForm.reference}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="payment_condition"
                                        value={quoteForm.days_count}
                                        readOnly={true}
                                        title="Días"
                                        placeholder="días"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Condición de Pago</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control"
                            name="payment_condition"
                            value={quoteForm.payment_condition}
                            readOnly={true}
                        />
                    </div>
                </div>
            )}

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Moneda</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        className="form-control"
                        name="currency"
                        value={quoteForm.currency}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="my-tooltip-id">
                            La fecha de expiración se calcula con la cantidad de días después de que
                            se confirma la cotización, Ej.: Si se confirma el 11 de junio y la
                            cantidad de días para expirar es de 4, la fecha de expiración será el 15
                            de junio
                        </Tooltip>
                    }
                >
                    <label className="col-md-3 col-form-label">Expira en días (Confirmada)</label>
                </OverlayTrigger>
                <div className="col-md-9">
                    <input
                        name="expired_in_days"
                        type="number"
                        className="form-control"
                        value={quoteForm.expired_in_days}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Comentario</label>
                <div className="col-md-9">
                    <textarea
                        className="form-control"
                        name="comment"
                        value={quoteForm.comment}
                        readOnly={true}
                    />
                </div>
            </div>
        </>
    );
};

export default QuoteShowForm;
