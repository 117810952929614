import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../contexts/AuthContext';
import { TaxDocumentDataForm, TaxDocumentFilters } from '../../../../app/models/TaxDocument';
import { RouterParams } from '../../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../../components/default/DefaultCard';
import LazyLoading from '../../../../components/LazyLoading';
import { AppContext } from '../../../../contexts/AppContext';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { Tabs, Tab } from 'react-bootstrap';
import useTaxDocumentService from '../../../../app/services/hooks/useTaxDocumentService';
import TaxDocumentDetailContainer from '../TaxDocumentDetailContainer';
import useTaxDocumentDetailService from '../../../../app/services/hooks/useTaxDocumentDetailService';
import TaxDocumentHistoryContainer from '../TaxDocumentHistoryContainer';
import { TaxDocumentHistoryDataForm, TaxDocumentHistoryFilters } from '../../../../app/models/TaxDocumentHistory';
import { TaxDocumentDetail, TaxDocumentDetailFilters } from '../../../../app/models/TaxDocumentDetail';
import { PaymentStatusDataForm, PaymentStatusesStatus } from '../../../../app/models/PaymentStatus';
import { TaxDocumentStatus } from '../../../../app/models/TaxDocumentStatus';
import useNavigationPage from '../../../../hooks/useNavigationPage';
import useTaxDocumentHistoryService from '../../../../app/services/hooks/useTaxDocumentHistoryService';

const TaxDocumentShow = () => {
    const { id } = useParams<RouterParams>();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { auth } = useContext(AuthContext);
    const { showTaxDocument } = useTaxDocumentService();
    const { navigationPage } = useNavigationPage();

    const [verifyingTaxDocument, setVerifyingTaxDocument] = useState<boolean>(true);
    const [taxDocument, setTaxDocument] = useState<TaxDocumentDataForm>({});
    const [taxDocumentForm, setTaxDocumentForm] = useState<TaxDocumentDataForm>({});
    const [paymentStatuses, setPaymentStatuses] = useState<PaymentStatusDataForm[]>([]);
    const [paymentStatusesStatus, setPaymentStatusesStatus] = useState<PaymentStatusesStatus[]>([]);
    const [statuses, setStatuses] = useState<TaxDocumentStatus[]>([]);
    const [taxDocumentHistory, setTaxDocumentHistory] = useState<TaxDocumentHistoryDataForm[]>([]);
    const [totalRowsDetail, setTotalRowsDetail] = useState<number>(0);
    const [totalHistoryRows, setTotalHistoryRows] = useState<number>(0);

    const {getTaxDocumentDetailsByTaxDocument} = useTaxDocumentDetailService();
    const [taxDocumentsDetail, setTaxDocumentsDetail] = useState<TaxDocumentDetail[]>([]);
    const { getAllTaxDocumentHistoriesByInvoice, fetchingGetTaxDocumentHistoriesByInvoice} = useTaxDocumentHistoryService();

    const [filter, setFilter] = useState<TaxDocumentFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });
    
    const [filterDetails, setFilterDetails] = useState<TaxDocumentDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });

    const [filterHistory, setFilterHistory] = useState<TaxDocumentHistoryFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        // reloadModule();
        showDetails();
        show(parseInt(id));
    }, [id]);


    useEffect(() => {
        reloadTaxDocumentDetailTable();
    }, [filterDetails]);

    useEffect(() => {
        reloadTableHistory();
    }, [filterHistory]);

    const [errorFields, setErrorFields] = useState<any>();

    const show = (id: number) => {
            showTaxDocument(id, {
                onSuccess: (response: ServiceResponse) => {
                    setTaxDocument(response.data.tax_document);
                    setVerifyingTaxDocument(false)
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                    if (response.data.to_dashboard) {
                        navigationPage('/tax-documents');
                    }
                }
            });
        };

    const reloadTaxDocumentDetailTable = () => {
        getTaxDocumentDetailsByTaxDocument(filter, Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setTaxDocumentsDetail(response.data.tax_document_details);
                setPaymentStatuses(response.data.payment_statuses);
                setPaymentStatusesStatus(response.data.statuses)
                setTotalRowsDetail(response.data.total_rows);

            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showDetails = () => {
        showTaxDocument(Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setTaxDocumentForm(response.data.tax_document);
                setStatuses(response.data.statuses);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const reloadTableHistory = () => {
        getAllTaxDocumentHistoriesByInvoice(Number(id),filterHistory, {
            onSuccess: (response: ServiceResponse) => {
                setTaxDocumentHistory(response.data.tax_document_histories);
                setTotalHistoryRows(response.data.total_rows);
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message, {
                        autoClose: 2500
                    });
                }
            
        });
    };

    if (verifyingTaxDocument) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    const breadcrumbs: BreadcrumbsItem[] = [
        { name: 'Documentos de venta', url: '/tax-documents', isActive: false },
        { name: `Ver ${taxDocumentForm.document_type_obj?.name} #${taxDocument.document_number}`, url: `/tax-documents/${id}/show`, isActive: true }
    ];

    return (
        <>
            <Breadcrumbs pageSection={`Ver ${taxDocumentForm.document_type_obj?.name}  #${taxDocument.document_number}`} breadcrumbs={breadcrumbs} />

            <Tabs defaultActiveKey="general-data" id="tax-document-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-12">
                        <TaxDocumentDetailContainer
                            taxDocumentId={parseInt(id)}
                            taxDocument={taxDocument}
                            setTaxDocument={setTaxDocument}
                            taxDocumentForm={taxDocumentForm}
                            setTaxDocumentForm={setTaxDocumentForm}
                            setTaxDocumentDetailTable={setTaxDocumentsDetail}
                            setTaxDocumentHistoryTable={setTaxDocumentHistory}
                            reloadTaxDocumentDetailTable={reloadTaxDocumentDetailTable}
                            reloadTaxDocument={show}
                            filterDetail={filterDetails}
                            setFilterDetail={setFilterDetails}
                            filterHistory={filterHistory}
                            setFilterHistory={setFilterHistory}
                            reloadHistoryTable={reloadTableHistory}
                            reloadDetail={showDetails}
                            paymentStatuses={paymentStatuses}
                            setPaymentStatusesStatus={setPaymentStatusesStatus}
                            paymentStatusesStatus={paymentStatusesStatus}
                            statuses={statuses}
                            setStatuses={setStatuses}
                            totalRowsDetail={totalRowsDetail}
                            isShow = {true}
                        />

                        </div>
                    </div>
                </Tab>

                <Tab eventKey="history" title="Historial">
                    <div className="col-12">
                        <TaxDocumentHistoryContainer
                            taxDocumentId={parseInt(id)}
                            taxDocumentHistory={taxDocumentHistory}
                            totalRowsHistory={totalHistoryRows}
                            filterHistory={filterHistory}
                            setFilterHistory={setFilterHistory}
                            reloadTaxDocumentHistoryTable={reloadTableHistory}
                        />
                    </div>
                </Tab>
            </Tabs>
        </>
    );
};

export default TaxDocumentShow;
