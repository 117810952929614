import { Interlocutor, InterlocutorFilters } from '../../../../app/models/Interlocutor';
import { InterlocutorServiceManifest } from '../../../../app/models/InterlocutorServiceManifest';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import InterlocutorFilter from './InterlocutorFilter';


interface Props {
    interlocutors: Interlocutor[];
    totalRows: number;
    loading?: boolean;
    edit?: (interlocutorId: number) => void;
    show?: (interlocutorId: number) => void;
    filter: InterlocutorFilters;
    setFilter: (filter: InterlocutorFilters) => void;
    showServiceManifest?: (serviceManifestId: number) => void;
    deleteServiceManifest?: (id: number, serviceManifestId: number, position:number) => void;
    paginationRowsPerPageOptions?: any[];
    isShow?: boolean
}

const InterlocutorOnDispatchProgramDataTable = ({
    interlocutors,
    totalRows,
    loading = false,
    edit,
    deleteServiceManifest,
    show,
    filter,
    setFilter,
    showServiceManifest,
    isShow=false
}: Props) => {
    
    const columns = [
        {
            name: 'ID Interlocutor',
            selector: (row: any) => row.interlocutor_id,
            sortable: true,
            sortField: 'interlocutor_id',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.interlocutor_id}
                </span>
            )
        },
        {
            name: 'Posición',
            selector: (row: any) => row.position,
            sortable: true,
            sortField: 'position',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.position}
                </span>
            )
        },
       
        {
            name: 'Rut Proveedor',
            selector: (row: any) => row.provider?.rut,
            sortable: true,
            sortField: 'provider_rut',
            cell: (row: InterlocutorServiceManifest, index: any, column: any) => (
                <span className="">
                    {row.provider?.rut}
                </span>
            )
        },
        {
            name: 'Nombre Proveedor',
            selector: (row: any) => row.provider?.name,
            sortable: true,
            sortField: 'provider_name',
            cell: (row: InterlocutorServiceManifest, index: any, column: any) => (
                <span className="">
                    {row.provider_name ?? 'Sin Nombre'}
                </span>
            )
        },
        {
            name: 'Nombre Servicio',
            selector: (row: any) => row.service?.name,
            sortable: true,
            sortField: 'service_name',
            cell: (row: InterlocutorServiceManifest, index: any, column: any) => (
                <span className="">
                    {row.service?.name ?? 'Sin Servicio'}
                </span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    
                    {showServiceManifest && (
                        <ButtonTableAction
                            callbackFunction={() => showServiceManifest(row.service_manifest_id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Ir a hoja de entrada'}
                        />
                    )}
                    {(deleteServiceManifest && !isShow) && (
                        <ButtonTableAction
                            callbackFunction={() => deleteServiceManifest(row.id ?? -1, row.service_manifest_id ?? -1, row.position ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Eliminar hoja de entrada de interlocutor'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-md-0 mt-3">
                <div className="row">
                    <div className="col-auto">
                        <InterlocutorFilter filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={interlocutors}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default InterlocutorOnDispatchProgramDataTable;
