import React from 'react';
import { InterlocutorFilters } from '../../../../app/models/Interlocutor';


type Props = {
    filter: InterlocutorFilters;
    setFilter: (filter: InterlocutorFilters) => void;
};

const InterlocutorFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="provider_rut">
                        Rut Proveedor
                    </label>
                    <input
                        type="text"
                        id="provider_rut"
                        name="provider_rut"
                        className="form-control form-control-sm"
                        value={filter.provider_rut}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="provider_name">
                        Nombre Proveedor
                    </label>
                    <input
                        type="text"
                        id="provider_name"
                        name="provider_name"
                        className="form-control form-control-sm"
                        value={filter.provider_name}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="service_name">
                        Nombre Servicio
                    </label>
                    <input
                        type="text"
                        id="service_name"
                        name="service_name"
                        className="form-control form-control-sm"
                        value={filter.service_name}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="service_manifest_id">
                        Hoja de entrada
                    </label>
                    <input
                        type="number"
                        id="service_manifest_id"
                        name="service_manifest_id"
                        className="form-control form-control-sm"
                        value={filter.service_manifest_id}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
        </div>
    );
};

export default InterlocutorFilter;
