import React from 'react';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { DispatchProgramItemStatus } from '../../../app/models/DispatchProgramItem';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';

interface Props {
    dispatchProgramItemId: number;
    statuses: DispatchProgramItemStatus[];
    selectedStatus: DispatchProgramItemStatus;
    callbackSuccess?: () => void;
    reloadModule: () => void;
}

const DispatchProgramItemChangeStatus = ({
    dispatchProgramItemId,
    statuses,
    selectedStatus,
    reloadModule,
    callbackSuccess
}: Props) => {

    const [selected, setSelected] = React.useState<DispatchProgramItemStatus>(selectedStatus);

    const {
        preparationDispatchProgramItem,
        planningDispatchProgramItem,
        dispatchDispatchProgramItem,
        finishDispatchProgramItem,
        annulledDispatchProgramItem,
        hasAllFinishedDispatchProgramItems
    } = useDispatchProgramItemService();

    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (callbackSuccess) {
                callbackSuccess();
            }
            useSweetAlert().successAlert({
                title: 'Éxito',
                text: 'Se ha cambiado el estado de item de despacho #' + dispatchProgramItemId
            });
        },
        onError: (response: ServiceResponse) => {
            if (callbackSuccess) {
                callbackSuccess();
            }
            toast.error(response.message);
        }
    };

    const changeStatus = (value: string) => {
        if (value == selected.id) return null;

        const _status = statuses.find((status: DispatchProgramItemStatus) => status.id == value);

        setSelected(_status!);

        let _text =
            'Está a punto de cambiar el estado de item de despacho #' +
            dispatchProgramItemId +
            ' a ' +
            (_status ? (_status.name === 'Despacho' ? 'Despachado' : _status.name) : '') +
            '.';
        useSweetAlert().spinnerAlert();
        hasAllFinishedDispatchProgramItems(dispatchProgramItemId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (response.data.has_all_finished_dispatch_program_items) {

                    _text += response.data.message;
                }
                useSweetAlert().requestConfirmation({
                    title: '¿Está seguro?',
                    text: _text,
                    confirmButtonText: 'Si, cambiar estado',
                    cancelButtonText: 'Cancelar',
                    onConfirm: () => {
                        const folio = (
                            document.getElementById('swal-input-folio') as HTMLInputElement
                        )?.value;
                        const file: any = document.getElementById('swal-input-file');
                        const total = (
                            document.getElementById('swal-input-total') as HTMLInputElement
                        )?.value;
                        switch (value) {
                            case 'PREPARACION':
                                preparationDispatchProgramItem(
                                    dispatchProgramItemId,
                                    eventChangeStatus
                                );

                                break;
                            case 'PLANIFICACION':
                                planningDispatchProgramItem(
                                    dispatchProgramItemId,
                                    eventChangeStatus
                                );

                                break;
                            case 'DESPACHO':
                                dispatchDispatchProgramItem(
                                    dispatchProgramItemId,
                                    eventChangeStatus
                                );

                                break;
                            case 'FINALIZADO':
                                finishDispatchProgramItem(dispatchProgramItemId, eventChangeStatus);

                                break;

                            // case 'ANULAR':
                            //     annulledDispatchProgramItem(dispatchProgramItemId, eventChangeStatus);

                            //     break;
                            

                            default:
                                useSweetAlert().errorAlert({
                                    title: 'Error',
                                    text: 'Opción no encontrada.'
                                });
                                setSelected(selectedStatus);
                                break;
                        }
                    },
                    onCancel: () => {
                        useSweetAlert().infoAlert({
                            title: 'Cancelado',
                            text: 'El estado de item de despacho no se ha cambiado.'
                        });
                        setSelected(selectedStatus);
                    }
                });
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().errorAlert({
                    title: 'Error',
                    text: response.message
                });
                setSelected(selectedStatus);
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => {
                return (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                );
            })}
        </select>
    );
};

export default DispatchProgramItemChangeStatus;