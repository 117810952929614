import { RelatedDocument, TaxDocumentDataForm, TaxDocumentFilters } from '../../../../app/models/TaxDocument';
import { TaxDocumentStatus } from '../../../../app/models/TaxDocumentStatus';
import { TaxDocumentStatusEnum } from '../../../../app/shared/enums';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import TaxDocumentChangeStatus from '../TaxDocumentChangeStatus';
import TaxDocumentSwitchChange from '../TaxDocumentSwitchChange';
import TaxDocumentFilter from './TaxDocumentFilter';

interface Props {
    taxDocuments: TaxDocumentDataForm[];
    statuses : TaxDocumentStatus[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: TaxDocumentFilters;
    setFilter: (filter: TaxDocumentFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const TaxDocumentDataTable = ({
    taxDocuments,
    statuses,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        //{/* DESCOMENTAR_PRODUCCION */}
        // {
        //     name: 'Sociedad',
        //     selector: (row: any) => row.society_name,
        //     sortable: true,
        //     sortField: 'society_name',
        //     cell: (row: TaxDocumentDataForm, index: any, column: any) => (
        //         <span className="">{row.society_name}</span>
        //     )
        // },
        // {
        //     name: 'Cliente',
        //     selector: (row: any) => row.client_name,
        //     sortable: true,
        //     sortField: 'client_name',
        //     cell: (row: TaxDocumentDataForm, index: any, column: any) => (
        //         <span className="">{row.client_name}</span>
        //     )
        // },
        // {
        //     name: 'Folio',
        //     selector: (row: any) => row.folio,
        //     sortable: true,
        //     sortField: 'folio',
        //     cell: (row: TaxDocumentDataForm, index: any, column: any) => (
        //         <span className="">{row.folio}</span>
        //     )
        // },
        {
            name: 'Tipo de Documento',
            selector: (row: any) => row.document_type,
            sortable: true,
            sortField: 'document_type',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <span className="">{row.document_type}</span>
            )
        },
        {
            name: 'Codigo SII',
            selector: (row: any) => row.sii_document_code,
            sortable: true,
            sortField: 'sii_document_code',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <span className="">{row.sii_document_code}</span>
            )
        },
        {
            name: 'Nro de Documento',
            selector: (row: any) => row.document_number,
            sortable: true,
            sortField: 'document_number',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <span className="">{row.document_number}</span>
            )
        },
        {
            name: 'Documento Asociado',
            selector: (row: any) => row.related_document,
            sortable: true,
            sortField: 'related_document',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => {
                const relatedDocument = row.related_document as RelatedDocument;
        
                if (relatedDocument && Object.keys(relatedDocument).length > 0) {
                    return (
                        <span className="">
                            <div>{relatedDocument.document_type}</div>
                            <div>Id: {relatedDocument.id}</div>
                        </span>
                    );
                }
        
                return <span className="text-muted"></span>;
            }
        },
        {
            name: 'Neto',
            selector: (row: any) => row.total_neto,
            sortable: false,
            sortField: 'total_neto',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <span className="">{row.total_neto ?? 0}</span>
            )
        },
        {
            name: 'Impuesto',
            selector: (row: any) => row.total_tax,
            sortable: false,
            sortField: 'total_tax',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <span className="">{row.total_tax ?? 0}</span>
            )
        },
        {
            name: 'Monto Total',
            selector: (row: any) => row.total_amount,
            sortable: false,
            sortField: 'total_amount',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <span className="">{row.total_amount ?? 0}</span>
            )
        },
        // {
        //     name: 'Fecha Emisión',
        //     selector: (row: any) => row?.issue_date,
        //     sortable: true,
        //     sortField: 'created_at',
        //     cell: (row: TaxDocumentDataForm, index: any, column: any) => (
        //         <span className="">{momentParseDate(row?.issue_date)}</span>
        //     )
        // },
        {
            name: 'Estado',
            selector: (row: any) => row.status,
            sortable: true,
            sortField: 'status',
            width:'185px',
            cell: (row: TaxDocumentDataForm, index: any, column: any) => {
                const status = statuses.find((taxDocumentStatus) => taxDocumentStatus.id === row.status);
                if (statuses.length == 0) {
                    return '';
                }
                return (
                    <TaxDocumentChangeStatus
                        callbackSuccess={callbackSuccess}
                        statuses={row.status_choices! ?? []}
                        selectedStatus={status!}
                        taxDocumentId={row.id!}
                    />
                );
            }
        },

        // se comenta mientras se define el uso del active
        // {
        //     name: 'Activo',
        //     selector: (row: any) => row.is_active,
        //     sortable: true,
        //     sortField: 'is_active',
        //     cell: (row: TaxDocumentDataForm, index: any, column: any) => (
        //         <TaxDocumentSwitchChange
        //             checkValue={row.is_active ?? true}
        //             taxDocumentId={row.id!}
        //             callbackSuccess={callbackSuccess!}
        //         />
        //     )
        // },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: TaxDocumentDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && row.status !== TaxDocumentStatusEnum.ANULADA && row.status !== TaxDocumentStatusEnum.CONTABILIZADA && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <TaxDocumentFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={taxDocuments}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default TaxDocumentDataTable;
