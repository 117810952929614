import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import usePaymentStatusService from '../../../../app/services/hooks/usePaymentStatusService';
import { AuthContext } from '../../../../contexts/AuthContext';
import { PaymentStatusDataForm, PaymentStatusesStatus} from '../../../../app/models/PaymentStatus';
import { RouterParams } from '../../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../../template/MainTheme/components/Breadcrumbs';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import PaymentChangeStatus from '../PaymentChangeStatus';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import DefaultCard from '../../../../components/default/DefaultCard';
import LazyLoading from '../../../../components/LazyLoading';
import { AppContext } from '../../../../contexts/AppContext';
import useNavigationPage from '../../../../hooks/useNavigationPage';
import PaymentStatusForm from '../forms/PaymentStatusForm';
import { PaymentStatusDetail } from '../../../../app/models/PaymentStatusDetail';
import usePaymentStatusDetailService from '../../../../app/services/hooks/usePaymentStatusDetailService';
import PaymentStatusDetailContainer from '../PaymentStatusDetailContainer';
import DefaultCreatedModifiedUserForm from '../../../../components/default/DefaultCreatedModifiedUserForm';


const PaymentStatusShow = () => {
    const { id } = useParams<RouterParams>();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { auth } = useContext(AuthContext);

    const {
        fetchingChangeStatusPaymentStatus,
        changeReviewStatusPaymentStatus,
        changeApprovedStatusPaymentStatus,
        changeAnnulledStatusPaymentStatus,
        changeDraftStatusPaymentStatus,
        showPaymentStatus,
        canChangePaymentStatuses,
        updatePaymentStatus,
        fetchingEditPaymentStatus
    } = usePaymentStatusService(); 

    const {
        fetchingGetPaymentStatusDetailByPaymentStatus,
        getPaymentStatusDetailsByPaymentStatus
    } = usePaymentStatusDetailService();

    const [statuses, setStatuses] = useState<PaymentStatusesStatus[]>([]);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusDataForm>({});
    const [errorFields, setErrorFields] = useState<any>();

    const [paymentStatusDetail, setPaymentStatusDetail] = useState<PaymentStatusDetail[]>([]);


    useEffect(() => {
        reloadModule();
    }, [id]);

    const show = (id: number) => {
        showPaymentStatus(id, {
            onSuccess: (response: ServiceResponse) => {
                setPaymentStatus(response.data.payment_status);
                setStatuses(response.data.statuses)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/payment-statuses');
                }
            }
        });
    };

    const reloadModule = () => {
        show(parseInt(id));
    };


    const breadcrumbs: BreadcrumbsItem[] = [
        { name: 'Estados de pago de venta', url: '/payment-statuses', isActive: false },
        { name: `Ver Estado de pago #${id}`, url: `/payment-statuses/${id}/show`, isActive: true }
    ];

    return (
        <>
            <Breadcrumbs pageSection={`Ver Estado de pago #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditPaymentStatus ? (
                            <PaymentStatusForm
                                paymentStatus={paymentStatus}
                                setPaymentStatus={setPaymentStatus}
                                errorFields={errorFields}
                            />
                        ) : (
                            <LazyLoading />
                        )}
                        <hr />
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                            {!fetchingEditPaymentStatus ? (
                                <>
                                    <DefaultCreatedModifiedUserForm
                                        createdBy={paymentStatus.created_user}
                                        createdAt={paymentStatus.created_at}
                                        updatedBy={
                                            paymentStatus.last_modified_user
                                        }
                                        updatedAt={paymentStatus.updated_at}
                                        status={paymentStatus.status_label}
                                    />
                                </>
                                
                            ) : (
                                <LazyLoading />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-2">
                <h5>Detalles Estado de Pago</h5>
            </div>
            

            <PaymentStatusDetailContainer
                paymentStatusId={parseInt(id)}
            />
        </>
    );
};

export default PaymentStatusShow;
