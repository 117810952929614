import React from 'react';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
};

const DispatchProgramFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            {/* primera fila */}
            <div className="row">
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="dispatch_program_id">
                            Despacho
                        </label>
                        <input
                            title="Número"
                            type="number"
                            id="dispatch_program_id"
                            name="dispatch_program_id"
                            className="form-control form-control-sm"
                            value={filter.dispatch_program_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="transportation_order_id">
                            Pedido
                        </label>
                        <input
                            title="Pedido"
                            type="number"
                            id="transportation_order_id"
                            name="transportation_order_id"
                            className="form-control form-control-sm"
                            value={filter.transportation_order_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="quote_id">
                            Cotización
                        </label>
                        <input
                            title="Cotización"
                            type="number"
                            id="quote_id"
                            name="quote_id"
                            className="form-control form-control-sm"
                            value={filter.quote_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="client">
                            Cliente
                        </label>
                        <input
                            title="Cliente"
                            type="text"
                            id="client"
                            name="client"
                            className="form-control form-control-sm"
                            value={filter.client}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="start_date">
                            Desde
                        </label>
                        <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            className="form-control form-control-sm"
                            value={filter.start_date}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="end_date">
                            Hasta
                        </label>
                        <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            className="form-control form-control-sm"
                            value={filter.end_date}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="dispatch_program_status">
                            Estatus
                        </label>
                        <select
                            id="dispatch_program_status"
                            name="dispatch_program_status"
                            className="form-control form-select-sm"
                            value={filter.dispatch_program_status}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option value="TODOS">TODOS</option>
                            <option value="DESPACHO">Despachando</option>
                            <option value="FINALIZADO">Finalizado</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DispatchProgramFilter;
