export interface DailyCarrier {
    id?: number;
    name?: string;
    day?: string;
    carrier_id?: number;
    truck_id?: number;
    provider_id?: number;
    hopper_id?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    total_guide?: number;

    service_manifest_id?: number
}

export interface DailyCarrierFilters {
    id?: number | "";
    daily_carrier_id?: number | "";
    provider?: string | '';
    carrier_rut?: string | '';
    carrier_name?: string | '';
    truck_id?: number | "";
    hopper_id?: number | "";
    service_manifest_id?: number | '',
    truck_patent?: string | '';
    hopper_patent?: string | '';
    provider_rut?: string | '';
    provider_name?: string | '';
    
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultDailyCarrier: DailyCarrier = {
    carrier_id: 0,
    provider_id: 0,
    truck_id: 0,
    hopper_id: 0,
    created_at: '',
    updated_at: '',
    deleted_at: ''
};
