import React, { useContext, useEffect, useState } from 'react';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import {
    DispatchProgramItem,
    DispatchProgramItemFilters,
    dispatchProgramItemStatuses,
    MassiveStatusForm
} from '../../../../app/models/DispatchProgramItem';
import DispatchProgramItemChangeStatus from '../DispatchProgramItemChangeStatus';
import DispatchProgramItemFilter from './DispatchProgramItemFilter';
import useDispatchProgramItemService from '../../../../app/services/hooks/useDispatchProgramItemService';
import ChangeStatusRows from '../../../../components/ChangeStatusRows';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { StatusRows } from '../../../../app/shared/interfaces';
import { AppContext } from '../../../../contexts/AppContext';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { DispatchProgramItemStatusEnum } from '../../../../app/shared/enums';

interface Props {
    dispatchProgramId: number;
    dispatchProgramItems: DispatchProgramItem[];
    totalRows: number;
    callbackSuccess?: () => void;
    edit?: (dispatchProgramItemId: number, status: string) => void;
    destroy?: (dispatchProgramItemId: number) => void;
    show?: (dispatchProgramItemId: number) => void;
    syncDailyCarrier?: (dispatchProgramItemId: number) => void;
    generateServiceManifest?: () => void;
    reloadModule: () => void;
    canChangeStatus?: boolean;
    filter: DispatchProgramItemFilters;
    setFilter: (filter: DispatchProgramItemFilters) => void;
    loading: boolean;
    isShow?: boolean;
    canBeEdited: boolean;

    downloadExcel: () => void;
    showMassiveLoad: () => void;
    setShowingModalDocument: (value: boolean) => void;
    status: string
}

const DispatchProgramItemDataTable = ({
    dispatchProgramId,
    dispatchProgramItems,
    edit,
    destroy,
    syncDailyCarrier,
    generateServiceManifest,
    canChangeStatus,
    totalRows = 0,
    filter,
    setFilter,
    loading,
    reloadModule,
    callbackSuccess,
    downloadExcel,
    showMassiveLoad,
    setShowingModalDocument,
    canBeEdited,
    isShow = false,
    status,
}: Props) => {
    const columns = [
        {
            name: 'Posición',
            selector: (row: any) => row?.position,
            sortable: true,
            sortField: 'position'
        },
        {
            name: 'Cantidad Plan',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: DispatchProgramItem, index: any, column: any) => (
                <span className="">
                    {formatNumber(row.quantity)} {row.product_unit}
                </span>
            )
        },
        {
            name: 'F. Despacho Plan',
            selector: (row: any) => row?.dispatch_date,
            sortable: true,
            sortField: 'dispatch_date',
            cell: (row: DispatchProgramItem, index: any, column: any) => (
                <span className="">{momentParseDate(row.dispatch_date)}</span>
            )
        },
        {
            name: 'Transportistas',
            selector: (row: any) => row?.carrier_name,
            sortable: true,
            sortField: 'transporter',
            cell: (row: any, index: any, column: any) => (
                <>
                    {row.dispatch_program_daily_carrier.map((item: any, index: number) => {
                        const provider = item.daily_carrier.provider;
                        return (
                            <div key={row}>
                                {index !== 0 ? <br /> : ''}
                                <div>{provider?.rut}</div>
                                <div>{provider?.name}</div>
                            </div>
                        );
                    })}
                </>
            )
        },
        {
            name: 'Conductores',
            selector: (row: any) => row?.carrier_name,
            sortable: true,

            sortField: 'carrier',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.dispatch_program_daily_carrier.map((item: any, index: number) => {
                        const carrier = item.daily_carrier.carrier;

                        return (
                            <div key={row}>
                                {index !== 0 ? <br /> : ''}
                                <div>{carrier?.rut}</div>
                                <div>{carrier?.name}</div>
                                <div>{carrier?.phone}</div>
                            </div>
                        );
                    })}
                </span>
            )
        },

        {
            name: 'Folio Guia',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'folio',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.dispatch_program_daily_carrier[0]?.guides[0]?.folio}</span>
            )
        },
        {
            name: 'Cantidad guia',
            selector: (row: any) => row?.total,
            sortable: true,
            sortField: 'total',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.dispatch_program_daily_carrier[0]?.guides[0]?.total
                        ? formatNumber(row.dispatch_program_daily_carrier[0]?.guides[0]?.total) +
                          ' ' +
                          row.product_unit
                        : ''}
                </span>
            )
        },
        {
            name: 'F. Despacho Real',
            selector: (row: any) => row?.dispatch_date,
            sortable: true,
            sortField: 'dispatch_date',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.dispatch_program_daily_carrier[0]?.guides[0]?.actual_dispatch_date
                        ? momentParseDate(
                              row.dispatch_program_daily_carrier[0]?.guides[0]?.actual_dispatch_date
                          )
                        : ''}
                </span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {canChangeStatus ? (
                        <DispatchProgramItemChangeStatus
                            callbackSuccess={callbackSuccess}
                            statuses={row.status_choices!}
                            selectedStatus={row.status_selected!}
                            dispatchProgramItemId={row.id!}
                            reloadModule={reloadModule!}
                        />
                    ) : row.status_selected ? (
                        row.status_selected.name
                    ) : (
                        ''
                    )}
                </div>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '170px',
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {syncDailyCarrier && (
                        <ButtonTableAction
                            callbackFunction={() => syncDailyCarrier(row.id ?? -1)}
                            classIcon={'mdi mdi-truck'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede asignar este transportista.'}
                            title={'Asignar Transporte'}
                        />
                    )}
                    {row.dispatch_program_daily_carrier[0]?.guides[0]?.file ? (
                        <a
                            href={row.dispatch_program_daily_carrier[0]?.guides[0]?.file}
                            className="text-success icon-pointer mx-2"
                            title={'Descargar Guia'}
                            download
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="mdi mdi-download font-size-18" />
                        </a>
                    ) : (
                        <a
                            className="text-secondary icon-pointer mx-2 disabled"
                            title={'Descargar Guia'}
                        >
                            <i className="mdi mdi-download font-size-18 disabled" />
                        </a>
                    )}

                    {/*{show && <ButtonTableShow callbackShow={() => show(row.id ?? -1)} />}*/}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1, row.status)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [rows, setRows] = useState<any[]>([]);
    const [rowsIds, setRowsIds] = useState<number[]>([]);
    const [rowsPositions, setRowsPositions] = useState<number[]>([]);
    const [massiveStatusForm, setMassiveStatusForm] = useState<MassiveStatusForm>({
        dispatch_program_id: dispatchProgramId,
        all_selected: false,
        selected_count: 0,
        rows_ids: []
    });
    const [sameStatusAllRows, setSameStatusAllRows] = useState<boolean>(false);
    const [toggleCleared, setToggleCleared] = useState<boolean>(false);


    const {
        changeStatusesRowsToDispatchDispatchProgramItem,
        masiveDeleteDispatchRows,
        fetchingMasiveDeleteDispatchRows,
        fetchingChangeStatusesRowsToDispatchDispatchProgramItem
    } = useDispatchProgramItemService();

    const contextMessage = ({
        selectedCount,
        allSelected
    }: {
        selectedCount: number;
        allSelected: boolean;
    }) => {
        return {
            singular: 'fila seleccionada',
            plural: 'filas seleccionadas'
        };
    };
    
    // const contextActions = () => {
    //     setToggleCleared(!toggleCleared);
    //     setSameStatusAllRows(false);
    //     setRows([]);
    //     setRowsIds([]);
    // };

    const resetContextActions = () => {
        setToggleCleared(!toggleCleared);
        setRows([]);
    };

    const changeStatus = (
        value: string,
        massiveChange: boolean,
        setSelected: (status: string) => void
    ) => {
        if (value === '') {
            setSelected('');
            return;
        }

        const _status = dispatchProgramItemStatuses.find(
            (status: StatusRows) => status.value == value
        );

        let _text = `Está a punto de cambiar el estado ${
            massiveChange ? 'de todos los registros ' : 'de los registros marcados'
        } a ${_status!.label}`;

        if(value === DispatchProgramItemStatusEnum.ANULAR){
            
            _text = `Está a punto de anular ${
                massiveChange ? 'todos los registros,' : 'los registros marcados,'
            } volverán a estado de Preparación`;
        }
      
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estados...');

                        changeStatusesRowsToDispatchDispatchProgramItem(
                            {
                                ...massiveStatusForm,
                                status: value,
                                all_selected: massiveChange
                            },
                            {
                                onSuccess: (response: ServiceResponse) => {
                                    setSelected('');
                                    if (hideLoading) hideLoading();

                                    useReactConfirmAlert().successAlert({
                                        title: 'Éxito',
                                        message: response.message,
                                        message_list: response.data?.message_list ?? []
                                    });
                                    resetContextActions();
                                    if (callbackSuccess) callbackSuccess();
                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();

                                    useReactConfirmAlert().errorAlert({
                                        title: 'Error',
                                        message: response.message
                                    });
                                }
                            }
                        );
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'Los estados no se han cambiado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const deleteRows = () => {
        if (rows.length === 0) {
            useReactConfirmAlert().infoAlert({
                title: 'Información',
                message: 'No hay filas seleccionadas para eliminar.'
            });
            return;
        }
    
        const confirmationMessage = `Está a punto de eliminar ${
            massiveStatusForm.all_selected ? 'todas las filas seleccionadas' : 'las filas seleccionadas'
        }. ¿Desea continuar?`;
    
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: confirmationMessage,
            buttons: {
                confirmButton: {
                    label: 'Sí, eliminar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando filas...');
    
                        masiveDeleteDispatchRows(
                            {
                                ...massiveStatusForm,
                                rows_ids: rows.map(row => row.id),
                            },
                            {
                                onSuccess: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
    
                                    useReactConfirmAlert().successAlert({
                                        title: 'Éxito',
                                        message: response.message,
                                        message_list: response.data?.message_list ?? []
                                    });
                                    resetContextActions();
                                    if (callbackSuccess) callbackSuccess();

                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
    
                                    useReactConfirmAlert().errorAlert({
                                        title: 'Error',
                                        message: response.message
                                    });
                                }
                            }
                        );
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'Las filas no se han eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const contextActions = React.useMemo(() => {
        return (
            <div className="d-flex">
            <div className="me-2">
                <ChangeStatusRows
                    changeStatus={changeStatus}
                    className="form-select-sm"
                    statuses={dispatchProgramItemStatuses}
                />
            </div>
            <div>
                <button
                    className="btn btn-danger btn-sm"
                    onClick={deleteRows}
                >
                    Eliminar filas
                </button>
            </div>
        </div>
        );
    }, [dispatchProgramItems, rows, toggleCleared]);

    const handleRowSelected = React.useCallback(
        ({ allSelected, selectedCount, selectedRows }: any) => {
            setRows(selectedRows);
            setMassiveStatusForm({
                ...massiveStatusForm,
                all_selected: false,
                selected_count: selectedCount,
                rows_ids: selectedRows.map((row: any) => row.id)
            });
        },
        []
    );

    const handleServiceManifestClick = () => {

        if (generateServiceManifest) generateServiceManifest();
    };


    // const handleChange = ({ selectedRows }: any) => {
    //     setSameStatusAllRows(false);
    //     const sameStatus = selectedRows.every(
    //         (item: { status: any }) => item.status === selectedRows[0].status
    //     );

    //     let comunStatus = '';

    //     if (sameStatus && selectedRows.length > 0) {
    //         comunStatus = selectedRows[0].status;
    //         setSameStatusAllRows(true);
    //     }

    //     const ids = selectedRows.map((item: { id: any }) => item.id);
    //     const positions = selectedRows.map((item: { position: any }) => item.position);

    //     setRowsPositions(positions);
    //     setRowsIds(ids);
    //     setRows(selectedRows);
    // };

    return (
        <>
            <div className="row">
                <div className="col-12 mt-md-0 mt-3">
                    <div className="row">
                        <div className="col-auto">
                            <DispatchProgramItemFilter filter={filter} setFilter={setFilter} />
                        </div>
                        {canBeEdited && (
                            <div className="row">
                                {/* <label className="font-size-10 mb-1">Cambiar estado</label>
                                {sameStatusAllRows ? (
                                    <DispatchProgramRowsChangeStatus
                                        callbackSuccess={callbackSuccess}
                                        statuses={rows[0].status_choices!}
                                        selectedStatus={rows[0].status_selected!}
                                        dispatchProgramRowsIds={rowsIds}
                                        positions={rowsPositions}
                                        reloadModule={reloadModule!}
                                        contextActions={contextActions}
                                        className="form-select-sm"
                                    />
                                ) : (
                                    <select
                                        className="form-control form-select-sm"
                                        defaultValue=""
                                        disabled
                                    >
                                        <option>
                                            {rowsIds.length > 0
                                                ? 'Todas deben tener el mismo estado'
                                                : 'Seleccione una fila'}
                                        </option>
                                    </select>
                                )} */}
                            </div>
                        )}

                        <div className="col-auto" style={{ paddingTop: 22 }}>
                            <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                                <i className="fa fa-file-excel" /> Descargar
                            </button>
                        </div>
                        {

                            <div className="col-auto" style={{ paddingTop: 22 }}>
                                <button className="btn btn-info btn-sm" onClick={handleServiceManifestClick}>
                                    <i className="fa fa-file-excel" /> Crear hoja de entrada
                                </button>
                            </div>
                        }

                        <div className="col" style={{ paddingTop: 22 }}>
                            <button
                                className="btn btn-success btn-sm  float-end"
                                onClick={showMassiveLoad}
                            >
                                <i className="fa fa-file-excel" /> Carga Masiva
                            </button>
                        </div>
                        <div className="col-auto" style={{ paddingTop: 22 }}>
                            <button
                                className="btn btn-primary btn-sm  float-end"
                                onClick={() => setShowingModalDocument(true)}
                            >
                                <i className="fa fa-file-archive" /> Subida de documentos
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <DefaultDataTable
                        title="Posiciones"
                        columns={columns}
                        data={dispatchProgramItems}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        filter={filter}
                        setFilter={setFilter}
                        selectableRows={canBeEdited}
                        onSelectedRowsChange={handleRowSelected}
                        selectableRowsHighlight={true}
                        clearSelectedRows={toggleCleared}
                        contextActions={contextActions}
                        resetContextActions={resetContextActions}
                        contextMessage={contextMessage({
                            selectedCount: massiveStatusForm.selected_count ?? 0,
                            allSelected: massiveStatusForm.all_selected!
                        })}
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramItemDataTable;
