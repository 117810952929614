import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ServiceManifestDataForm, ServiceManifestFilters } from '../../models/ServiceManifest';

const useInterlocutorServiceManifestService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetServiceManifests, setFetchingGetServiceManifests] = useState(false);
    const [fetchingDeleteServiceManifestByDispatchProgram, setFetchingDeleteServiceManifestByDispatchProgram] = useState(false);
    

    const getInterlocutorServiceManifests = (
        serviceManifestFilters: ServiceManifestFilters,
        dispatch_program_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(serviceManifestFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.INTERLOCUTOR_SERVICE_MANIFEST.GET_INTERLOCUTOR_SERVICE_MANIFEST}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':dispatch_program_id', dispatch_program_id.toString()),
            setFetching: setFetchingGetServiceManifests
        });
    };

   

    const deleteServiceManifestByDispatchProgram = (id: number, events: ServiceEvents = {}) => {
        const url = EndPoints.INTERLOCUTOR_SERVICE_MANIFEST.DELETE_SERVICE_MANIFEST_BY_DISPATCH_PROGRAM
        doDelete({
            ...events,
            url: url.replace(':id', id.toString()),
            setFetching: setFetchingDeleteServiceManifestByDispatchProgram
        });
    };


   

    return {
        fetchingGetServiceManifests,
        fetchingDeleteServiceManifestByDispatchProgram,
        getInterlocutorServiceManifests,
        deleteServiceManifestByDispatchProgram,
    };
};

export default useInterlocutorServiceManifestService;
