import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import TaxDocumentDataTable from './tables/TaxDocumentDataTable';
import { TaxDocumentDataForm, TaxDocumentFilters, TaxDocumentPaymentDataForm } from '../../../app/models/TaxDocument';
import useTaxDocumentDetailService from '../../../app/services/hooks/useTaxDocumentDetailService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import TaxDocumentDetailDataTable from './tables/TaxDocumentDetailDataTable';
import { TaxDocumentDetail, TaxDocumentDetailFilters } from '../../../app/models/TaxDocumentDetail';
import { PaymentStatusDataForm, PaymentStatusesStatus, PaymentStatusFilters } from '../../../app/models/PaymentStatus';
import PaymentStatusDataTable from '../../PaymentStatus/components/tables/PaymentStatusDataTable';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import TaxDocumentsEdit from './TaxDocumentEdit';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import useTaxDocumentHistoryService from '../../../app/services/hooks/useTaxDocumentHistoryService';
import { TaxDocumentHistoryDataForm, TaxDocumentHistoryFilters } from '../../../app/models/TaxDocumentHistory';
import useTaxDocumentService from '../../../app/services/hooks/useTaxDocumentService';
import TaxDocumentDetailForm from './forms/TaxDocumentDetailForm';
import { TaxDocumentStatus } from '../../../app/models/TaxDocumentStatus';
import TaxDocumentTotal from './TaxDocumentTotal';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import TaxDocumentCondition from './TaxDocumentCondition';
import { TaxDocumentStatusEnum } from '../../../app/shared/enums';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

interface Props {
    taxDocumentId: number;
    isShow?: boolean;
    taxDocument: TaxDocumentDataForm;
    setTaxDocument?: (taxDocument: TaxDocumentDataForm) => void;
    taxDocumentForm: TaxDocumentDataForm;
    setTaxDocumentForm: (taxDocumentForm: TaxDocumentDataForm) => void;
    setTaxDocumentDetailTable?: (taxDocumentDetail: TaxDocumentDetail[]) => void;
    reloadTaxDocumentDetailTable: () => void;
    filterDetail: TaxDocumentDetailFilters;
    setFilterDetail: (filter: TaxDocumentDetailFilters) => void;
    reloadTaxDocument: (taxDocumentId: number) => void;
    setTaxDocumentHistoryTable: (taxDocumentHistory: TaxDocumentHistoryDataForm[]) => void;
    filterHistory: TaxDocumentHistoryFilters;
    setFilterHistory: (filter: TaxDocumentHistoryFilters) => void;
    reloadHistoryTable: () => void;
    reloadDetail:() => void;
    paymentStatuses: PaymentStatusDataForm[];
    setPaymentStatuses?: (paymentStatusesStatus: PaymentStatusDataForm[]) => void;
    paymentStatusesStatus: PaymentStatusesStatus[];
    setPaymentStatusesStatus?: (setPaymentStatusesStatus: PaymentStatusesStatus[]) => void;
    statuses:TaxDocumentStatus[];
    setStatuses: (statuses: TaxDocumentStatus[]) => void;
    totalRowsDetail: number
    

}

const TaxDocumentContainer = ({
    taxDocumentId,
    taxDocumentForm,
    setTaxDocumentDetailTable,
    reloadTaxDocumentDetailTable,
    setTaxDocumentHistoryTable,
    filterHistory,
    setFilterHistory,
    filterDetail,
    setFilterDetail,
    reloadHistoryTable,
    paymentStatuses,
    statuses,
    paymentStatusesStatus,
    totalRowsDetail,
    setPaymentStatusesStatus,
    setStatuses,
    reloadDetail,
    reloadTaxDocument,
    setTaxDocumentForm,
    isShow = false,
}: Props) => {

    const [taxDocumentsDetail, setTaxDocumentsDetail] = useState<TaxDocumentDetail[]>([]);

    const [showingEdit, setShowingEdit] = useState(false);
    const [taxDocumentDetailIdEdit, settaxDocumentDetailIdEdit] = useState<number>(-1);
    const [taxDocumentHistories, setTaxDocumentHistories] = useState<TaxDocumentHistoryDataForm[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const [showingShow, setShowingShow] = useState(false);
    const { showLoading, hideLoading } = useContext(AppContext);
    const { getTaxDocumentDetailsByTaxDocument, 
            deleteTaxDocumentDetail,
            fetchingGetTaxDocumentDetailsByTaxDocument,
            fetchingDeleteTaxDocumentDetailsByTaxDocument } = useTaxDocumentDetailService();
    const { getAllTaxDocumentHistoriesByInvoice, fetchingGetTaxDocumentHistoriesByInvoice } = useTaxDocumentHistoryService();
    const { editTaxDocument, updateTaxDocument, showTaxDocument, fetchingEditTaxDocument } = useTaxDocumentService();
    const [documentType, setDocumentType] = useState<string | null>(null); 

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando estado de pago...');
        updateTaxDocument(taxDocumentId, taxDocumentForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(undefined);
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                reloadTaxDocumentDetailTable();
                reloadDetail();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        }, true); // Pasa `true` como cuarto argumento
    };

    const showEdit = (taxDocumentDetailId: number, docType: string) => {
        setShowingEdit(true);
        setDocumentType(docType);
        // console.log(docType)
        settaxDocumentDetailIdEdit(taxDocumentDetailId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setDocumentType(null);
        settaxDocumentDetailIdEdit(-1);
    };


    const hideShow = () => {
        setShowingShow(false);
    };

    

    const destroy = (paymentStatusId: number | null) => {
        const _text = paymentStatusId
            ? `Está a punto de desvincular el estado de pago # ${paymentStatusId} de la factura de venta`
            : 'No hay un estado de pago válido para desvincular.';
            
    
        useReactConfirmAlert().requestConfirmation({
            title: '¿Está seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, desvincular',
                    onClick: () => {
                        if (paymentStatusId) {
                            if (showLoading) showLoading('loading', 'Eliminando estado de pago...');
    
                            deleteTaxDocumentDetail(taxDocumentId, paymentStatusId, {
                                onSuccess: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    useReactConfirmAlert().successAlert({
                                        title: 'Éxito',
                                        message: response.message,
                                    });
                                    reloadTaxDocumentDetailTable();
                                    reloadDetail();
                                    reloadHistoryTable();
                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    useReactConfirmAlert().errorAlert({
                                        title: 'Error',
                                        message: response.message,
                                    });
                                },
                            });
                        } else {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado de pago no se ha desvinculado.',
                            });
                        }
                    }
                },
                cancelButton: {
                    label: 'Cancelar',
                    onClick: () => {
                        setTimeout(() => {
                        useReactConfirmAlert().infoAlert({
                            title: 'Cancelado',
                            message: 'El estado de pago no se ha desvinculado.',
                        });
                    }, 0);
                }
            }
        }
        });
    };

    return (
        <>

                <div className="row">
                        {/* Datos Generales */}
                        <div className="col-md-6">
                        <DefaultCard>
                            <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                            {!fetchingEditTaxDocument ? (
                                <TaxDocumentDetailForm
                                    taxDocument={taxDocumentForm}
                                    setTaxDocument={setTaxDocumentForm}
                                    errorFields={errorFields}
                                    disabled={taxDocumentForm.status !== TaxDocumentStatusEnum.BORRADOR || isShow} // Pasa `true` si no es BORRADOR
                                />
                            ) : (
                                <LazyLoading />
                            )}
                            <hr />
                            <div className="row justify-content-end">
                                {!fetchingEditTaxDocument && taxDocumentForm.status === TaxDocumentStatusEnum.BORRADOR  && !isShow &&(
                                    <div className="col-auto">
                                        <ButtonSaveForm callbackSave={update} locked={fetchingEditTaxDocument} />
                                    </div>
                                )}
                            </div>
                        </DefaultCard>
                        </div>

                        {/* Usuarios */}
                        <div className="col-md-6">
                            <div className="card">
                            <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                                {!fetchingEditTaxDocument ? (
                                    <DefaultCreatedModifiedUserForm
                                        createdBy={taxDocumentForm.created_user}
                                        createdAt={taxDocumentForm.created_at}
                                        updatedBy={taxDocumentForm.last_modified_user}
                                        updatedAt={taxDocumentForm.updated_at}
                                        status={taxDocumentForm.status_label}
                                        // status={taxDocumentForm.status === TaxDocumentStatusEnum.BORRADOR ? 'BORRADOR' : taxDocumentForm.status}
                                    />
                                ) : (
                                    <div>Cargando...</div>
                                )}
                            </div>
                            </div>
                        </div>
                    </div>

                    <DefaultCard>

                    <div className="col-12">
                        <TaxDocumentCondition
                        taxDocument={taxDocumentForm}
                        />
                    </div>

                    </DefaultCard>
            
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                    <DefaultToolBar
                        left={
                            !isShow &&
                            taxDocumentForm &&
                            taxDocumentForm.status === TaxDocumentStatusEnum.BORRADOR &&
                            taxDocumentForm.note_type !== 'ANULACION' ? (
                                <ButtonCreate
                                    callbackCreate={() => {
                                        if (taxDocumentForm.document_type_obj) {
                                            showEdit(taxDocumentDetailIdEdit, String(taxDocumentForm.document_type_obj?.id));
                                        } else {
                                            toast.error('El tipo de documento no está definido.');
                                        }
                                    }}
                                    title={`Agregar posicion de ${taxDocumentForm.document_type_obj?.name}`}
                                />
                            ) : null
                        }
                    />
                    </div>

                    <div className="col-12">
                        <TaxDocumentDetailDataTable
                            taxDocumentDetail={paymentStatuses}
                            totalRows={totalRowsDetail}
                            loading={fetchingGetTaxDocumentDetailsByTaxDocument}
                            destroy={isShow || taxDocumentForm.note_type === "ANULACION" || taxDocumentForm.status === TaxDocumentStatusEnum.CONTABILIZADA ? undefined : destroy}
                            filter={filterDetail}
                            setFilter={setFilterDetail}
                            statuses={paymentStatusesStatus}
                            callbackSuccess={reloadTaxDocumentDetailTable}
                        />
                    </div>
                    <div className="col-12">
                        <TaxDocumentTotal
                        taxDocument={taxDocumentForm}
                        />
                    </div>
                </div>
            </DefaultCard>
    
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title={`Agregar posicion de ${taxDocumentForm.document_type_obj?.name}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <TaxDocumentsEdit
                        taxDocumentId={Number(taxDocumentId)}
                        onSaved={() => {
                            reloadHistoryTable();
                            reloadTaxDocumentDetailTable();
                            reloadDetail();
                            hideEdit();
                        }}
                        onError={() => {
                            reloadTaxDocumentDetailTable();
                            reloadDetail();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        taxDocumentDetailId={taxDocumentDetailIdEdit}
                        setTaxDocumentDetailTable={setTaxDocumentDetailTable!}
                        reloadTaxDocumentTable={reloadTaxDocumentDetailTable}
                        setTaxDocumentHistoryTable={setTaxDocumentHistoryTable!}
                        // reloadTaxDocumentHistoryTable={reloadHistoryTable}
                    />
                </DefaultModal>
            ) : null}

        </>
    );
};

export default TaxDocumentContainer;
