import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { TaxDocumentDetailFilters } from '../../models/TaxDocumentDetail';
import { ServiceEvents } from '../shared/interfaces';

const useTaxDocumentDetailService = () => {
    const { doGet,doDelete } = useFetch(); 
    const [fetchingGetTaxDocumentDetailsByTaxDocument, setFetchingGetTaxDocumentDetailsByInvoice] = useState(false);
    const [fetchingDeleteTaxDocumentDetailsByTaxDocument, setFetchingDeleteTaxDocumentDetailsByInvoice] = useState(false);


    const getTaxDocumentDetailsByTaxDocument = (filter: TaxDocumentDetailFilters, taxDocumentId: number, events: ServiceEvents = {} )=> {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.TAX_DOCUMENT_DETAIL.GET_TAX_DOCUMENT_DETAILS_BY_TAX_DOCUMENT}?${queryString}`;
        
        doGet({
            ...events,
            url: url.replace(':id', taxDocumentId.toString()),
            setFetching: setFetchingGetTaxDocumentDetailsByInvoice
        });
    };

    const deleteTaxDocumentDetail = (taxDocumentId: number, paymentStatusId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TAX_DOCUMENT_DETAIL.DELETE_TAX_DOCUMENT_BY_TAX_DOCUMENT
                .replace(':id', taxDocumentId.toString())  
                .replace(':payment_status_id', paymentStatusId.toString()),  
            setFetching: setFetchingDeleteTaxDocumentDetailsByInvoice
        });
    };

    return {
        getTaxDocumentDetailsByTaxDocument, 
        deleteTaxDocumentDetail,
        fetchingGetTaxDocumentDetailsByTaxDocument,
        fetchingDeleteTaxDocumentDetailsByTaxDocument, 
    };
};

export default useTaxDocumentDetailService;

