
import { BatchType } from './BatchType';
import { Brand } from './Brand';
import { Depot } from './Depot';

import { Product } from './Product';
import { Society } from './Society';
import { Unit } from './Unit';

export interface Batch {
    id?: number;
    society?: Society
society_name?: string;
society_fantasy_name?: string;
    society_id?: number;
    batch_type?: BatchType;
    batch_type_id?: number;
    brand?: Brand;
    brand_id?: number;
    code?: string;
    description?: string;
    capacity?: number;
    unit_capacity?: Unit;
    unit_capacity_id?: number;
    depot?: Depot;
    depot_id?: number;
    status?: BatchStatus;
    status_selected?: string;
    product_label?: Product;
    product_label_id?: number;
}

export interface BatchDataForm {
    id?: number;
    society?: string;
    society_id?: number;
    batch_type?: string;
    batch_type_id?: number;
    brand?: string;
    brand_id?: number;
    code?: string;
    description?: string;
    capacity?: number;
    unit_capacity?: string;
    unit_capacity_id?: number;
    current_quantity_calculated?: number;
    depot?: string;
    depot_id?: number;
    status?: string;
    product_label?: string;
    product_label_id?: number;
    is_active?: boolean;
    society_name?: string;
    batch?: string;
    batch_id?: number;
}

export const defaultBatchDataForm: BatchDataForm = {
    society_id: 0,
    batch_type_id: 0,
    brand_id: 0,
    code: '',
    description: '',
    capacity: 0,
    unit_capacity_id: 0,
    depot_id: 0,
    product_label_id: 0
};

export interface BatchFilters {
    id?: number | null;
    product_label?: string | '';
    batch_type?: string | '';
    status?: string | '';
    depot?: string | '';

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface BatchStatus {
    id?: string;
    name?: string;
}
