import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import NoteFormContainer from './NoteFormContainer';
import { PaymentStatus } from '../../../app/models/PaymentStatus';
import { NoteCauses, TaxDocumentPaymentDataForm, NoteTypes } from '../../../app/models/TaxDocument';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import useClientService from '../../../app/services/hooks/useClientService';
import useTaxDocumentService from '../../../app/services/hooks/useTaxDocumentService';
import { TaxDocument } from '../../../app/models/TaxDocument';
import { TaxDocumentStatus } from '../../../app/models/TaxDocumentStatus';
import { Tax } from '../../../app/models/Tax';
import useNavigationPage from '../../../hooks/useNavigationPage';

interface Props {
    documentType: string | null;
    onSaved?: (data: any) => void;
    onCancel?: () => void;
    onError?: () => void;
}

const NoteCreate = ({documentType, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [note, setNote] = useState<any>({});
    const { navigationPage } = useNavigationPage();
    const [taxDocuments, setTaxDocuments] = useState<TaxDocument[]>([]);
    const [noteTypes, setNoteTypes] = useState<NoteTypes[]>([]);
    const [causes, setCauses] = useState<NoteCauses[]>([]);
    const [paymentStatuses, setPaymentStatuses] = useState<TaxDocumentPaymentDataForm[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);
    const { getClientsBySociety, fetchingGetClientBySociety } = useClientService();
    const [filteredTaxDocuments, setFilteredTaxDocuments] = useState<TaxDocument[]>([]);

    const [taxes, setTaxes] = useState<Tax[]>([]);

    const {createNote, fetchingCreateNote} = useTaxDocumentService();
    const { storeNote,getPaymentStatusesByClientSociety } = useTaxDocumentService();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (!documentType) {
            toast.error('El tipo de documento no está definido.');
            if (onError) onError();
            return;
        }
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createNote({
            onSuccess: (response: ServiceResponse) => {
                const taxDocuments = response.data.tax_documents || [];
                setTaxDocuments(taxDocuments);
                setSocieties(response.data.societies);
                setNoteTypes(response.data.note_types || []);
        
                if (taxDocuments.length > 0) {
                    const taxDocument = taxDocuments[0];
                    setNote({
                        total_neto: taxDocument.total_neto,
                        total_tax: taxDocument.total_tax,
                        total_amount: taxDocument.total_amount,
                        document_type: documentType,
                        society: taxDocument.society,
                        client: taxDocument.client,
                        note_type: undefined,
                        cause: undefined,
                    });
                } else {
                    toast.info('No hay documentos de venta contabilizados.');
                }
        
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

        useEffect(() => {
            if (note.society_id && note.society_id !== societyIdSelected.current) {
                getClientsBySociety(note.society_id, {
                    onSuccess: (response: ServiceResponse) => {
                        setClients(response.data.clients);
                        societyIdSelected.current = note.society_id;
        
                        setNote ({
                            ...note,
                            client_id: note.related_document_id ? note.client_id : 0,
                        });
                    },
                    onError: (response: ServiceResponse) => {
                        toast.error(response.message);
                    },
                });
            }
        }, [note.society_id]);

        useEffect(() => {
            if (note.society_id && note.client_id) {
                const filteredDocuments = taxDocuments.filter(
                    (doc) =>
                        doc.society?.id === note.society_id &&
                        doc.client?.id === note.client_id
                );
                setFilteredTaxDocuments(filteredDocuments);
            } else {
                setFilteredTaxDocuments(taxDocuments);
            }
        }, [note.society_id, note.client_id, taxDocuments]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando factura de venta...');
        storeNote(note, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message, { autoClose: 2500 });
                const noteId = response.data?.tax_document_id;
                if (noteId) {
                    navigationPage(`/tax-documents/${noteId}/edit`);
            }
                if (onSaved) {
                    onSaved(response.data);
            }

            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateNote ? (
        <div>Cargando...</div>
    ) : (
        <NoteFormContainer
            action={store}
            cancel={cancel}
            taxDocuments={filteredTaxDocuments}
            societies={societies}
            clients={clients}
            note={note}
            setNote={setNote}
            noteTypes={noteTypes}
            setNoteTypes = {setNoteTypes}
            causes={causes}
            setCauses={setCauses}
            availableStatuses={paymentStatuses}
            errorFields={errorFields}
            updated={false}
            disabled={false}
        />
    );
};

export default NoteCreate;
