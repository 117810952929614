import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { Society } from '../../../app/models/Society';
import { Provider } from '../../../app/models/Provider';
import { useHistory } from 'react-router-dom';
import usePurchaseOrderService from '../../../app/services/hooks/usePurchaseOrderService';
import { PurchaseorderDataForm, defaultPurchaseOrderDataForm } from '../../../app/models/PurchaseOrder';
import { Incoterm } from '../../../app/models/Incoterm';
import { PaymentType } from '../../../app/models/PaymentType';
import PurchaseOrderForm from './forms/PurchaseOrderForm';
import useSocietyService from '../../../app/services/hooks/useSocietyService';
import useProviderService from '../../../app/services/hooks/useProviderService';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Place } from '../../../app/models/Place';
import { Currency } from '../../../app/models/Currency';
import { PaymentMethod } from '../../../app/models/PaymentMethod';
import { ProviderContact } from '../../../app/models/ProviderContact';
import useProviderContactService from '../../../app/services/hooks/useProviderContactService';
import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';


    interface Props {
        onSaved?: () => void;
        onCancel?: () => void;
        onError?: () => void;
    }

    const PurchaseOrderCreate = ({ onSaved, onCancel, onError }: Props) => {
        const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
        const { navigationPage } = useNavigationPage();

        const {
            createPurchaseOrder, 
            storePurchaseOrder, 
            fetchingCreatePurchaseOrder, 
            fetchingStorePurchaseOrder 
        } = usePurchaseOrderService();

        const {
            getSociety,
            fetchingGetSociety,
            getSocietyAndProviders,
            fetchingGetSocietyAndProviders
        } = useSocietyService();

        const {
            getProvider,
            // usar este service para traer contactos del proveedor
            getProviderContact, 
            fetchingGetProvider,
            fetchingGetProviderContact,
        } = useProviderService();

        // const {
        //     getProviderContactByProvider,
        //     fetchingGetProviderContactByProvider,
        // } = useProviderContactService();

        // const {
        //     getBusinessNameContactByProvider,
        //     fetchingGetBusinessNameContactsByProvider
        // } = useBusinessNameContactService();

        const [societies, setSocieties] = useState<Society[]>([]);
        const [providers, setProviders] = useState<Provider[]>([]);
        const [providerContacts, setProviderContacts] = useState<ProviderContact[]>([]);
        const [incoterms, setincoterms] = useState<Incoterm[]>([]);
        const [currencies, setCurrencies] = useState<Currency[]>([]);
        const [paymentConditions, setPaymentConditions] = useState<PaymentCondition[]>([]);
        const [purchaseOrder, setPurchaseOrder] = useState<PurchaseorderDataForm>(defaultPurchaseOrderDataForm);
        const [places, setPlaces] = useState<Place[]>([]);
        const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
        const [errorFields, setErrorFields] = useState<any>();
        
        const providerContactIdSelected = useRef<number | undefined>(0);
        const providerIdSelected = useRef<number | undefined>(0);
        const societyIdSelected = useRef<number | undefined>(0);

        useEffect(() => {
            if (showLoading) showLoading('loading', 'Cargando datos...');

            createPurchaseOrder({
                onSuccess: (response: ServiceResponse) => {
                    setSocieties(response.data.societies);
                    setincoterms(response.data.incoterms);
                    setCurrencies(response.data.currencies);
                    setPaymentConditions(response.data.payment_conditions);
                    setPlaces(response.data.places);
                    setPaymentMethods(response.data.payment_methods)
                    if (hideLoading) hideLoading();
                },
                onError: (response: ServiceResponse) => {
                    
                    if (hideLoading) hideLoading();
                    
                    toast.error(response.message);
                    
                    if (onError) onError();
                }
            });
        }, []);

        useEffect(() => {

            if (purchaseOrder.society_id == undefined) {
                societyIdSelected.current = undefined;
            }
    
            if (purchaseOrder.society_id && purchaseOrder.society_id != societyIdSelected.current) {
    
                getSocietyAndProviders(purchaseOrder.society_id, {
                        onSuccess: (response: ServiceResponse) => {

                            societyIdSelected.current = purchaseOrder.society_id ?? 0;
                            setErrorFields(undefined);

                            setProviders(response.data.providers);
                            setProviderContacts([])
                            setPurchaseOrder({
                                ...purchaseOrder,
                                society_rut: response.data.society.rut,
                                society_name: response.data.society.name,
                                society_address: response.data.society.address,
                                provider_id: 0,
                                provider_contact_id: 0,
                                provider_rut: '',
                                provider_name: '',
                                provider_address: '',
                                provider_name_contact: '',
                                provider_email_contact: '',
                                provider_phone_contact: ''
                            });
                        },
                        onError: (response: ServiceResponse) => {
                            setPurchaseOrder({
                                ...purchaseOrder,
                                society_id: societyIdSelected.current
                            });
                        }
                    })
                    
                }
        }, [purchaseOrder.society_id]);

        useEffect(() => {
            if (purchaseOrder.provider_id == undefined) {
                providerIdSelected.current = undefined;
            }
            if (purchaseOrder.provider_id && purchaseOrder.provider_id != providerIdSelected.current) {
                getProvider(purchaseOrder.provider_id, {
                    onSuccess: (response: ServiceResponse) => {
                        providerIdSelected.current = purchaseOrder.provider_id ?? 0;
                        setErrorFields(undefined);
                        setProviderContacts(response.data.provider_contacts);
                        setPurchaseOrder({
                            ...purchaseOrder,
                            provider_rut: response.data.provider.rut,
                            provider_name: response.data.provider.name,
                            provider_address: response.data.provider.address,
                            provider_contact_id: 0,
                            provider_name_contact: '',
                            provider_email_contact: '',
                            provider_phone_contact: ''
                        });
                    },
                    onError: (response: ServiceResponse) => {
                        setPurchaseOrder({
                            ...purchaseOrder,
                            provider_id: providerIdSelected.current
                        });
                    }
                });
            }
        }, [purchaseOrder.provider_id]);


        useEffect(() => {
          
            if(purchaseOrder.provider_contact_id){

                const providerContact = providerContacts.find(item => item.id === purchaseOrder.provider_contact_id)

                if(providerContact){
                   
                    setPurchaseOrder(
                        {
                            ...purchaseOrder,
                            provider_name_contact: providerContact.name,
                            provider_email_contact: providerContact.email,
                            provider_phone_contact: `${providerContact.phone_code ?? ""}-${providerContact.phone ?? ""}`
                        }
                    )
                }

                
            }
        }, [purchaseOrder.provider_contact_id]);


        const store = () => {
            if (showLoading) showLoading('loading', 'Guardando tracto...');
            storePurchaseOrder(purchaseOrder, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setPurchaseOrder(defaultPurchaseOrderDataForm);
                    navigationPage('/purchase-orders/' + response.data.purchase_order_id + '/edit')
                    if (onSaved) onSaved();
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        };

        const cancel = () => {
            if (onCancel) onCancel();
        };

        // if (errorGetData !== '') {
        //     return <div>{errorGetData}</div>;
        // }

        return fetchingCreatePurchaseOrder ? (
            <LazyLoading height={300} />
        ) : (
            <>
                <div className="row">
                    <div className="col-12">
                        <PurchaseOrderForm
                            purchaseOrderDataForm={purchaseOrder}
                            setPurchaseOrderDataForm={setPurchaseOrder}
                            societies={societies}
                            providers={providers}
                            providerContacts={providerContacts}
                            incoterms={incoterms}
                            paymentMethods={paymentMethods}
                            currencies={currencies}
                            paymentConditions={paymentConditions}
                            errorFields={errorFields}
                            societyLoading={fetchingGetSocietyAndProviders}
                            providerLoading={fetchingGetProvider}
                            providerContactLoading={fetchingGetProviderContact}
                            places={places}
                        />
                    </div>
                </div>
                <hr />
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <ButtonCancelForm callbackCancel={cancel} locked={fetchingStorePurchaseOrder} />
                    </div>
                    <div className="col-auto">
                        <ButtonSaveForm callbackSave={store} locked={fetchingStorePurchaseOrder} />
                    </div>
                </div>
            </>
        );
    };

    export default PurchaseOrderCreate;
