import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Interlocutor, InterlocutorDataForm, InterlocutorFilters } from '../../models/Interlocutor';

const useInterlocutorService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetInterlocutors, setFetchingGetInterlocutors] = useState(false);
    const [fetchingShowInterlocutor, setFetchingShowInterlocutor] = useState(false);
    const [fetchingStoreInterlocutor, setFetchingStoreInterlocutor] = useState(false);
    const [fetchingEditInterlocutor, setFetchingEditInterlocutor] = useState(false);
    const [fetchingUpdateInterlocutor, setFetchingUpdateInterlocutor] = useState(false);
    const [fetchingDeleteInterlocutor, setFetchingDeleteInterlocutor] = useState(false);
    const [fetchingActiveInterlocutor, setFetchingActiveInterlocutor] = useState(false);
    const [fetchingCreateInterlocutor, setFetchingCreateInterlocutor] = useState(false);
    const [
        fetchingGetInterlocutorsDispatchProgram,
        setFetchingGetInterlocutorsDispatchProgram
    ] = useState(false);
    const [fetchingGetInterlocutorsByProviderAndService, setFetchingGetInterlocutorsByProviderAndService] = useState(false);
    // const [FetchingChangeStatusInterlocutor, setFetchingChangeStatusInterlocutor] =
        useState(false);


    const getInterlocutorByDispatchProgram = (
            dispatch_program_id: number,
            filters: InterlocutorFilters,
            events: ServiceEvents = {},
        ) => {
            const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        
            // Construir la URL con el ID reemplazado y el query string
            const url = `${EndPoints.INTERLOCUTOR.GET_INTERLOCUTORS_BY_DISPATCH_PROGRAM.replace(
                ':dispatch_program_id',
                dispatch_program_id.toString()
            )}?${queryString}`;
        
            // Realizar la solicitud GET
            doGet({
                ...events,
                url: url,
                setFetching: setFetchingGetInterlocutorsDispatchProgram,
            });
        };

    const getInterlocutors = (filters: Record<string, string | number>, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.INTERLOCUTOR.GET_INTERLOCUTORS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetInterlocutors
        });
    };

    const showInterlocutor = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INTERLOCUTOR.SHOW_INTERLOCUTOR.replace(':id', id.toString()),
            setFetching: setFetchingShowInterlocutor
        });
    };

    const createInterlocutor = (dispatchProgram: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INTERLOCUTOR.CREATE_INTERLOCUTOR.replace(':id', dispatchProgram.toString()),
            setFetching: setFetchingCreateInterlocutor
        });
    };
    
    const getInterlocutorsByProviderAndService = (dispatchProgram: number, providerId: number, serviceId:number, events: ServiceEvents = {}) => {

        const url = EndPoints.INTERLOCUTOR.GET_INTERLOCUTOR_BY_PROVIDER_AND_SERVICE;
        doGet({
            ...events,
            url: url
            .replace(':dispatch_program_id', dispatchProgram.toString())
            .replace(':provider_id', providerId.toString())
            .replace(':service_id', serviceId.toString()),
            setFetching: setFetchingGetInterlocutorsByProviderAndService
        });
    };
    const storeInterlocutor = (dispatchProgram: number, interlocutor: InterlocutorDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INTERLOCUTOR.STORE_INTERLOCUTOR.replace(':id', dispatchProgram.toString()),
            body: interlocutor,
            setFetching: setFetchingStoreInterlocutor
        });
    };

    const editInterlocutor = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INTERLOCUTOR.EDIT_INTERLOCUTOR.replace(':id', id.toString()),
            setFetching: setFetchingEditInterlocutor
        });
    };

    const updateInterlocutor = (id: number, interlocutor: Interlocutor, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INTERLOCUTOR.UPDATE_INTERLOCUTOR.replace(':id', id.toString()),
            body: interlocutor,
            setFetching: setFetchingUpdateInterlocutor
        });
    };

    const deleteInterlocutor = (
        dispatchProgramId: number,
        interlocutorId: number,
        events: ServiceEvents = {}
    ) => {
        const url = EndPoints.INTERLOCUTOR.DELETE_INTERLOCUTOR
            .replace(':dispatch_program_id', dispatchProgramId.toString())
            .replace(':interlocutor_id', interlocutorId.toString());
    
        doDelete({
            ...events,
            url: url,
            setFetching: setFetchingDeleteInterlocutor
        });
    };

    const activeInterlocutor = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INTERLOCUTOR.ACTIVE_INTERLOCUTOR.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveInterlocutor
        });
    };

    
    return {
        fetchingGetInterlocutorsDispatchProgram,
        fetchingGetInterlocutors,
        fetchingShowInterlocutor,
        fetchingStoreInterlocutor,
        fetchingEditInterlocutor,
        fetchingUpdateInterlocutor,
        fetchingDeleteInterlocutor,
        fetchingActiveInterlocutor,
        fetchingCreateInterlocutor,
        fetchingGetInterlocutorsByProviderAndService,
        // FetchingChangeStatusInterlocutor,
        // changeToStatusInterlocutor,
        getInterlocutorByDispatchProgram,
        createInterlocutor,
        getInterlocutors,
        showInterlocutor,
        storeInterlocutor,
        editInterlocutor,
        updateInterlocutor,
        deleteInterlocutor,
        activeInterlocutor,
        getInterlocutorsByProviderAndService
    };
};

export default useInterlocutorService;
