import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import useInterlocutorService from '../../../app/services/hooks/useInterlocutorService';
import InterlocutorsDataTable from './tables/InterlocutorsDataTable';
import InterlocutorCreate from './InterlocutorCreate';
import LazyLoading from '../../../components/LazyLoading';
import { Interlocutor, InterlocutorFilters } from '../../../app/models/Interlocutor';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';


interface Props {
    dispatchProgramId: number;
    interlocutors: Interlocutor[]
    totalRows: number;
    reloadModuleInterlocutor: () => void;
    filter: InterlocutorFilters;
    setFilter: (filter: InterlocutorFilters) => void;
    isShow?: boolean;
    loadingInterlocutors: boolean;
}

const InterlocutorsContainer = ({ 
    dispatchProgramId,
    interlocutors,
     reloadModuleInterlocutor,
     filter,
     setFilter,
     totalRows,
     loadingInterlocutors,
     isShow=false
}: Props) => {
    const {
        deleteInterlocutor,
        fetchingDeleteInterlocutor,
    } = useInterlocutorService();

    const { showLoading, hideLoading } = useContext(AppContext);


    const [totalRowsInterlocutors, setTotalRowsInterlocutors] = useState<number>(0);
    const [showingCreate, setShowingCreate] = useState(false);

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const destroy = (interlocutorId: number) => {
        const _text = `Está a punto de eliminar el interlocutor con ID #${interlocutorId}.`;
    
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando interlocutor...');
                        deleteInterlocutor(dispatchProgramId, interlocutorId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
    
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
    
                                if (reloadModuleInterlocutor) reloadModuleInterlocutor();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
    
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El interlocutor no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };
    return (
        <>

            <div className="col-12">
                <div className="row mb-3">
                    <div className="col-auto">
                        <ButtonCreate callbackCreate={showCreate} title="Nuevo interlocutor" />
                    </div>
                </div>
            </div>
            <DefaultCard>
                <div className="card-body">
                    <InterlocutorsDataTable
                        interlocutors={interlocutors}
                        filter={filter}
                        setFilter={setFilter}
                        callbackSuccess={reloadModuleInterlocutor}
                        totalRows={totalRows}
                        destroy={destroy}
                        isShow={isShow}
                        loading={loadingInterlocutors}
                    />
                </div>
            </DefaultCard>

            {showingCreate && (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear interlocutor"
                    backdrop={true}
                    showFooter={false}
                >
                    <InterlocutorCreate
                        onSaved={() => {
                            reloadModuleInterlocutor();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                        dispatchProgramId={dispatchProgramId}
                    />
                </DefaultModal>
            )}
        </>
    );
};

export default InterlocutorsContainer;
