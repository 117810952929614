import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import NoteForm from './forms/NoteForm';
import { NoteCauses, NoteTypes, TaxDocumentPaymentDataForm } from '../../../app/models/TaxDocument';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { TaxDocument } from '../../../app/models/TaxDocument';
import { Tax } from '../../../app/models/Tax';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    note: any;
    setNote: (note: any) => void;

    noteTypes: NoteTypes[];
    setNoteTypes: (note: any) => void;
    causes: NoteCauses[];
    setCauses: (causes: any) => void;

    taxesData?: Tax[];

    availableStatuses: TaxDocumentPaymentDataForm[];  
    errorFields?: any;
    updated: boolean;
    disabled: boolean;
    taxDocuments: TaxDocument[];
    societies: Society[];
    clients: Client[];
    loadingClient?: boolean;


}

const NoteFormContainer = ({
    fetching,
    action,
    cancel,
    note,
    noteTypes,
    causes,
    setNote,
    setNoteTypes,
    setCauses,
    taxesData,
    availableStatuses,
    updated = false,
    disabled= false,
    errorFields = null,
    taxDocuments,
    societies,
    clients,
    loadingClient
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <NoteForm
                        note={note}
                        setNote={setNote}
                        societies={societies}
                        clients={clients}
                        noteTypes={noteTypes}
                        setNoteTypes={setNoteTypes}
                        causes={causes}
                        setCauses={setCauses}
                        taxDocuments = {taxDocuments}
                        taxesData={taxesData}
                        availableStatuses={availableStatuses}  
                        errorFields={errorFields}
                        disabled={updated}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default NoteFormContainer;
