import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { TaxDocumentHistoryFilters } from '../../models/TaxDocumentHistory';

const useTaxDocumentHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetTaxDocumentHistoriesByInvoice,
        setFetchingGetTaxDocumentHistoriesByInvoice
    ] = useState(false);

    const getAllTaxDocumentHistoriesByInvoice = (
        taxDocumentId: number,
        filter: TaxDocumentHistoryFilters,
        events: ServiceEvents = {}
    ) => {

        const queryString = Object.entries(filter)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
        const url = `${EndPoints.TAX_DOCUMENT_HISTORY.GET_TAX_DOCUMENT_HISTORIES_BY_TAX_DOCUMENT}?${queryString}`;

        doGet({
            ...events,
            url: url.replace(
                ':tax_document_id',
                taxDocumentId.toString()
            ),
            setFetching: setFetchingGetTaxDocumentHistoriesByInvoice
        });
    };

    return {
        getAllTaxDocumentHistoriesByInvoice,
        fetchingGetTaxDocumentHistoriesByInvoice
    };
};

export default useTaxDocumentHistoryService;
