import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import TaxDocumentFormContainer from './TaxDocumentFormContainer';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { TaxDocumentDataForm, TaxDocumentPaymentDataForm } from '../../../app/models/TaxDocument';
import useTaxDocumentService from '../../../app/services/hooks/useTaxDocumentService';
import { TaxDocumentDetail } from '../../../app/models/TaxDocumentDetail';
import { TaxDocumentHistoryDataForm } from '../../../app/models/TaxDocumentHistory';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import NoteFormContainer from './NoteFormContainer';
import { NoteCauses, NoteTypes } from '../../../app/models/TaxDocument';
import { Currency } from '../../../app/models/Currency';
import { Tax } from '../../../app/models/Tax';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';


interface Props {
    taxDocumentId: number;
    taxDocumentDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    setTaxDocumentDetailTable: (taxDocumentDetail: TaxDocumentDetail[]) => void;
    reloadTaxDocumentTable: () => void;
    setTaxDocumentHistoryTable: (taxDocumentHistory: TaxDocumentHistoryDataForm[]) => void;
    // reloadTaxDocumentHistoryTable: () => void;
}

const TaxDocumentsEdit = ({ 
    taxDocumentId, 
    taxDocumentDetailId,
    onSaved,
    onCancel,
    onError 
}: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const { editTaxDocument, updateTaxDocument, fetchingEditTaxDocument, updateNote } = useTaxDocumentService();
    const [taxDocument, setTaxDocument] = useState<TaxDocumentDataForm>({});
    const [errorFields, setErrorFields] = useState<any>();
    const [paymentStatuses, setPaymentStatuses] = useState<TaxDocumentPaymentDataForm[]>([]); 
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);

    const [taxes, setTaxes] = useState<Tax[]>([]);
    const isNoteType = taxDocument.document_type_obj?.id === 'NOTA_CREDITO' || taxDocument.document_type_obj?.id === 'NOTA_DEBITO';
    const [noteTypes, setNoteTypes] = useState<NoteTypes[]>([]);
    const [causes, setCauses] = useState<NoteCauses[]>([]);

    // Payment statuses


    useEffect(() => {
        reloadModule();
    }, [taxDocumentId]);


    const edit = (taxDocumentId: number) => {
        if (showLoading) showLoading('loading', 'Cargando documento de venta...');
        
        editTaxDocument(taxDocumentId, {
            onSuccess: (response: ServiceResponse) => {
                const _tax_document = response.data.tax_document;
    
                _tax_document.taxes = [];
    
                setTaxDocument(_tax_document);
                setPaymentStatuses(response.data.payment_statuses);
                setSocieties(response.data.societies);
                setClients(response.data.clients);
                setTaxes(response.data.taxes);
    
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando documento de venta...');
        const updateMethod = isNoteType ? updateNote : updateTaxDocument;
        updateMethod(taxDocumentId!, taxDocument, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message,
                    message_list: response.data.message_list ?? []
                });
            
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        }, false); // Pasa `false` como cuarto argumento
    };
    
    const reloadModule = () => {
        edit(taxDocumentId);
    };


    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditTaxDocument ? (
        <div>Cargando...</div>
    ) : isNoteType ? (
        <NoteFormContainer
            action={update}
            cancel={cancel}
            note={taxDocument}
            setNote={setTaxDocument}
            societies={societies}
            clients={clients}
            setNoteTypes = {setNoteTypes}
            causes={causes}
            setCauses={setCauses}
            noteTypes={noteTypes}
            taxesData={taxes}
            availableStatuses={paymentStatuses}
            errorFields={errorFields}
            updated={true}
            disabled={true}
            taxDocuments={[]}
        />
    ) : (
        <TaxDocumentFormContainer
            action={update}
            cancel={cancel}
            taxDocument={taxDocument}
            setTaxDocument={setTaxDocument}
            societies={societies}
            clients={clients}
            errorFields={errorFields}
            availableStatuses={paymentStatuses}
            updated={true}
            disabled={true}
        />
    );
};

export default TaxDocumentsEdit;
