import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import { Currency } from '../../../app/models/Currency';
import {
    defaultDispatchProgram,
    defaultDispatchProgramShowDataForm,
    DispatchProgram,
    DispatchProgramShowDataForm
} from '../../../app/models/DispatchProgram';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCard from '../../../components/default/DefaultCard';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import DispatchProgramForm from '../components/forms/DispatchProgramForm';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import DispatchProgramShowForm from '../components/forms/DispatchProgramShowForm';
import DispatchProgramItemsContainer from '../components/DispatchProgramItemsContainer';
import { RouterParams } from '../../../app/shared/interfaces';
import { RunnerDataForm } from '../../../app/models/Runner';
import { AuthContext } from '../../../contexts/AuthContext';
import { Roles } from '../../../app/shared/enums';
import { QuoteReferenceDataForm } from '../../../app/models/Quote';
import useSweetAlert from '../../../hooks/useSweetAlert';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Tab, Tabs } from 'react-bootstrap';
import { Interlocutor, InterlocutorFilters } from '../../../app/models/Interlocutor';
import InterlocutorsDataTable from '../components/tables/InterlocutorsDataTable';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import DispatchProgramCreate from '../components/DispatchProgramCreate';
import InterlocutorCreate from '../components/InterlocutorCreate';
import InterlocutorsContainer from '../components/InterlocutorContainer';
import useInterlocutorService from '../../../app/services/hooks/useInterlocutorService';
import useDispatchProgramHistoryService from '../../../app/services/hooks/useDispatchProgramHistoryService';
import { DispatchProgramHistoryDataForm, DispatchProgramHistoryFilters } from '../../../app/models/DispatchProgramHistory';
import DispatchProgramHistoryContainer from '../components/DispatchProgramHistoryContainer';

const DispatchProgramEdit = () => {
    const { id } = useParams<RouterParams>();

    const { auth } = useContext(AuthContext);

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Despachos',
            url: '/dispatch-programs',
            isActive: false
        },
        {
            name: 'Editar despacho #' + id,
            url: '/dispatch-programs/' + id + '/edit',
            isActive: true
        }
    ];

    const {
        editDispatchProgram,
        fetchingEditDispatchProgram,
        fetchingShowDispatchProgram,
        fetchingShowTransportationOrderDispatchProgram,
        updateDispatchProgram,
        showDispatchProgram,
        showTransportationOrderDispatchProgram
    } = useDispatchProgramService();

    const{
        getInterlocutorByDispatchProgram,
        fetchingGetInterlocutorsDispatchProgram
    } = useInterlocutorService()

    const [dispatchProgramForm, setDispatchProgramForm] = useState<DispatchProgram>({
        contact_email: '',
        client_id: 0,
        society_id: 0
    });
    const [dispatchProgramShowDataForm, setDispatchProgramShowDataForm] =
        useState<DispatchProgramShowDataForm>(defaultDispatchProgramShowDataForm);

    const [errorFields, setErrorFields] = useState<any>();
    const [canBeEdited, setCanBeEdited] = useState(false);

    const [transportationOrders, setTransportationOrders] = useState<TransportationOrderDataForm[]>(
        []
    );
    const [clients, setClients] = useState<Client[]>([]);
    const [editClients, setEditClients] = useState<Client[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [editSocieties, setEditSocieties] = useState<Society[]>([]);
    const [runners, setRunners] = useState<RunnerDataForm[]>([]);
    const [availableUse, setAvailableUse] = useState<boolean>(false);
    const [transportationSubOrderItemId, setTransportationSubOrderItemId] = useState<number>(0);
    const [isNotAuthorized, setIsNotAuthorized] = useState(false);

    const [interlocutors, setInterlocutors] = useState<Interlocutor[]>([]);
    const [interlocutorsFilter, setInterlocutorsFilter] = useState<InterlocutorFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });
    
    const [totalRowsInterlocutors, setTotalRowsInterlocutors] = useState<number>(0);

    const [showingCreate, setShowingCreate] = useState(false);

    const {getAllDispatchProgramHistoriesByDispatchProgram, fetchingGetDispatchProgramHistoriesByDispatchProgram} = useDispatchProgramHistoryService();
    const [dispatchProgramHistory, setDispatchProgramHistory] = useState<DispatchProgramHistoryDataForm[]>([]);
    const [totalHistoryRows, setTotalHistoryRows] = useState<number>(0);
    const [filterHistory, setFilterHistory]= useState<DispatchProgramHistoryFilters>({
            page: 1,
            per_page: 10,
            sort: 'id',
            order: 'desc'
        });


    const dispatchProgramFromToi = useRef<DispatchProgram|undefined>();
    const clientsFromToi = useRef<Client[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);
    const clientIdSelected = useRef<number | undefined>(0);
    const { navigationPage } = useNavigationPage();


    useEffect(() => {
        if (
            auth?.roles?.includes(Roles.ADMIN) ||
            auth?.roles?.includes(Roles.MANAGERS) ||
            auth?.roles?.includes(Roles.OPERATIONS_MANAGER)
        ) {
            setAvailableUse(false);
        } else {
            setAvailableUse(true);
        }
    });

    useEffect(() => {
        reloadModule();
    }, []);

    useEffect(() =>{
        reloadModuleInterlocutor();
    }, [interlocutorsFilter]);

    useEffect(() => {
            reloadTableHistory();
        }, [filterHistory]);

    const reloadModule = () => {
        edit(parseInt(id));
        // show(parseInt(id));
    };

    
    const reloadModuleInterlocutor = () => {
        getInterlocutorsByDispatch();
        // show(parseInt(id));
    };

    const edit = (id: number) => {
        setCanBeEdited(false);
        editDispatchProgram(id, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationSubOrderItemId(
                    response.data.dispatch_program.transportation_order_item_id
                );
                if (
                    response.data.dispatch_program.transportation_order_id != '' ||
                    response.data.dispatch_program.transportation_order_id != null
                ) {
                    setCanBeEdited(true);
                }
                if (
                    auth?.roles?.includes(Roles.RUNNER) &&
                    auth?.roles?.length == 1 &&
                    response.data.dispatch_program.runner_id != auth?.id
                ) {
                    // setIsNotAuthorized(true);
                }
                const societies = response.data.societies.map((society: Society) => {
                    if(society.id === response.data.dispatch_program.society_id){
                        const _society = {
                            ...society,
                            name: response.data.dispatch_program.society_name
                        }
                        return _society;
                    }
                    return society;
                });

                setSocieties(societies);
                setEditSocieties(response.data.societies);

                const clients = response.data.clients.map((client: Client) => {
                    if(client.id === response.data.dispatch_program.client_id){
                        const _client = {
                            ...client,
                            name: response.data.dispatch_program.client_name
                        }
                        return _client;
                    }
                    return client;
                }
                );
                
                setClients(clients);
                setEditClients(response.data.clients);

                setTransportationOrders(response.data.transportation_orders);
                setRunners(response.data.runners);
                societyIdSelected.current = response.data.dispatch_program.society_id;
                clientIdSelected.current = response.data.dispatch_program.client_id;
                setDispatchProgramForm(response.data.dispatch_program);
                
                clientsFromToi.current = response.data.clients;
                dispatchProgramFromToi.current = response.data.dispatch_program;
                setDispatchProgramForm({
                    ...response.data.dispatch_program,
                    status_selected: response.data.dispatch_program.status_selected
                });
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/dispatch-programs')
                }
            }
        });
    };

    useEffect(() => {
        if (societyIdSelected.current && dispatchProgramForm.society_id != societyIdSelected.current) {
            setSocieties(editSocieties);
        }
    }, [dispatchProgramForm.society_id]);

    useEffect(() => {
        if (clientIdSelected.current && dispatchProgramForm.client_id != clientIdSelected.current) {
            setClients(editClients);
        }
    }, [dispatchProgramForm.client_id]);

    const show = (id: number) => {
        showDispatchProgram(id, {
            onSuccess: (response: ServiceResponse) => {
                setDispatchProgramShowDataForm(response.data.dispatch_program);
            }
        });
    };

    const reloadTableHistory = () => {
            getAllDispatchProgramHistoriesByDispatchProgram(Number(id),filterHistory, {
                onSuccess: (response: ServiceResponse) => {
                    setDispatchProgramHistory(response.data.dispatch_program_histories);
                    setTotalHistoryRows(response.data.total_rows);
                    },
                    onError: (response: ServiceResponse) => {
                        toast.error(response.message, {
                            autoClose: 2500
                        });
                    }
                
            });
        };

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando despacho..');
        updateDispatchProgram(dispatchProgramForm.id!, dispatchProgramForm, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);

                setErrorFields(undefined);
                reloadModule();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const getInterlocutorsByDispatch = () => {

        getInterlocutorByDispatchProgram(parseInt(id),interlocutorsFilter, {
            onSuccess: (response: ServiceResponse) => {
                setInterlocutors(response.data.interlocutors);
                setTotalRowsInterlocutors(response.data.total_rows)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };


    useEffect(() => {
        console.log("Status del despacho:", dispatchProgramForm.status_selected
            ? dispatchProgramForm.status_selected.name.toString()
            : '');
    }, [dispatchProgramForm.status_selected]);


    return (
        <>
            <Breadcrumbs pageSection={`Editar despacho #${id}`} breadcrumbs={breadcrumbs} />
            {isNotAuthorized ? (
                <div className="alert alert-danger">
                    No tienes permisos para editar este despacho
                </div>
            ) : (
                <>
                <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                    <Tab eventKey="general-data" title="Datos Generales">
                        <div className="row">
                            <div className="col-md-6">
                                <DefaultCard>
                                    <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                    {!fetchingEditDispatchProgram ? (
                                        canBeEdited ? (
                                            <>
                                                <DispatchProgramForm
                                                    dispatchProgramForm={dispatchProgramForm}
                                                    setDispatchProgramForm={setDispatchProgramForm}
                                                    societies={societies}
                                                    clients={clients}
                                                    runners={runners}
                                                    errorFields={errorFields}
                                                    transportationOrders={transportationOrders}
                                                    disabled={true}
                                                    availableUse={availableUse}
                                                />
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        <ButtonSaveForm callbackSave={update} />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <DispatchProgramShowForm
                                                dispatchProgramShowDataForm={dispatchProgramShowDataForm}
                                                transportationOrder={null}
                                            />
                                        )
                                    ) : (
                                        <LazyLoading />
                                    )}
                                </DefaultCard>
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                                        {!fetchingEditDispatchProgram ? (
                                            canBeEdited ? (
                                                <>
                                                    <DefaultCreatedModifiedUserForm
                                                        createdBy={dispatchProgramForm.created_user}
                                                        createdAt={dispatchProgramForm.created_at}
                                                        updatedBy={dispatchProgramForm.last_modified_user}
                                                        updatedAt={dispatchProgramForm.updated_at}
                                                        status={
                                                            dispatchProgramForm.status_selected
                                                                ? dispatchProgramForm.status_selected.name.toString()
                                                                : ''
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <DefaultCreatedModifiedUserForm
                                                    createdBy={dispatchProgramShowDataForm.created_user}
                                                    createdAt={dispatchProgramShowDataForm.created_at}
                                                    updatedBy={dispatchProgramShowDataForm.last_modified_user}
                                                    updatedAt={dispatchProgramShowDataForm.updated_at}
                                                    status={
                                                        dispatchProgramShowDataForm.status_selected
                                                            ? dispatchProgramShowDataForm.status_selected.name.toString()
                                                            : ''
                                                    }
                                                />
                                            )
                                        ) : (
                                            <LazyLoading />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
 
                    </Tab>

                    <Tab eventKey="interlocutors" title="Interlocutores">

                        <InterlocutorsContainer
                            dispatchProgramId={parseInt(id)}
                            interlocutors={interlocutors}
                            totalRows={totalRowsInterlocutors}
                            reloadModuleInterlocutor={reloadModuleInterlocutor}
                            filter={interlocutorsFilter}
                            setFilter={setInterlocutorsFilter}
                            loadingInterlocutors={fetchingGetInterlocutorsDispatchProgram}

                        />
                    </Tab>
                    
                    {/* DESCOMENTAR_PRODUCCION */}
                    {/* <Tab eventKey="history" title="Historial">
                    <div className="col-12">
                            <DispatchProgramHistoryContainer
                                dispatchProgramId={parseInt(id)}
                                dispatchProgramHistory={dispatchProgramHistory}
                                totalRowsHistory={totalHistoryRows}
                                filterHistory={filterHistory}
                                setFilterHistory={setFilterHistory}
                                setDispatchProgramHistoryTable={setDispatchProgramHistory}
                                reloadDispatchProgramHistoryTable={reloadTableHistory}
                                loading={fetchingGetDispatchProgramHistoriesByDispatchProgram}
                            />

                    </div>
                    </Tab> */}

                </Tabs>
                {!fetchingEditDispatchProgram ? (
                            <>
                                <DispatchProgramItemsContainer
                                    dispatchProgramId={parseInt(id)}
                                    transportationSubOrderItemId={transportationSubOrderItemId}
                                    canBeEdited={false}
                                    reloadModule={reloadModule}
                                    reloadModuleInterlocutor={reloadModuleInterlocutor}
                                    changeStatus={true}
                                    status={
                                        dispatchProgramForm.status_selected
                                            ? dispatchProgramForm.status_selected.name.toString()
                                            : ''
                                    }
                                />
                            </>
                        ) : null}
                </>
            )}
        </>
    );
};

export default DispatchProgramEdit;
