export enum Roles {
    ADMIN = 'administrador',
    MANAGERS = 'gerentes',
    RUNNER = 'runner',
    OPERATIONS_MANAGER = 'jefe_operaciones'
}

export enum Items {
    PRODUCT = 'PRODUCT',
    SERVICE = 'SERVICE'
}

export enum TransportationOrderStatuses {
    ACTIVO = 'ACTIVO',
    FINALIZADO = 'FINALIZADO',
    ANULADO = 'ANULADO'
}

export enum TransportationOrderItemStatuses {
    PREPARACION = 'PREPARACION',
    DESPACHO = 'DESPACHO',
    FINALIZADO = 'FINALIZADO',
    ANULADO = 'ANULADO'
}


export enum ServiceManifestStatuses {
    DRAFT = 'DRAFT',
    CONFIRMED = 'CONFIRMED',
    ANNULLED = 'ANNULLED'
}

export enum PurchaseOrderStatuses {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    ANNULLED = 'ANNULLED'
}

export enum AccountingStatuses {
    ACCOUNTED = 'ACCOUNTED',
    IN_PROGRESS = 'IN_PROGRESS'
}

export enum ServiceType {
    TRANSPORT = 'TRANSPORT',
    STORAGE = 'STORAGE'
}

export enum BusinessEntities {
    SOCIETY = 'SOCIETY',
    PROVIDER = 'PROVIDER',
    CLIENT = "CLIENT"
}

export enum TransferTypes {
    PROVIDER_PAY = 'PROVIDER_PAY',
    CLIENT_PAY = 'CLIENT_PAY',
}

export enum PaymentStatusEnum {
    BORRADOR = 'BORRADOR',
    REVISION = 'REVISION',
    APROBADO = 'APROBADO',
    ANULADO = 'ANULADO'
}

export enum DispatchProgramStatusEnum{
    DESPACHO = "DESPACHO", 
    FINALIZADO = "FINALIZADO", 
    ANULAR = "ANULAR", 
}

export enum DispatchProgramItemStatusEnum{
    PREPARACION = "PREPARACION",
    PLANIFICACION = "PLANIFICACION",
    DESPACHO = "DESPACHO", 
    FINALIZADO = "FINALIZADO", 
    ANULAR = "ANULAR", 
}

export enum SalesInvoiceStatusEnum {
    BORRADOR = 'BORRADOR',
    CONTABILIZADA = 'CONTABILIZADA',
    ANULADA = 'ANULADA',
}

export enum TaxDocumentStatusEnum {
    BORRADOR = 'BORRADOR',
    CONTABILIZADA = 'CONTABILIZADA',
    ANULADA = 'ANULADA'
}