import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { CauseNoteDataForm, NoteTypes } from '../../../../app/models/CauseNote';

type Props = {
    causeNoteDataForm: CauseNoteDataForm;
    setCauseNoteDataForm: (causeNoteDataForm: CauseNoteDataForm) => void;
    noteTypes: NoteTypes[];
    setNoteTypes: (noteTypes: NoteTypes) => void;
    disabled?: boolean;
    errorFields?: any;
};

const CauseNoteForm = ({
    causeNoteDataForm,
    setCauseNoteDataForm,
    noteTypes,
    errorFields,
    disabled = false,
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> |
           React.ChangeEvent<HTMLSelectElement> |
           React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setCauseNoteDataForm({ ...causeNoteDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
     
        setCauseNoteDataForm({
            ...causeNoteDataForm,
            note_type: value as string | undefined,
        });
    };

    const mappedNoteTypes = noteTypes.map((reason) => ({
        value: reason.id ?? null,
        label: `${reason.name}`,
    }));

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={causeNoteDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-form-label">Tipo de Nota</label>
                <div className="col-md-12">
                    <SelectTwo
                        name="note_type"
                        id="note_type"
                        inputValue={causeNoteDataForm.note_type ?? null}
                        options={mappedNoteTypes}
                        hasError={fieldHasError('note_type') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('note_type', value.value)}
                        placeholder="Seleccione un tipo de nota"
                        onFocus={() => onFocusRemove('note_type')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('note_type')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        name="description"
                        id="description"
                        className={`form-control ${fieldHasError('description')}`}
                        value={causeNoteDataForm.description}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('description')}
                        placeholder="Ingrese descripción"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CauseNoteForm;
