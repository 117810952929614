import React from 'react';
import { DispatchProgramShowDataForm } from '../../../../app/models/DispatchProgram';
import { Link } from 'react-router-dom';
import { TransportationOrderStatusRequestDataForm } from '../../../../app/models/TransportationOrder';

interface Props {
    dispatchProgramShowDataForm: DispatchProgramShowDataForm;
    transportationOrder: TransportationOrderStatusRequestDataForm | null;
}

const DispatchProgramShowForm = ({
    dispatchProgramShowDataForm,
    transportationOrder = null
}: Props) => {
    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Pedido de transporte</label>
                <div className="col-md-9">
                    {transportationOrder ? (
                        <Link to={`/transportation-orders/${transportationOrder.id}/show`}>
                            {transportationOrder.name}
                        </Link>
                    ) : (
                        <input
                            type="text"
                            name="transportation_order_id"
                            className="form-control"
                            readOnly={true}
                            value="No asignada"
                        />
                    )}
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="society"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramShowDataForm.society_name}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cliente</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="client"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramShowDataForm.client_name}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Contacto</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="contact_email"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramShowDataForm.contact_email}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Runner</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="runner"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramShowDataForm.runner}
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramShowForm;
