import React, { useEffect, useState } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { NoteCauses, TaxDocumentDataForm, TaxDocumentPaymentDataForm, NoteTypes } from '../../../../app/models/TaxDocument'; 
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { Society } from '../../../../app/models/Society';
import { Client } from '../../../../app/models/Client';
import LazyLoading from '../../../../components/LazyLoading';
import { momentParseDate } from '../../../../helpers';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { TaxDocument } from '../../../../app/models/TaxDocument';
import useTaxDocumentService from '../../../../app/services/hooks/useTaxDocumentService';
import { Tax } from '../../../../app/models/Tax';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';


interface Props {
    note: TaxDocumentDataForm;
    noteTypes: NoteTypes[];
    causes: NoteCauses[];
    societies: Society[];
    clients: Client[];
    taxDocuments: TaxDocument[];
    taxesData?: Tax[];
    disabled?: boolean;
    setNote: (note: TaxDocumentDataForm) => void;
    setNoteTypes: (noteTypes: NoteTypes) => void;
    setCauses: (noteTypes: NoteCauses) => void;
    availableStatuses: TaxDocumentPaymentDataForm[];
    errorFields?: any;
    loadingClient?: boolean;
}

const NoteForm = ({ 
    note,
    setNote,
    taxDocuments,
    societies,
    clients,
    noteTypes,
    taxesData,
    availableStatuses, 
    disabled = false,
    loadingClient,
    errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();

    const [addedPayments, setAddedPayments] = useState<TaxDocumentPaymentDataForm[]>([]);
    const [selectedPayment, setSelectedPayment] = useState<TaxDocumentPaymentDataForm | null>(null);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const { getCauseNote, fetchingGetCauseNote } = useTaxDocumentService();
    const [causes, setCauses] = useState<NoteCauses[]>([]);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);

    const parseCurrencyToNumber = (currency: any) => {
        if (typeof currency !== 'string') {
            return typeof currency === 'number' ? currency : 0;
        }
        return parseFloat(currency.replace(/\./g, '').replace(/,/g, '').replace(/[^0-9.-]+/g, ''));
    };

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('es-CL', {
            style: 'currency',
            currency: 'CLP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    };

    const mappedStatuses = availableStatuses.map((status) => ({
        id: status.id,
        name: `${status.client_name} - Total: ${status.total}`,
        total: status.total,
        client_name: status.client_name,
        total_tax: status.total_tax,
        total_unit: status.total_unit,
        total_neto: status.total_unit,
    }));

    const filteredTaxDocuments = taxDocuments.filter((doc) => {
        return (
            (!note.society_id || doc.society?.id === note.society_id) &&
            (!note.client_id || doc.client?.id === note.client_id)
        );
    });
    
    const mappedTaxDocuments = filteredTaxDocuments.map((taxDoc) => ({
        value: taxDoc.id ?? null,
        label: `${taxDoc.id} - ${taxDoc.folio} - ${taxDoc.document_number} - ${taxDoc.document_type}`,
        society_name: taxDoc.society?.name || '',
    }));
    

    const mappedNoteTypes = noteTypes.map((reason) => ({
        value: reason.id ?? null,
        label: `${reason.name}`,
    }));

    const mappedDynamicCauses = causes.map((cause_note) => ({
        value: cause_note.id ?? null,
        label: `${cause_note.name}`,
    }));

    const handleChangeTaxes = (value: any) => {
        setNote({
            ...note,
            taxes: value, 
            total_unit: note.total_unit ?? 0,
        });
    };

    const handleSelectChange = (name: string, value: any) => {
        const selected = mappedStatuses.find((status) => status.id === value.value);
        setSelectedPayment(selected || null);
    };

    const [visibleTotalNeto, setVisibleTotalNeto] = useState<string | number>('');
    

    // const handleChangeSelectTwoClient = (name: string, value: number | string | null) => {
    //     setNote({
    //         ...note,
    //         [name]: value,
    //         client_email: clients.find((client) => client.id == value)?.email || ''
    //     });
    // };

    // const [note, setNote] = useState<NoteDataForm>({
    //     id: 0,
    //     note_type: undefined,
    //     cause: undefined,
    // });

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        console.log(name)
        if (name === 'society_id') {
            console.log("aaa")
            setNote({
                ...note,
                society_id: value ?? 0,
                related_document_id: "",
                society_name: '',
                client_name: '',
            });
        }
        else if(name === 'client_id'){
            setNote({
                ...note,
                client_id: value ?? 0,
                client_email: clients.find((client) => client.id == value)?.email || '',
                related_document_id: "",
                society_name: '',
                client_name: '',
            });
        }
        
        else if (name === 'related_document') {
            const selectedDocument = taxDocuments.find((doc) => doc.id === value);

                if (selectedDocument) {
                    setNote({
                        ...note,
                        related_document_id: value as number,
                        society_id: selectedDocument.society?.id || undefined,
                        client_id: selectedDocument.client?.id || undefined,
                        society_name: selectedDocument.society?.name || '',
                        client_name: selectedDocument.client?.name || '',
                        total_unit: parseCurrencyToNumber(String(selectedDocument.total_unit || '')),
                        total_tax: parseCurrencyToNumber(String(selectedDocument.total_tax || '')),
                        total_neto: parseCurrencyToNumber(String(selectedDocument.total_neto || '')),
                        total_amount: parseCurrencyToNumber(String(selectedDocument.total_amount || '')),

                    });
                    const payment = {
                        id: selectedDocument.id ?? 0,
                        total_unit: parseCurrencyToNumber(String(selectedDocument.total_unit || '')).toString(),
                        total_tax: parseCurrencyToNumber(String(selectedDocument.total_tax || '')).toString(),
                        total_neto: parseCurrencyToNumber(String(selectedDocument.total_neto || '')).toString(),
                        total: selectedDocument.total_amount ?? 0,
                    };

                setAddedPayments([payment]);
            }
        } else if (name === 'note_type') {
            const shouldResetTotals = value === 'CARGO O ABONO' || value === 'AJUSTE DE TEXTO';
            setNote({
                ...note,
                note_type: value as string | undefined,
                cause_note: undefined,
                cause_note_id: undefined,
                cause_note_name: "",
                total_unit: shouldResetTotals ? 0 : note.total_unit,
                total_neto: shouldResetTotals ? 0 : note.total_neto,
                total_tax: shouldResetTotals ? 0 : note.total_tax,
                total_amount: shouldResetTotals ? 0 : note.total_amount,

            });
    
            if (value) {
                getCauseNote(value.toString(), {
                    onSuccess: (response: any) => {
                        setCauses(response.data.cause_notes || []);
                    },
                    onError: (response: any) => {
                        useReactConfirmAlert().errorAlert({
                            title: 'Error',
                            message: response.message
                        });
                    },
                });
            }
        } else if (name === 'cause_note') {
            setNote({
                ...note,
                cause_note_name: value as string | undefined,
            });
        }
    };

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setNote({ ...note, [name]: value });
    };


    const addPayment = () => {
        if (selectedPayment && !addedPayments.some(p => p.id === selectedPayment.id)) {
            setAddedPayments([...addedPayments, selectedPayment]);
        }
    };

    const removePayment = (id: number) => {
        const updatedPayments = addedPayments.filter(note => note.id !== id);
        setAddedPayments(updatedPayments);
    };


    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setNote({ ...note, ['file']: e[0] });
        }
    };

    const handleChangeSelectTwoClient = (name: string, value: number | string | null) => {
        setNote({
            ...note,
            [name]: value,
            client_email: clients.find((client) => client.id == value)?.email || ''
        });
    };

    const handleRemoveFile = (name: string) => {
        setNote({ 
            ...note, 
            [name]: undefined 
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const { calculateTotalNote, fetchingCalculateTotalNote } = useTaxDocumentService();
    const [dynamicTotal, setDynamicTotal] = useState({
        total_unit: 0,
        total_neto: 0,
        total_tax: 0,
        total_amount: 0
    });

    const calculateTotal = async () => {
        const totalNeto = note.total_unit ?? 0;
        const cant = note.cant ?? 0;
        const taxes = note.taxes ?? [];
    
        calculateTotalNote(
            {
                total_unit: totalNeto,
                taxes: taxes,
                cant: cant,
            },
            {
                onSuccess: (response: any) => {
                    setDynamicTotal(response.data);
    
                    setNote({
                        ...note,
                        total_neto: response.data.total_neto,
                        total_tax: response.data.total_tax,
                        total_amount: response.data.total_amount,
                    });
    
                    if (!note.total_unit) {
                        setVisibleTotalNeto(formatCurrency(response.data.total_unit));
                    }
    
                    // Asegurar que solo se mantenga el último cálculo en la tabla
                    const newPayment: TaxDocumentPaymentDataForm = {
                        id: typeof note.related_document_id === "number"
                            ? note.related_document_id
                            : Date.now(),
                        total_unit: response.data.total_unit,
                        total_neto: response.data.total_neto,
                        total_tax: response.data.total_tax,
                        total: response.data.total_amount,
                    };
    
                    setAddedPayments([newPayment]); // Se reemplaza la lista en lugar de agregar
                },
                onError: (response: any) => {
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                }
            }
        );
    };
    
    const handleUpdateTable = async () => {
        await calculateTotal();
    };

    useEffect(() => {
        let newTotalAmount = addedPayments.reduce((sum, note) => {
            const noteTotal = typeof note.total === 'string' 
                ? parseCurrencyToNumber(note.total)
                : note.total;
            return sum + noteTotal;
        }, 0);
    

        if (note.note_type === 'ANULACION' && note.related_document_id) {
            newTotalAmount = parseCurrencyToNumber(String(note.total_amount)) || newTotalAmount;
        }
        else {
            newTotalAmount = 0;
        }
    
        setTotalAmount(newTotalAmount);

        setNote({
            ...note,
            total_amount: newTotalAmount,
        });
    }, [addedPayments, note.note_type]);

    return (
        <div className="note-form">

        {!disabled && ( 
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={note.society_id}
                        options={SelectTwoMapperOptions(societies)}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione una sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>
        )}
        {!disabled && ( 
                <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Cliente</label>
                        <div className="col-md-9">
                            <SelectTwo
                                name="client_id"
                                id="client_id"
                                inputValue={note.client_id}
                                options={SelectTwoMapperOptions(clients)}
                                hasError={fieldHasError('client_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('client_id', value.value)
                                }
                                placeholder={'Seleccione un cliente'}
                                onFocus={() => onFocusRemove('client_id')}
                                disabled={disabled}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('client_id')}
                            </div>
                        </div>
                    </div>
        )}

        {!disabled && ( 
            <div className="mb-2 row">
                <label className="col-form-label">Documento de venta</label>
                <div className="col-md-12">
                    <SelectTwo
                        name={`form-control ${fieldHasError('related_document_id')}`}
                        id="related_document"
                        inputValue={note.related_document_id ?? null}
                        options={mappedTaxDocuments}
                        hasError={fieldHasError('related_document_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('related_document', value.value)}
                        placeholder="Seleccione un documento"
                        onFocus={() => onFocusRemove('related_document')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('related_document_id')}
                    </div>
                </div>
            </div>
        )}

                <div className="mb-2 row needs-validation">
                            <label className="col-form-label">Sociedad</label>
                            <div className="col-md-12">
                            <input
                                name="society"
                                id="society"
                                type="text"
                                className={`form-control ${fieldHasError('society')}`}
                                value={note.society_name || ''}
                                readOnly={true}
                            />
                            </div>
                    </div>

                    <div className="mb-2 row needs-validation">
                            <label className="col-form-label">Cliente</label>
                            <div className="col-md-12">
                                <input
                                    name="client"
                                    id="client"
                                    type="text"
                                    className={`form-control ${fieldHasError('client')}`}
                                    value={
                                        note.client_name
                                    }
                                    readOnly={true}
                                />
                            </div>
                    </div>

                    {!disabled && ( 
                    <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Folio Documento</label>
                    <div className="col-md-9">
                        <input
                            className={`form-control ${fieldHasError('folio')}`}
                            name="folio"
                            id="folio"
                            type="text"
                            value={note.folio}
                            onChange={handleChange}
                            placeholder="Ingrese el folio de la factura"
                            onFocus={() => onFocusRemove('folio')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('folio')}
                        </div>
                    </div>
                    </div>

                    )}
                    {!disabled && ( 

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Fecha de emisión</label>
                        <div className="col-md-9">
                            <input
                                type="date"
                                className={`form-control ${fieldHasError('issue_date')}`}
                                id="issue_date"
                                name="issue_date"
                                value={note.issue_date}
                                aria-invalid={fieldHasError('issue_date') ? true : undefined}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('issue_date')}
                                // disabled={disabled}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('issue_date')}
                            </div>
                        </div>
                    </div>
                    )}

                <div className="mb-2 row">
                    <label className="col-form-label">Documento de Venta</label>
                    <div className="col-md-9">

                        <InputButtonFile
                            name={'file'}
                            objectFile={note.file}
                            handleChangeDocumentFile={handleChangeDocumentFile}
                            handleRemoveFile={(value: any) => handleRemoveFile('file')}
                            onFocus={() => onFocusRemove('file')}
                            // disabled={disabled}
                        />

                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('file')}
                        </div>
                    </div>
                </div>
            {!disabled && ( 
                <div className="mb-2 row">
                <label className="col-form-label">Tipo de Nota</label>
                <div className="col-md-12">
                <SelectTwo
                    name="note_type"
                    id="note_type"
                    inputValue={note.note_type ?? null}
                    options={mappedNoteTypes}
                    hasError={fieldHasError('note_type') !== ''}
                    onChange={(value: any) => handleChangeSelectTwo('note_type', value.value)}
                    placeholder="Seleccione un tipo de nota"
                    onFocus={() => onFocusRemove('note_type')}
                    disabled={disabled}
                />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('id')}
                    </div>
                </div>
                </div>
            )}
            {!disabled && ( 
                <div className="mb-2 row">
                    <label className="col-form-label">Motivo</label>
                    <div className="col-md-12">
                    <SelectTwo
                        name={`form-control ${fieldHasError('cause_note_id')}`}
                        id="cause_note"
                        inputValue={note.cause_note_id || null}
                        options={mappedDynamicCauses}
                        hasError={fieldHasError('cause_note_id') !== ''}
                        onChange={(selected: any) => {
                            setNote({
                                ...note,
                                cause_note_id: selected.value,
                                cause_note_name: selected.label,
                            });
                        }}
                        placeholder="Seleccione un motivo"
                        onFocus={() => onFocusRemove('cause_note_id')}
                        disabled={disabled}
                    />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('cause_note_id')}
                        </div>
                    </div>
                </div>
            )}
            {disabled && (
                <div className="mb-2 row">
                <label className="col-form-label">Valor</label>
                <div className="col-md-6">
                    <input
                        className={`form-control ${fieldHasError('total_unit')}`}
                        type="text"
                        name="total_unit"
                        id="total_unit"
                        value={visibleTotalNeto}
                        onChange={(e) => {
                            const value = e.target.value;
                    
                            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                                setVisibleTotalNeto(value); 
                                setNote({
                                    ...note,
                                    total_unit: value === '' ? undefined : parseFloat(value),
                                });
                            }
                        }}
                        onFocus={() => onFocusRemove('total_unit')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('total_unit')}
                    </div>
                </div>

                <div className="col-3">
                    <input
                        name="cant"
                        id="cant"
                        type="number"
                        className={`form-control ${fieldHasError('cant')}`}
                        value={note.cant}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('cant')}
                        placeholder="cant"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('cant')}
                    </div>
                </div>

                <div className="mb-2 row">
                <label className="col-form-label">Impuestos</label>
                <div className="col-md-12">
                    <SelectTwo
                        name="tax"
                        id="tax"
                        inputValue={note.taxes || []}
                        options={SelectTwoMapperOptions(taxesData ?? [])}
                        isMulti={true}
                        hasError={fieldHasError('tax') !== ''}
                        onChange={handleChangeTaxes}
                        placeholder="Seleccione uno o más impuestos"
                        onFocus={() => onFocusRemove('tax')}
                        
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('tax')}
                    </div>
                </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={handleUpdateTable}>
                        Actualizar Tabla
                    </button>
                </div>
            </div>
            )}



            <div className="flex-grow-1 me-2">
        </div>
        <div className="table-form">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        {!disabled && <th>Nro Documento</th>}
                        <th>Neto</th>
                        <th>Impuesto</th>
                        <th>Total</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {addedPayments.map((note) => (
                        <tr key={note.id}>
                            {!disabled && <td data-label="Nro">#{note.id}</td>}
                            <td data-label="Neto">{formatCurrency(parseCurrencyToNumber(note.total_neto))}</td>
                            <td data-label="Impuesto">{formatCurrency(parseCurrencyToNumber(note.total_tax))}</td>
                            <td data-label="Total">{formatCurrency(parseCurrencyToNumber(note.total))}</td>
                            <td data-label="Acciones">
                                <ButtonTableAction
                                    callbackFunction={() => removePayment(note.id ?? -1)}
                                    classIcon={'mdi mdi-delete'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede eliminar este registro.'}
                                    title={'Eliminar'}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
            <div className="total mt-3 d-flex justify-content-end">
                <span className="me-2">Total:</span>
                <span>{formatCurrency(totalAmount)}</span>
            </div>
        </div>
    );
};

export default NoteForm;
