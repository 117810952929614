import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { DispatchProgramHistoryFilters } from '../../models/DispatchProgramHistory';

const useDispatchProgramHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetDispatchProgramHistoriesByDispatchProgram,
        setFetchingGetDispatchProgramHistoriesByDispatchProgram
    ] = useState(false);

    const getAllDispatchProgramHistoriesByDispatchProgram = (
        dispatchProgramId: number,
        filter: DispatchProgramHistoryFilters,
        events: ServiceEvents = {}
    ) => {

        const queryString = Object.entries(filter)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
        const url = `${EndPoints.DISPATCH_PROGRAM_HISTORY.GET_DISPATCH_PROGRAM_HISTORIES_BY_DISPATCH_PROGRAM}?${queryString}`;

        doGet({
            ...events,
            url: url.replace(
                ':dispatch_program_id',
                dispatchProgramId.toString()
            ),
            setFetching: setFetchingGetDispatchProgramHistoriesByDispatchProgram
        });
    };

    return {
        getAllDispatchProgramHistoriesByDispatchProgram,
        fetchingGetDispatchProgramHistoriesByDispatchProgram
    };
};

export default useDispatchProgramHistoryService;
