import React from 'react';
import { ServiceFilters } from '../../../../app/models/Service';
import { TaxDocumentFilters } from '../../../../app/models/TaxDocument';

type Props = {
    filter: TaxDocumentFilters;
    setFilter: (filter: TaxDocumentFilters) => void;
};

const ServiceFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    
    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };


    return (
        <div className="row">
            <div className="row">
            <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="id">
                            ID
                        </label>
                        <input
                            title="Nro de Documento"
                            type="text"
                            id="id"
                            name="id"
                            className="form-control form-control-sm"
                            value={filter.id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="document_number">
                            Nro de Documento
                        </label>
                        <input
                            title="Nro de Documento"
                            type="text"
                            id="document_number"
                            name="document_number"
                            className="form-control form-control-sm"
                            value={filter.document_number}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                {/* DESCOMENTAR_PRODUCCION */}
                {/* <div className="col-md-auto">
                <label htmlFor="selectGrande" className="mb-1 font-size-10 ">Tipo de Documento</label>
                <select                         
                            name="document_type"
                            className="form-select form-select-sm"
                            value={filter.document_type}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'FACTURA'} value="FACTURA">
                            Factura electrónica
                            </option>
                            <option key={'NOTA_CREDITO'} value="NOTA_CREDITO">
                            Nota de Crédito electrónica
                            </option>
                            <option key={'NOTA_DEBITO'} value="NOTA_DEBITO">
                            Nota de Débito electrónica
                            </option>
                        </select>
                </div>
                <div className="col-md-auto">
                <label htmlFor="selectGrande" className="mb-1 font-size-10 ">Tipo de Nota</label>
                <select 
                            name="note_type"
                            className="form-select form-select-sm"
                            value={filter.note_type}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'CARGO'} value="CARGO">
                            Cargo
                            </option>
                            <option key={'ABONO'} value="ABONO">
                            Abono
                            </option>
                            <option key={'ANULACION'} value="ANULACION">
                            Anulacion
                            </option>
                            <option key={'AJUSTE DE TEXTO'} value="AJUSTE DE TEXTO">
                            Ajuste de Texto
                            </option>
                        </select>
                </div> */}
                <div className="col-md-auto">
                <label htmlFor="selectGrande" className="mb-1 font-size-10 ">Estado</label>
                <select                 
                            name="status"
                            className="form-select form-select-sm"
                            value={filter.status}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'BORRADOR'} value="BORRADOR">
                            Borrador
                            </option>
                            {/* <option key={'ANULADA'} value="ANULADA">
                            Anulada
                            </option> */}
                            <option key={'CONTABILIZADA'} value="CONTABILIZADA">
                            Contabilizada
                            </option>
                        </select>
                </div>
            </div>
        </div>

        
    );
};

export default ServiceFilter;
