import React from 'react';
import { momentParseDate } from '../../../helpers';

interface Props {
    taxDocument: any;
}

const TaxDocumentCondition = ({ taxDocument }: Props) => {
    return (
        <div className="tax-document-condition">
            <div className="row">
                {/* Row for Payment Method */}
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label htmlFor="paymentMethod">Método de pago:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={taxDocument?.payment_method || ''}
                            disabled
                        />
                    </div>
                </div>
                
                {/* Spacer column to align the next row underneath */}
                <div className="col-md-6"></div>

                {/* Row for Payment Condition and Due Date */}
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label htmlFor="paymentCondition">Condición de pago:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={taxDocument?.payment_condition || ''}
                            disabled
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label htmlFor="dueDate">Fecha de vencimiento:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={momentParseDate(taxDocument?.due_date || '')}

                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaxDocumentCondition;
