import React, { useState } from 'react';
import useTaxDocumentService from '../../../app/services/hooks/useTaxDocumentService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { TaxDocumentStatusRequestDataForm } from '../../../app/models/TaxDocument';
import { TaxDocumentStatusEnum } from '../../../app/shared/enums';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    taxDocumentId: number;
    statuses: TaxDocumentStatusRequestDataForm[];
    selectedStatus: TaxDocumentStatusRequestDataForm;
    callbackSuccess?: () => void;
}

const TaxDocumentChangeStatus = ({ taxDocumentId, statuses, selectedStatus, callbackSuccess }: Props) => {
    const [selected, setSelected] = useState<TaxDocumentStatusRequestDataForm>(selectedStatus);
    const [paymentStatusIds, setPaymentStatusIds] = useState<number[]>([]);  // Lista para los payment_status_ids
    const [cancellationReason, setCancellationReason] = useState<string>('');  // Estado para la razón de anulación

    const { getTaxDocumentDetails, taxDocumentAccounted, taxDocumentDraft, taxDocumentAnnulled } = useTaxDocumentService();

    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (callbackSuccess) {
                callbackSuccess();
            }
            useReactConfirmAlert().successAlert({
                title: 'Éxito',
                message: 'Se ha cambiado el estado del Documento #' + taxDocumentId
            });
        },
        onError: (response: ServiceResponse) => {
            useReactConfirmAlert().errorAlert({
                title: 'Error',
                message: response.message
            });
            if (callbackSuccess) {
                callbackSuccess();
            }
            toast.error(response.message);
        }
    };

    const changeStatus = (value: string) => {
        if (value === selected.id) return null;
    
        const _status = statuses.find((status: TaxDocumentStatusRequestDataForm) => status.id === value);
    
        setSelected(_status!);
    
        getTaxDocumentDetails(taxDocumentId, {
            onSuccess: (response: ServiceResponse) => {
                const paymentStatusIdsResponse = response.data.tax_document.payment_status_ids || [];
                const docType = response.data.tax_document.document_type|| "";
                setPaymentStatusIds(paymentStatusIdsResponse);
                
                let _text = '';

                if (docType === "FACTURA") {
                    if (value === TaxDocumentStatusEnum.ANULADA) {
                        _text = `Está a punto de cambiar el estado del Documento #${taxDocumentId} a Anulada. Los estados de pago [${paymentStatusIdsResponse.join(', ')}] serán desvinculados del Documento al realizar la anulación.`;
                    } else if (value === TaxDocumentStatusEnum.CONTABILIZADA) {
                        _text = `Está a punto de cambiar el estado del Documento #${taxDocumentId} a Contabilizada con los siguientes estados de pago [${paymentStatusIdsResponse.join(', ')}].`;
                    } else if (value === TaxDocumentStatusEnum.BORRADOR) {
                        _text = `Está a punto de cambiar el estado del Documento #${taxDocumentId} a Borrador.`;
                    }
                } else {
                    if (value === TaxDocumentStatusEnum.ANULADA) {
                        _text = `Está a punto de cambiar el estado del Documento #${taxDocumentId} a Anulada.`;
                    } else if (value === TaxDocumentStatusEnum.CONTABILIZADA) {
                        _text = `Está a punto de cambiar el estado del Documento #${taxDocumentId} a Contabilizada.`;
                    } else if (value === TaxDocumentStatusEnum.BORRADOR) {
                        _text = `Está a punto de cambiar el estado del Documento #${taxDocumentId} a Borrador.`;
                    }
                }
    
                useReactConfirmAlert().requestConfirmation({
                    title: '¿Está seguro?',
                    message: _text,
                    buttons: {
                        confirmButton: {
                            label: 'Sí, cambiar estado',
                            onClick: () => {
                                if (value === TaxDocumentStatusEnum.ANULADA) {
                                    const reason = prompt('Escriba la razón para la anulación:');
                
                                    if (!reason || reason.trim() === '') {
                                        useReactConfirmAlert().errorAlert({
                                            title: 'Error',
                                            message: 'Debe proporcionar una razón para la anulación.',
                                        });
                                        setSelected(selectedStatus);
                                        return;
                                    }
                
                                    taxDocumentAnnulled(taxDocumentId, { reason }, eventChangeStatus);
                                } else {
                                    switch (value) {
                                        case TaxDocumentStatusEnum.CONTABILIZADA:
                                            taxDocumentAccounted(taxDocumentId, eventChangeStatus);
                                            break;
                                        case TaxDocumentStatusEnum.BORRADOR:
                                            taxDocumentDraft(taxDocumentId, eventChangeStatus);
                                            break;
                                        default:
                                            useReactConfirmAlert().errorAlert({
                                                title: 'Error',
                                                message: 'Opción no encontrada.',
                                            });
                                            setSelected(selectedStatus);
                                            break;
                                    }
                                }
                            }
                        },
                        cancelButton: {
                            label: 'No, cancelar',
                            onClick: () => {
                                setTimeout(() => {
                                    useReactConfirmAlert().infoAlert({
                                        title: 'Cancelado',
                                        message: 'El estado del Documento no se ha cambiado.',
                                    });
                                }, 0);
                                setSelected(selectedStatus);
                            }
                        }
                    }
                });
                
            },
            onError: (response: ServiceResponse) => {
                toast.error('Error al obtener los detalles del Documento.');
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => (
                <option key={status.id} value={status.id}>
                    {status.name}
                </option>
            ))}
        </select>
    );
};

export default TaxDocumentChangeStatus;
