export const customStyles = {
    table: {
        style: {
            border: '1px solid #ddd'
        }
    },
    header: {
        style: {
            minHeight: '56px'
        }
    },
    head: {
        style: {}
    },
    headRow: {
        style: {
            backgroundColor: '#eff2f7'
        }
    },
    headCells: {
        
        style: {
            // maxWidth: 'auto',
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#D4D3D3'
            }
        }
    },
    cells: {
        // maxWidth: 'auto',
        style: {
            
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#D4D3D3'
            }
        }
    },
    progress: {
        style: {
            border: 'transparent'
        }
    }
};
