import React, { useEffect } from 'react';
import { TaxDocumentDataForm } from '../../../../app/models/TaxDocument';
import { momentParseDate } from '../../../../helpers';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';

interface Props {
    taxDocument: TaxDocumentDataForm;
    setTaxDocument: (taxDocument: TaxDocumentDataForm) => void;
    errorFields?: any;
    disabled?: boolean;

}

const TaxDocumentDetailForm = ({ taxDocument, setTaxDocument, errorFields,disabled }: Props) => {


    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();


    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);
    
    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setTaxDocument({ ...taxDocument, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setTaxDocument({ ...taxDocument, ['file']: e[0] });
        }
    };

    const handleRemoveFile = (name: string) => {
        setTaxDocument({ 
            ...taxDocument, 
            [name]: undefined 
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };
    return (
        <div className="tax-document-detail-form">
            {/* Tipo de Documento */}
            <div className="form-group" style={{ flex: 1 }}>
                <label htmlFor="tipo_documento">
                    {taxDocument.document_type_obj?.name}
                </label>
            </div>

            {/* Mostrar cause_note si no es FACTURA */}
            {taxDocument.document_type !== "FACTURA" && taxDocument.note_type && taxDocument.cause_note && (
                <>
                    <div className="form-group" style={{ flex: 1 }}>
                        <label htmlFor="note_type">Tipo de Nota: {taxDocument.note_type}</label>
                    </div>
                    <div className="form-group" style={{ flex: 1 }}>
                        <label htmlFor="cause_note">Motivo: {taxDocument.cause_note.name}</label>
                    </div>
                    <div className="form-group" style={{ flex: 1 }}>
                        <label htmlFor="cause_note">
                            Nro Documento Relacionado:
                            <a
                                href={`${window.location.origin}/tax-documents/${taxDocument.related_document?.id}/edit`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ marginLeft: "5px", textDecoration: "underline", color: "blue" }}
                            >
                                {taxDocument.related_document?.document_number}
                            </a>
                        </label>
                    </div>
                </>
            )}

            {/* Espacio adicional */}
            <div className="mt-3"></div>

            {/* Fecha de Emisión and Folio */}
            <div className="form-group-row" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <div className="form-group" style={{ flex: 1 }}>
                        <label htmlFor="issueDate">Fecha de Emisión:</label>
                        <input
                            type="date"
                            className="form-control"
                            id="issue_date"
                            name="issue_date"
                            value={taxDocument.issue_date}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('issue_date')}
                            disabled={disabled}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex', minHeight: '20px', alignItems: 'center' }}>
                            {fieldErrorMessage('issue_date')}
                        </div>
                    </div>
                    <div className="form-group" style={{ flex: 1 }}>
                        <label htmlFor="folio">Folio:</label>
                        <input
                            className={`form-control ${fieldHasError('folio')}`}
                            name="folio"
                            id="folio"
                            type="text"
                            value={taxDocument.folio}
                            onChange={handleChange}
                            placeholder="Ingrese el folio del documento"
                            onFocus={() => onFocusRemove('folio')}
                            disabled={disabled}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex', minHeight: '20px', alignItems: 'center' }}>
                            {fieldErrorMessage('folio')}
                        </div>
                    </div>
                </div>

            {/* Espacio adicional */}
            <div className="mt-3"></div>

            {/* Datos del Emisor */}
            {/* <h4>Datos del Emisor</h4> */}
            <div className="h2 mt-0 mb-2 card-title">Datos del Emisor</div>
            <div className="form-group mb-3">
                <label htmlFor="issuerSociety">Sociedad:</label>
                <input
                    type="text"
                    id="issuerSociety"
                    className="form-control"
                    value={taxDocument?.society_name || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="issuerAddress">Direccion:</label>
                <input
                    type="text"
                    id="issuerAddress"
                    className="form-control"
                    value={taxDocument?.society_address || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="issuerEmail">Email:</label>
                <input
                    type="text"
                    id="issuerEmail"
                    className="form-control"
                    value={taxDocument?.society_email || ''}
                    disabled
                />
            </div>

            {/* Datos del Receptor */}
            <div className="h2 mt-0 mb-2 card-title">Datos del Receptor</div>
            <div className="form-group mb-3">
                <label htmlFor="receiverClient">Cliente:</label>
                <input
                    type="text"
                    id="receiverClient"
                    className="form-control"
                    value={taxDocument?.client_name || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="receiverAddress">Direccion:</label>
                <input
                    type="text"
                    id="receiverAddress"
                    className="form-control"
                    value={taxDocument?.client_address || ''}
                    disabled
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="receiverEmail">Email:</label>
                <input
                    type="text"
                    id="receiverEmail"
                    className="form-control"
                    value={taxDocument?.client_email || ''}
                    disabled
                />
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label htmlFor="receiverEmail">Documento de Venta:</label>
                    {
                        disabled ? (
                            taxDocument.file ? (
                        
                                    <div>
                                        <a
                                            href={String(taxDocument.file)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-success btn-sm"
                                            role="button"
                                            aria-pressed="true"
                                        >
                                            Documento
                                        </a>
                                    </div>
                                ) : (
                                    <div>
                                        <p>No tiene documento</p>
                                    </div>
                                )
                        )
                        :
                        (
<>
                        <InputButtonFile
                            name="file"
                            objectFile={taxDocument.file}
                            handleChangeDocumentFile={handleChangeDocumentFile}
                            handleRemoveFile={(value: any) => handleRemoveFile('file')}
                            onFocus={() => onFocusRemove('file')}
                                disabled={disabled}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                        </div>
                        </>
                        )
                        
                    }
                    
                </div>
            </div>
        </div>
    );
};

export default TaxDocumentDetailForm;
