import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import useServiceManifestService from '../../../app/services/hooks/useServiceManifestService';
import { Society } from '../../../app/models/Society';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import {
    ServiceManifestStatus,
    ServiceManifestDataForm,
    defaultServiceManifestDataForm
} from '../../../app/models/ServiceManifest';
import { Provider } from '../../../app/models/Provider';
import ServiceManifestForm from '../components/forms/ServiceManifestForm';
import { PurchaseorderDataForm } from '../../../app/models/PurchaseOrder';
import usePurchaseOrderService from '../../../app/services/hooks/usePurchaseOrderService';
import ServiceManifestDetailContainer from '../components/ServiceManifestDetailContainer';
import ServiceManifestChangeStatus from '../components/ServiceManifestChangeStatus';
import { Tab, Tabs } from 'react-bootstrap';
import ServiceManifestDocumentContainer from '../components/ServiceManifestDocumentContainer';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { AccountingStatus } from '../../../app/models/Accounting';
import useServiceManifestDetailService from '../../../app/services/hooks/useServiceManifestDetailService';
import { ServiceManifestDetailDataForm, serviceManifestDetailFilters } from '../../../app/models/ServiceManifestDetail';

const ServiceManifestEdit = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'hoja de entrada',
            url: '/service-manifests',
            isActive: false
        },
        {
            name: 'Editar hoja de entrada #' + id,
            url: '/service-manifests/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editServiceManifest,
        fetchingEditServiceManifest,
        fetchingShowServiceManifest,
        fetchingUpdateServiceManifest,
        updateServiceManifest,
        changeStatusServiceManifest,
        fetchingChangeStatusServiceManifest,
        hasAllFinishedStatuses
    } = useServiceManifestService();



    const {
        getServiceManifestDetailsByServiceManifest,
        fetchingGetServiceManifestDetailsByServiceManifest,
        deleteServiceManifestDetail,
        fetchingDeleteServiceManifestDetail
    } = useServiceManifestDetailService();

    const { getPurchaseOrderBySocietyAndProvider, fetchingGetPurchaseOrdersBySocietyAndProvider } =
        usePurchaseOrderService();

    const [serviceManifest, setServiceManifest] = useState<ServiceManifestDataForm>(
        defaultServiceManifestDataForm
    );
    const [purchaseOrders, setPurcharseOrders] = useState<PurchaseorderDataForm[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [editSocieties, setEditSocieties] = useState<Society[]>([]);
    const [editProviders, setEditProviders] = useState<Provider[]>([]);
    const [accountingStatuses, setAccountingStatuses] = useState<AccountingStatus[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const [statuses, setStatuses] = useState<ServiceManifestStatus[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);
    const providerIdSelected = useRef<number | undefined>(0);

    const [serviceManifestDetails, setServiceManifestDetails] = useState<
        ServiceManifestDetailDataForm[]
    >([]);
    const [filter, setFilter] = useState<serviceManifestDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });
    const [totalRows, setTotalRows] = useState<number>(0);


    useEffect(() => {
        reloadModule();
    }, [id]);
    

    const edit = (id: number) => {
        editServiceManifest(id, {
            onSuccess: (response: ServiceResponse) => {
                const societies = response.data.societies.map((society: Society) => {
                    if(society.id === response.data.service_manifest.society_id){
                        const _society = {
                            ...society,
                            name: society.name.split(' ')[0] + ' ' + response.data.service_manifest.society_business_name 
                        }
                        return _society;
                    }
                    return society;
                });
                setSocieties(societies);
                setEditSocieties(response.data.societies);
                const providers = response.data.providers.map((provider: Provider) => {
                    if(provider.id === response.data.service_manifest.provider_id){
                        const _provider = {
                            ...provider,
                            name: provider.name.split(' ')[0] + ' ' + response.data.service_manifest.provider_business_name
                        }
                        return _provider;
                    }
                    return provider;
                }
                );

                setProviders(providers);
                setEditProviders(response.data.providers);
                setPurcharseOrders(response.data.purchase_orders);
                setStatuses(response.data.statuses);
                setServiceManifest(response.data.service_manifest);
                setAccountingStatuses(response.data.accounting_statuses);
                societyIdSelected.current = response.data.service_manifest.society_id;
                providerIdSelected.current = response.data.service_manifest.provider_id;
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/service-manifests');
                }
            }
        });
    };


    useEffect(() => {
        reloadDetailTable();
    }, [filter]);

    const reloadDetailTable = () => {
        getServiceManifestDetailsByServiceManifest(filter,parseInt(id) , {
            onSuccess: (response: ServiceResponse) => {
                setServiceManifestDetails(response.data.service_manifest_details);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };


    useEffect(() => {
        if (serviceManifest.society_id == undefined) {
            societyIdSelected.current = undefined;
        }

        if (serviceManifest.provider_id == undefined) {
            providerIdSelected.current = undefined;
        }

        if (
            serviceManifest.society_id &&
            serviceManifest.provider_id &&
            (serviceManifest.society_id != societyIdSelected.current ||
                serviceManifest.provider_id != providerIdSelected.current)
        ) {
            getPurchaseOrderBySocietyAndProvider(
                serviceManifest.society_id,
                serviceManifest.provider_id,
                parseInt(id),
                {
                    onSuccess: (response: ServiceResponse) => {
                        societyIdSelected.current = serviceManifest.society_id ?? 0;
                        providerIdSelected.current = serviceManifest.provider_id ?? 0;
                        setErrorFields(undefined);
                        setPurcharseOrders(response.data.purchase_orders);
                        if (response.data.purchase_order_instance) {
                            setServiceManifest({
                                ...serviceManifest,
                                purchase_order_id: response.data.purchase_order_instance.id,
                                society_rut: response.data.purchase_order_instance.society_rut,
                                society_business_name:
                                    response.data.purchase_order_instance.society_business_name,
                                provider_rut: response.data.purchase_order_instance.provider_rut,
                                provider_business_name:
                                    response.data.purchase_order_instance.provider_business_name,
                                incoterm: response.data.purchase_order_instance.incoterm,
                                destiny: response.data.purchase_order_instance.destiny,
                                payment_method: response.data.purchase_order_instance.payment_method,
                                payment_condition:
                                    response.data.purchase_order_instance.payment_condition
                            });
                        } else {
                            setServiceManifest({
                                ...serviceManifest,
                                society_rut: '',
                                society_business_name: '',
                                provider_rut: '',
                                provider_business_name: '',
                                incoterm: '',
                                destiny: '',
                                payment_method: '',
                                payment_condition: '',
                                purchase_order_id: 0
                            });
                        }
                    },
                    onError: (response: ServiceResponse) => {
                        setServiceManifest({
                            ...serviceManifest,
                            society_id: societyIdSelected.current,
                            provider_id: providerIdSelected.current
                        });
                        if (hideLoading) hideLoading();
                        toast.error(response.message);
                    }
                }
            );
        }
    }, [serviceManifest.society_id, serviceManifest.provider_id]);

    const reloadModule = () => {
        edit(parseInt(id));
    };

    const update = () => {
        if (serviceManifest != null) {
            if (showLoading) showLoading('loading', 'Actualizando hoja de entrada...');
            updateServiceManifest(Number(serviceManifest.id), serviceManifest, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(undefined);
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
                    reloadDetailTable();
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    const requestChangeStatus = (status: string) => {
        if (showLoading) showLoading('loading', 'Cargando...');
        hasAllFinishedStatuses(parseInt(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                changeStatus(status, response.message);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message,
                    message_list: response.data?.message_list ?? []
                });
                toast.error(response.message);
            }
        });
    };

    const changeStatus = (status: string, message: string) => {
        const _text = `Está a punto de cambiar el estado de la hoja de entrada #${id}.${
            message ?? ''
        }`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusServiceManifest(Number(id), status, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                reloadModule();
                                reloadDetailTable();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La hoja de entrada no ha cambiado el estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection={`Editar hoja de entrada #${id}`} breadcrumbs={breadcrumbs} />

            <Tabs defaultActiveKey="general-data" id="service-manifest-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                {!fetchingEditServiceManifest ? (
                                    <>
                                        <ServiceManifestForm
                                            serviceManifestDataForm={serviceManifest}
                                            setServiceManifestDataForm={setServiceManifest}
                                            societies={societies}
                                            providers={providers}
                                            purchaseOrders={purchaseOrders}
                                            errorFields={errorFields}
                                            purchaseOrdersLoading={
                                                fetchingGetPurchaseOrdersBySocietyAndProvider
                                            }
                                            onEdit={true}
                                        />
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <ButtonSaveForm
                                                    callbackSave={update}
                                                    locked={fetchingUpdateServiceManifest}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <LazyLoading />
                                )}
                            </DefaultCard>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </Tab>
                <Tab eventKey="documents-data" title="Documentos">
                    <ServiceManifestDocumentContainer serviceManifestId={parseInt(id)} />
                </Tab>
            </Tabs>

            <>
                <ServiceManifestDetailContainer
                    serviceManifestId={parseInt(id)}
                    statuses={accountingStatuses}
                    serviceManifestDetails={serviceManifestDetails}
                    setServiceManifestDetails={setServiceManifestDetails}
                    filterDetail={filter}
                    setFilterDetail={setFilter}
                    reloadDetail={reloadDetailTable}
                    totalRowsDetail={totalRows}
                />
            </>

            <ServiceManifestChangeStatus
                status={String(serviceManifest?.status)}
                statuses={statuses}
                requestChangeStatus={requestChangeStatus}
                fetchingChangeStatus={fetchingChangeStatusServiceManifest}
            />
        </>
    );
};

export default ServiceManifestEdit;
