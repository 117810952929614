import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { CauseNoteDataForm, CauseNoteFilters } from '../../models/CauseNote';

const useCauseNoteService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetCauseNotes, setFetchingGetCauseNotes] = useState(false);
    const [fetchingShowCauseNote, setFetchingShowCauseNote] = useState(false);
    const [fetchingCreateCauseNote, setFetchingCreateCauseNote] = useState(false);
    const [fetchingStoreCauseNote, setFetchingStoreCauseNote] = useState(false);
    const [fetchingEditCauseNote, setFetchingEditCauseNote] = useState(false);
    const [fetchingUpdateCauseNote, setFetchingUpdateCauseNote] = useState(false);
    const [fetchingDeleteCauseNote, setFetchingDeleteCauseNote] = useState(false);
    const [fetchingActiveCauseNote, setFetchingActiveCauseNote] = useState(false);

    const getCauseNotes = (CauseNoteFilters: CauseNoteFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(CauseNoteFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CAUSE_NOTE.GET_CAUSE_NOTES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetCauseNotes
        });
    };

    const createCauseNote = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CAUSE_NOTE.CREATE_CAUSE_NOTE,
            setFetching: setFetchingCreateCauseNote
        });
    };

    const showCauseNote = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CAUSE_NOTE.SHOW_CAUSE_NOTE.replace(':id', id.toString()),
            setFetching: setFetchingShowCauseNote
        });
    };

    const storeCauseNote = (costCenter: CauseNoteDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CAUSE_NOTE.STORE_CAUSE_NOTE,
            body: costCenter,
            setFetching: setFetchingStoreCauseNote
        });
    };

    const editCauseNote = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CAUSE_NOTE.EDIT_CAUSE_NOTE.replace(':id', id.toString()),
            setFetching: setFetchingEditCauseNote
        });
    };

    const updateCauseNote = (id: number, costCenter: CauseNoteDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CAUSE_NOTE.UPDATE_CAUSE_NOTE.replace(':id', id.toString()),
            body: costCenter,
            setFetching: setFetchingUpdateCauseNote
        });
    };

    const deleteCauseNote = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CAUSE_NOTE.DELETE_CAUSE_NOTE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCauseNote
        });
    };

    const activeCauseNote = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CAUSE_NOTE.ACTIVE_CAUSE_NOTE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveCauseNote
        });
    };

    return {
        fetchingGetCauseNotes,
        fetchingShowCauseNote,
        fetchingCreateCauseNote,
        fetchingStoreCauseNote,
        fetchingEditCauseNote,
        fetchingUpdateCauseNote,
        fetchingDeleteCauseNote,
        fetchingActiveCauseNote,
        getCauseNotes,
        showCauseNote,
        createCauseNote,
        storeCauseNote,
        editCauseNote,
        updateCauseNote,
        deleteCauseNote,
        activeCauseNote
    };
};

export default useCauseNoteService;
