import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import usePurchaseOrderService from '../../../app/services/hooks/usePurchaseOrderService';
import { Society } from '../../../app/models/Society';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { PurchaseOrderStatus, PurchaseorderDataForm, defaultPurchaseOrderDataForm } from '../../../app/models/PurchaseOrder';
import { Provider } from '../../../app/models/Provider';
import { Incoterm } from '../../../app/models/Incoterm';
import { PaymentType } from '../../../app/models/PaymentType';
import PurchaseOrderForm from '../components/forms/PurchaseOrderForm';
import useSocietyService from '../../../app/services/hooks/useSocietyService';
import useProviderService from '../../../app/services/hooks/useProviderService';
import PurchaseOrderDetailContainer from '../components/PurchaseOrderDetailContainer';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import PurchaseOrderChangeStatus from '../components/PurchaseOrderChangeStatus';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Place } from '../../../app/models/Place';
import { Currency } from '../../../app/models/Currency';
import { PaymentMethod } from '../../../app/models/PaymentMethod';
import useServiceManifestService from '../../../app/services/hooks/useServiceManifestService';
import useProviderContactService from '../../../app/services/hooks/useProviderContactService';
import { ProviderContact } from '../../../app/models/ProviderContact';

const PurchaseOrderEdit = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Ordenes de compra',
            url: '/purchase-orders',
            isActive: false
        },
        {
            name: 'Editar orden de compra #' + id,
            url: '/purchase-orders/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editPurchaseOrder,
        fetchingEditPurchaseOrder,
        fetchingShowPurchaseOrder,
        fetchingUpdatePurchaseOrder,
        updatePurchaseOrder,
        changeStatusPurchaseOrder,
        fetchingChangeStatusPurchaseOrder,
        hasAllFinishedStatuses,
        changeStatusToConfirmedPurchaseOrder,
        changeStatusToAnnulledPurchaseOrder,
        changeStatusToPendingPurchaseOrder
    } = usePurchaseOrderService();

    const {
        getSociety,
        fetchingGetSociety,
        getSocietyAndProviders,
        fetchingGetSocietyAndProviders
    } = useSocietyService();

    const {
        getProvider,
        getProviderContact, 
        fetchingGetProviderContact,
        fetchingGetProvider,
    } = useProviderService();

    // const {
    //     getProviderContactByProvider,
    //     fetchingGetProviderContactByProvider,
    // } = useProviderContactService();


    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseorderDataForm>(defaultPurchaseOrderDataForm);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [editSocieties, setEditSocieties] = useState<Society[]>([]);
    const [editProviders, setEditProviders] = useState<Provider[]>([]);
    const [providerContacts, setProviderContacts] = useState<ProviderContact[]>([]);
    const [incoterms, setincoterms] = useState<Incoterm[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [paymentConditions, setPaymentConditions] = useState<PaymentCondition[]>([]);
    const [places, setPlaces] = useState<Place[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const [statuses, setStatuses] = useState<PurchaseOrderStatus[]>([]);
    
    const purchaseOrderInitial = useRef<PurchaseorderDataForm>(defaultPurchaseOrderDataForm);
    const providersInitial = useRef<Provider[]>([]);
    const providerContactsInitial = useRef<ProviderContact[]>([]);
    const [updateReference, setUpdateReference] = useState<boolean>(false);
    const [hasFinished, setHasFinished] = useState<boolean>(false);

    const societyIdSelected = useRef<number | undefined>(0);
    const providerIdSelected = useRef<number | undefined>(0);
    const providerContactIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        editPurchaseOrder(id, {
            onSuccess: (response: ServiceResponse) => {
                const societies = response.data.societies.map((society: Society) => {
                    if(society.id === response.data.purchase_order.society_id){
                        const _society = {
                            ...society,
                            name: society.name.split(' ')[0] + ' ' + response.data.purchase_order.society_name 
                        }
                        return _society;
                    }
                    return society;
                });
                setSocieties(societies);
                setEditSocieties(response.data.societies);

                const providers = response.data.providers.map((provider: Provider) => {
                    if(provider.id === response.data.purchase_order.provider_id){
                        const _provider = {
                            ...provider,
                            name: response.data.purchase_order.provider_name
                        }
                        return _provider;
                    }
                    return provider;
                }
                );

                setProviders(providers);
                setEditProviders(response.data.providers);
                setincoterms(response.data.incoterms);
                setCurrencies(response.data.currencies);
                setPaymentMethods(response.data.payment_methods);
                setPaymentConditions(response.data.payment_conditions);
                setStatuses(response.data.statuses);
                setPlaces(response.data.places);
                setProviderContacts(response.data.provider_contacts);
                setPurchaseOrder(response.data.purchase_order);
                purchaseOrderInitial.current = response.data.purchase_order;
                providersInitial.current = response.data.providers;
                providerContactsInitial.current = response.data.provider_contacts;
                societyIdSelected.current = response.data.purchase_order.society_id;
                providerIdSelected.current = response.data.purchase_order.provider_id;
                providerContactIdSelected.current = response.data.purchase_order.provider_contact_id;
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/purchase-orders');
               
                }
            }
        });

    };

    useEffect(() => {
        if (purchaseOrder.society_id == undefined) {
            societyIdSelected.current = undefined;
        }
        if (purchaseOrder.society_id && purchaseOrder.society_id != societyIdSelected.current) {

            if((purchaseOrderInitial.current && 
                purchaseOrderInitial.current.society_id != purchaseOrder.society_id) ||
                (!purchaseOrderInitial.current)
            ){
                console.log("preguntando society")
                getSocietyAndProviders(purchaseOrder.society_id, {
                    onSuccess: (response: ServiceResponse) => {
                        societyIdSelected.current = purchaseOrder.society_id ?? 0;
                        const society_name = societies.find(item => item.id === purchaseOrder.society_id)?.name ?? '';
                        
                        setErrorFields(undefined);
                        setProviders(response.data.providers);
                        setProviderContacts([]);
                        setPurchaseOrder({
                            ...purchaseOrder,
                            society_rut: response.data.society.rut,
                            society_name: society_name,
                            society_address: response.data.society.address,
                            provider_id: 0,
                            provider_contact_id: 0,
                            provider_rut: '',
                            provider_name: '',
                            provider_address: '',
                            provider_name_contact: '',
                            provider_email_contact: '',
                            provider_phone_contact: ''
                        });
                        providerIdSelected.current = 0;
                        providerContactIdSelected.current = 0;
                    },
                    onError: (response: ServiceResponse) => {
                        setPurchaseOrder({
                            ...purchaseOrder,
                            society_id: societyIdSelected.current
                        });
                    }
                });

            }
            else if(
                (purchaseOrderInitial.current && 
                    purchaseOrderInitial.current.society_id == purchaseOrder.society_id)
            ){
                console.log("el mismo society de instancia")
                societyIdSelected.current = purchaseOrder.society_id ?? 0;

                const society_name = editSocieties.find(item => item.id === purchaseOrder.society_id)?.name.split(' ').slice(1).join(' ') ?? '';
                setProviders(providersInitial.current);
                setProviderContacts(providerContactsInitial.current);
                setPurchaseOrder(
                    {
                        ...purchaseOrder,
                        society_rut: purchaseOrderInitial.current.society_rut,
                        society_name: society_name,
                        society_address: purchaseOrderInitial.current.society_address,
                        provider_id: purchaseOrderInitial.current.provider_id ?? 0,
                        provider_contact_id: purchaseOrderInitial.current.provider_contact_id ?? 0,
                        provider_rut: purchaseOrderInitial.current.provider_rut ?? '',
                        provider_name: purchaseOrderInitial.current.provider_name ?? '',
                        provider_address: purchaseOrderInitial.current.provider_address ?? '',
                        provider_name_contact: purchaseOrderInitial.current.provider_name_contact ?? '',
                        provider_email_contact: purchaseOrderInitial.current.provider_email_contact ?? '',
                        provider_phone_contact: purchaseOrderInitial.current.provider_phone_contact ?? '',
                    }
                )

            }
            
        }
    }, [purchaseOrder.society_id]);

    useEffect(() => {
        if (purchaseOrder.provider_id == undefined) {
            providerIdSelected.current = undefined;
        }
        if (purchaseOrder.provider_id && purchaseOrder.provider_id != providerIdSelected.current) {
           
            if((purchaseOrderInitial.current && 
                purchaseOrderInitial.current.provider_id != purchaseOrder.provider_id 
            ) || 
                (purchaseOrderInitial.current && 
                 purchaseOrderInitial.current.provider_id == purchaseOrder.provider_id &&
                 purchaseOrderInitial.current.society_id != societyIdSelected.current
            ) ||
                (!purchaseOrderInitial.current)
            ){
                console.log("preguntando provider")
                getProvider(purchaseOrder.provider_id, {
                    onSuccess: (response: ServiceResponse) => {
                        providerIdSelected.current = purchaseOrder.provider_id ?? 0;
                        setErrorFields(undefined);
    
                        setProviderContacts(response.data.provider_contacts);
                        setPurchaseOrder({
                            ...purchaseOrder,
                            provider_rut: response.data.provider.rut,
                            provider_name: providers.find(item => item.id === purchaseOrder.provider_id)?.name ?? '', 
                            provider_address: response.data.provider.address,
                            provider_contact_id: 0,
                            provider_name_contact: '',
                            provider_email_contact: '',
                            provider_phone_contact: ''
                        });

                        providerContactIdSelected.current = 0;
                    },
                    onError: (response: ServiceResponse) => {
                        setPurchaseOrder({
                            ...purchaseOrder,
                            provider_id: providerIdSelected.current
                        });
                    }
                });

            }
            else if(
                (purchaseOrderInitial.current && 
                    purchaseOrderInitial.current.provider_id == purchaseOrder.provider_id &&
                    purchaseOrderInitial.current.society_id == societyIdSelected.current
                )
            ){
                console.log("el mismo provider de instancia")
                providerIdSelected.current = purchaseOrder.provider_id ?? 0;
                const provider_name = editProviders.find(item => item.id === purchaseOrder.provider_id)?.name.split(' ').slice(1).join(' ') ?? '';
                
                setProviderContacts(providerContactsInitial.current);
                setPurchaseOrder(
                    {
                        ...purchaseOrder,
                        provider_contact_id: purchaseOrderInitial.current.provider_contact_id,
                        provider_rut: purchaseOrderInitial.current.provider_rut ?? '',
                        provider_name: provider_name,
                        provider_address: purchaseOrderInitial.current.provider_address ?? '',
                        provider_name_contact: purchaseOrderInitial.current.provider_name_contact ?? '',
                        provider_email_contact: purchaseOrderInitial.current.provider_email_contact ?? '',
                        provider_phone_contact: purchaseOrderInitial.current.provider_phone_contact ?? '',
                    }
                )

            }
            
        }
    }, [purchaseOrder.provider_id]);


    useEffect(() => {

        if (purchaseOrder.provider_contact_id == undefined) {
            providerContactIdSelected.current = undefined;
        }

        if(purchaseOrder.provider_contact_id && purchaseOrder.provider_contact_id != providerContactIdSelected.current){

            console.log("PROVIDER CONTACT")
           
            if((purchaseOrderInitial.current && 
                purchaseOrderInitial.current.provider_contact_id != purchaseOrder.provider_contact_id
                ) ||
                (purchaseOrderInitial.current && 
                    purchaseOrderInitial.current.provider_contact_id == purchaseOrder.provider_contact_id &&
                   ( purchaseOrderInitial.current.provider_id != purchaseOrder.provider_id ||
                    purchaseOrderInitial.current.society_id != societyIdSelected.current)
                ) ||
                (!purchaseOrderInitial.current)
            ){
    
                
                    console.log("preguntando provider contact")
                    providerContactIdSelected.current = purchaseOrder.provider_contact_id;
                    const providerContact = providerContacts.find(item => item.id === purchaseOrder.provider_contact_id)
                    
                    if(providerContact){
                        console.log(providerContact)
                        setPurchaseOrder(
                            {
                                ...purchaseOrder,
                                provider_name_contact: providerContact.name,
                                provider_email_contact: providerContact.email,
                                provider_phone_contact: `${providerContact.phone_code ?? ""}-${providerContact.phone ?? ""}`
                            }
                        )
                    }
            
                
            }
            else if(
                (purchaseOrderInitial.current && 
                    purchaseOrderInitial.current.provider_contact_id == purchaseOrder.provider_contact_id &&
                    purchaseOrderInitial.current.provider_id == providerIdSelected.current &&
                    purchaseOrderInitial.current.society_id == societyIdSelected.current
                )
            ){
                console.log("el mismo provider contact de instancia")
                providerContactIdSelected.current = purchaseOrder.provider_contact_id ?? 0;
                setPurchaseOrder(
                    {
                        ...purchaseOrder,
                        provider_contact_id: purchaseOrderInitial.current.provider_contact_id,
                        provider_name_contact: purchaseOrderInitial.current.provider_name_contact,
                        provider_email_contact: purchaseOrderInitial.current.provider_email_contact,
                        provider_phone_contact: purchaseOrderInitial.current.provider_phone_contact
                    }
                )
    
            }

        }

    }, [purchaseOrder.provider_contact_id]);

    const reloadModule = () => {
        edit(parseInt(id));
    };

    useEffect(() => {
        if (societyIdSelected.current && purchaseOrder.society_id != societyIdSelected.current) {
            setSocieties(editSocieties);
        }
    }, [purchaseOrder.society_id]);

    useEffect(() => {
        if (providerIdSelected.current && purchaseOrder.provider_id != providerIdSelected.current) {
            setProviders(editProviders);
        }
    }, [purchaseOrder.provider_id]);

    const update = () => {
        if (purchaseOrder != null) {
            if (showLoading) showLoading('loading', 'Actualizando orden de compra...');
            updatePurchaseOrder(Number(purchaseOrder?.id), purchaseOrder, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(undefined);
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
                    setUpdateReference(true);
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    // const requestChangeStatus = (status: string) => {
    //     if (showLoading) showLoading('loading', 'Cargando...');
    //     hasAllFinishedStatuses(parseInt(id), status, {
    //         onSuccess: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();
    //             changeStatus(status, response.message, []);
    //         },
    //         onWarning: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();
    //             changeStatus(status, response.message, response.data?.message_list ?? []);
    //         },
    //         onError: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();
    //             useReactConfirmAlert().errorAlert({
    //                 title: 'Error',
    //                 message: response.message,
    //                 message_list: response.data?.message_list ?? []
    //             });
    //             toast.error(response.message);
    //         }
    //     });
    // };

    const changeStatus = (value: string) => {
        const _text = `Está a punto de cambiar el estado de la orden de compra #${id}.`;
        const _status = statuses.find((status: PurchaseOrderStatus) => status.id == value);
        
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        if(_status?.id == 'CONFIRMED'){
                            changeToConfirmed(_status?.id);
                        }else if(_status?.id == 'PENDING'){
                            changeToPending(_status?.id);
                        }else if(_status?.id == 'ANNULLED'){
                            changeToAnnulled(_status?.id);
                        }else if(_status?.id){
                            changeToOther(_status?.id);
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden de compra no ha cambiado el estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const changeToConfirmed = (status: string) => {
        changeStatusToConfirmedPurchaseOrder(Number(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                reloadModule();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const changeToAnnulled = (status: string) => {
        changeStatusToAnnulledPurchaseOrder(Number(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                reloadModule();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const changeToPending = (status: string) => {
        changeStatusToPendingPurchaseOrder(Number(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                reloadModule();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const changeToOther = (status: string) => {
        changeStatusPurchaseOrder(Number(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                reloadModule();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection={`Editar Orden de compra #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditPurchaseOrder ? (
                            <>
                                <PurchaseOrderForm
                                    purchaseOrderDataForm={purchaseOrder}
                                    setPurchaseOrderDataForm={setPurchaseOrder}
                                    societies={societies}
                                    providers={providers}
                                    providerContacts={providerContacts}
                                    incoterms={incoterms}
                                    currencies={currencies}
                                    paymentMethods={paymentMethods}
                                    paymentConditions={paymentConditions}
                                    errorFields={errorFields}
                                    societyLoading={fetchingGetSocietyAndProviders}
                                    providerLoading={fetchingGetProvider}
                                    providerContactLoading={fetchingGetProviderContact}
                                    places={places}
                                />
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdatePurchaseOrder}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    
                </div>
            </div>
    
            <>
                <PurchaseOrderDetailContainer
                    purchaseOrderId={parseInt(id)}
                    updateReference={updateReference}
                    setUpdateReference={setUpdateReference}
                />
            </>
            
            
            <PurchaseOrderChangeStatus
                status={String(purchaseOrder?.status)}
                statuses={statuses}
                requestChangeStatus={changeStatus}
                fetchingChangeStatus={fetchingChangeStatusPurchaseOrder}
            />

             
        </>
    );
};

export default PurchaseOrderEdit;
