import { Incoterm } from './Incoterm';
import { PaymentCondition } from './PaymentCondition';
import { PaymentMethod } from './PaymentMethod';
import { PaymentType } from './PaymentType';
import { Provider } from './Provider';
import { Society } from './Society';

export interface ServiceManifest {
    id?: number;
    purchase_order_id?: number;
    society?: Society
society_name?: string;
society_fantasy_name?: string;
    society_id?: number;
    provider?: Provider
provider_name?: string;
provider_fantasy_name?: string;
    provider_id?: number;
    destiny?: string;
    incoterm?: Incoterm;
    incoterm_id?: number;
    payment_method?: PaymentMethod;
    payment_method_id?: number;
    payment_condition?: PaymentCondition;
    payment_condition_selected?: string;
    created_at?: string;
    status?: string;
    file?: File;
    folio?: string;
}

export interface ServiceManifestDataForm {
    id?: number;
    purchase_order_id?: number;
    purchase_order?: string;
    society?: string;
    society_id?: number;
    provider?: string;
    provider_id?: number;
    service?: string;
    service_id?: number;
    destiny?: string;
    incoterm?: string;
    incoterm_id?: number;
    payment_method?: string;
    payment_condition?: string;
    created_at?: string;
    status?: string;
    society_rut?: string;
    society_business_name?: string;
    provider_rut?: string;
    provider_business_name?: string;
    days_count?: number;
    file?: File;
    file_url?: string;
    folio?: string;
    issue_date?: string;
    delivery_date?: string;
    gross_total?: number;
    taxes?: number;
    tax_total?: number;
    all_accounted?: boolean;
    daily_carrier_id?: number;
    daily_carrier?: string;
    total_guide?: number;
    dispatch_program_id?: number;
    purchase_order_detail_id?: number;
    cost_center?: string;
    interlocutor_id?: number;

    gross_total_calculated?: number;
    taxes_calculated?: number;
    tax_total_calculated?: number;

    currency?: string;


}

export interface ServiceManifestFilters {
    id?: number | null;
    purchase_order_id?: number | '';
    society?: string | '';
    provider?: string | '';
    status?: string | '';
    created_at_from?: string | '';
    created_at_until?: string | '';
    issue_date_from?: string | '';
    issue_date_until?: string | '';
    delivery_date_from?: string | '';
    delivery_date_until?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
    table_name?: string;
}

export const defaultServiceManifestDataForm: ServiceManifestDataForm = {
    society_id: 0,
    provider_id: 0,
    destiny: '',
    incoterm_id: 0,
    payment_method: '',
    payment_condition: ''
};

export interface ServiceManifestStatus {
    id?: string;
    name?: string;
    prev?: number | string;
    next?: number | string;
}
