import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';

import { ServiceManifestDataForm } from '../../../app/models/ServiceManifest';
import PurchaseOrderDetailOnDispatchProgramDataTable from './tables/PurchaseOrderDetailOnDispatchProgramDataTable';
import { PurchaseOrderDetailDataForm, PurchaseOrderDetailFilters } from '../../../app/models/PurchaseOrderDetail';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import { toast } from 'react-toastify';
import useServiceManifestService from '../../../app/services/hooks/useServiceManifestService';

import { Tab, Tabs } from 'react-bootstrap';
import ServiceManifestForm from './forms/ServiceManifestForm';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useProviderService from '../../../app/services/hooks/useProviderService';
import useInterlocutorService from '../../../app/services/hooks/useInterlocutorService';
import { Provider, ProviderFilters } from '../../../app/models/Provider';
import { Service } from '../../../app/models/Service';
import InterlocutorOnDispatchProgramDataTable from './tables/InterlocutorOnDispatchProgramDataTable';
import { Interlocutor, InterlocutorFilters } from '../../../app/models/Interlocutor';
import useInterlocutorServiceManifestService from '../../../app/services/hooks/useInterlocutorServiceManifestService';

interface Props {
    dispatch_program_id: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    onDelete?: () => void;
    isShow?: boolean;
}

const ServiceManifestCreateOnDispatchProgram = ({
    dispatch_program_id,
    onSaved,
    onCancel,
    onError,
    onDelete,
    isShow=false
}: Props) => {

    const { navigationPage } = useNavigationPage();

    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);

    const history = useHistory();

    const {
        fetchingGetPurchaseOrderDetailsFinished, 
        getPurchaseOrderDetailsFinished
    } = usePurchaseOrderDetailService();

    const {
        fetchingStoreServiceManifestOnDispatchProgram,
        storeServiceManifestOnDispatchProgram,
    } = useServiceManifestService()

    const {
        getDailyCarrierProvidersOnDispatchProgram,
        fetchingGetDailyCarrierProvidersOnDispatchProgram
    } = useProviderService()

    const {
        getInterlocutorServiceManifests,
        fetchingGetServiceManifests,
        fetchingDeleteServiceManifestByDispatchProgram,
        deleteServiceManifestByDispatchProgram
    } = useInterlocutorServiceManifestService()

    const {
        getInterlocutorsByProviderAndService,
        fetchingGetInterlocutorsByProviderAndService
    } = useInterlocutorService()

    const [providers, setProviders] = useState<Provider[]>([]);
    const [services, setservices] = useState<Service[]>([]);
    const [InterlocutorsWithServiceManifest, setInterlocutorsWithServiceManifest] = useState<Interlocutor[]>([]);
    const [serviceManifest, setServiceManifest] = useState<ServiceManifestDataForm>(
        {
            dispatch_program_id: dispatch_program_id,
            total_guide: 0,
            daily_carrier: ''
        }
    );
    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<PurchaseOrderDetailDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [dailyCarrierProviderTotalRows, setDailyCarrierProviderTotalRows] = useState(0);
    const [productName, setProductName] = useState<string>('');

    const [errorFields, setErrorFields] = useState<any>();

    const [interlocutors, setInterlocutors] = useState<Interlocutor[]>([]);
    const providerIdSelected = useRef<number | undefined>(0);
    const serviceIdSelected = useRef<number | undefined>(0);
    const interlocutorIdSelected = useRef<number | undefined>(undefined);

    const [filter, setFilter] = useState<PurchaseOrderDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });
    const [interlocutorFilter, setInterlocutorFilter] = useState<InterlocutorFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [toggleCleared, setToggleCleared] = useState<boolean>(false);

    const resetContextActions = () => {

        setToggleCleared(!toggleCleared);
     
    };

    useEffect(() => {

        reloadTable(interlocutorIdSelected.current ?? 0, false);

    }, [filter]);

    useEffect(() => {

        reloadDailyCarrierProviderTable();

    }, [interlocutorFilter]);

    useEffect(() => {
        getDataToForm();
    }, [])

    const resetForm = () => {
        setServiceManifest(
            {
                ...serviceManifest,
                provider_id: undefined,
                service_id: undefined,
                interlocutor_id: undefined,
                purchase_order_detail_id: undefined,
                total_guide: 0,
                issue_date: '',
            }
        )
    }

    useEffect(() => {
            if (serviceManifest.service_id == undefined) {
                serviceIdSelected.current = undefined;
            }
    
            if (serviceManifest.provider_id == undefined) {
                providerIdSelected.current = undefined;
            }
    
            if (
                serviceManifest.service_id ||
                serviceManifest.provider_id &&
                (serviceManifest.service_id != serviceIdSelected.current ||
                    serviceManifest.provider_id != providerIdSelected.current)
            ) {
                getInterlocutorsByProviderAndService(
                    dispatch_program_id,
                    serviceManifest.provider_id ?? 0,
                    serviceManifest.service_id ?? 0,
                    {
                        onSuccess: (response: ServiceResponse) => {
                            serviceIdSelected.current = serviceManifest.service_id ?? 0;
                            providerIdSelected.current = serviceManifest.provider_id ?? 0;
                            setErrorFields(undefined);
                            setInterlocutors(response.data.interlocutors);
                        },
                        onError: (response: ServiceResponse) => {
                            setServiceManifest({
                                ...serviceManifest,
                                service_id: serviceIdSelected.current,
                                provider_id: providerIdSelected.current
                            });
                            if (hideLoading) hideLoading();
                            toast.error(response.message);
                        }
                    }
                );
            }
        }, [serviceManifest.service_id, serviceManifest.provider_id]);


    useEffect(() => {
        if (serviceManifest.interlocutor_id == undefined) {
            interlocutorIdSelected.current = undefined;
        }


        if (
            serviceManifest.interlocutor_id &&
            serviceManifest.interlocutor_id != interlocutorIdSelected.current
        ) {
            reloadTable(serviceManifest.interlocutor_id, false);
        }
    }, [serviceManifest.interlocutor_id]);


    const getDataToForm = () => {
        getDailyCarrierProvidersOnDispatchProgram(dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {
                setProviders(response.data.providers_daily_carriers);
                setservices(response.data.services);
                setProductName(response.data.product);
                
            },
            onError(response: ServiceResponse) {
                toast.error(response.message);
                if (onError) onError();
            },
        });
    };


    const reloadTable = (interlocutorId: number| undefined, resetFilter: boolean) => {
        if(!interlocutorId){
            
            interlocutorIdSelected.current = undefined;
            if(resetFilter){
                resetForm();
            }
            else{
                setServiceManifest(
                    {
                        ...serviceManifest,
                        purchase_order_detail_id: undefined,
                        interlocutor_id: undefined
                    }
                )
                
            }

            resetContextActions();
                    
            setPurchaseOrderDetails([]);
            setTotalRows(0);

        }
        else{
            getPurchaseOrderDetailsFinished(filter, dispatch_program_id, interlocutorId, {
                onSuccess: (response: ServiceResponse) => {
    
                    interlocutorIdSelected.current = interlocutorId;
    
                    setErrorFields(undefined);
                    if(resetFilter){
                        resetForm();
                    }
                    else{
                        setServiceManifest(
                            {
                                ...serviceManifest,
                                purchase_order_detail_id: undefined,
                            }
                        )
                        
                    }
                    
                    resetContextActions();
                    
                    setPurchaseOrderDetails(response.data.purchase_order_details);
                    setTotalRows(response.data.total_rows);
               
                },
                onError(response: ServiceResponse) {
    
                    toast.error(response.message);
                    
                },
            });
        }
        
    };

    const reloadDailyCarrierProviderTable = () => {
        getInterlocutorServiceManifests(interlocutorFilter, dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {
                
                setInterlocutorsWithServiceManifest(response.data.interlocutors);
                setDailyCarrierProviderTotalRows(response.data.total_rows);
               
            },
            onError(response: ServiceResponse) {
                toast.error(response.message);
            },
        });
    };

    const goServiceManifest = (serviceManifestId: number) => {

        navigationPage('/service-manifests/' + serviceManifestId + '/edit', true);

    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Generando hoja de entrada...');
        storeServiceManifestOnDispatchProgram(serviceManifest, {
            onSuccess: (response: ServiceResponse) => {

                if (hideLoading) hideLoading();
                setErrorFields(undefined);
             
                toast.success(response.message);
                reloadTable(undefined, true);
                getDataToForm();
                reloadDailyCarrierProviderTable();
                if (onSaved) onSaved();
                
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

   
    const destroy = (id: number, serviceManifestId: number, position: number) => {

        const _text = 'Está a punto de eliminar la hoja de entrada #'+ serviceManifestId + ' del interlocutor en posición #' +position 

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando hoja de entrada...');
                        deleteServiceManifestByDispatchProgram(id, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                
                                reloadTable(interlocutorIdSelected.current ?? 0, false);
                                getDataToForm();
                                reloadDailyCarrierProviderTable();
                                if(onDelete)onDelete();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La hoja de entrada no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <div className="row mb-4">
                <div className="col-12">
                    <ServiceManifestForm
                        form={serviceManifest} 
                        setForm={setServiceManifest}
                        providers={providers}
                        services={services}
                        interlocutors={interlocutors}
                        errorFields={errorFields} 
                        isShow={isShow}
                        loadingInterlocutor={fetchingGetInterlocutorsByProviderAndService}
                    />
                    <hr />
                </div>
            </div>
            <Tabs defaultActiveKey="generate-data" id="service-manifest-tabs" className="mt-3 mb-3">
                <Tab eventKey="generate-data" title="Ordenes de compra">
                <>
                    
                    <div className="row justify-content-between align-items-center">

                        <div className="col-auto">
                            <input
                                name="product"
                                id="product"
                                type="text"
                                className="form-control"
                                value={productName}
                                readOnly={true}
                            />
                        </div>
                        {
                            !isShow &&
                            <div className="col-auto mt-2">
                                <ButtonSaveForm title='Generar' callbackSave={store} locked={fetchingStoreServiceManifestOnDispatchProgram} />
                            </div>
                        }
                        
                    </div>
                    <hr />
                    <PurchaseOrderDetailOnDispatchProgramDataTable
                        form= {serviceManifest}
                        setForm={setServiceManifest}
                        purchaseOrderDetails={purchaseOrderDetails}
                        loading={fetchingGetPurchaseOrderDetailsFinished}
                        totalRows={totalRows}
                        setFilter={setFilter}
                        filter={filter}
                        toggleCleared={toggleCleared}
                        resetContextActions={resetContextActions}
                    />
            
                </>  
                </Tab>
                <Tab eventKey="Interlocutores con hojas de entrada" title="Interlocutores con hojas de entrada">

                    <InterlocutorOnDispatchProgramDataTable
                        interlocutors={InterlocutorsWithServiceManifest}
                        loading={fetchingGetServiceManifests}
                        totalRows={dailyCarrierProviderTotalRows}
                        setFilter={setInterlocutorFilter}
                        filter={interlocutorFilter}
                        deleteServiceManifest={destroy}
                        showServiceManifest={goServiceManifest}
                        isShow={isShow}
                    />
                </Tab>
           </Tabs>
        </>
    );
};

export default ServiceManifestCreateOnDispatchProgram;
