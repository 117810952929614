import React, { useContext } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { PaymentStatusesStatus } from '../../../app/models/PaymentStatus';
import usePaymentStatusService from '../../../app/services/hooks/usePaymentStatusService';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import useTransportationOrderService from '../../../app/services/hooks/useTransportationOrderService';
import { TransportationOrderStatuses } from '../../../app/shared/enums';

interface Props {
    transportationOrderId: number;
    statuses: TransportationOrderStatus[];
    selectedStatus: TransportationOrderStatus;
    callbackSuccess?: () => void;
 }

const TransportationOrderChangeStatus = ({
    transportationOrderId,
    statuses,
    selectedStatus,
    callbackSuccess
}: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);

    const [selected, setSelected] = React.useState<TransportationOrderStatus>(selectedStatus);

    const {
        canChangeStatusTransportationOrder,
        fetchingCanChangeStatusTransportationOrder,
        changeStatusTransportationOrder,
        fetchingChangeStatusTransportationOrder,
        changeStatusToActiveTransportationOrder,
        fetchingChangeStatusToActiveTransportationOrder,
        changeStatusToFinishTransportationOrder,
        fetchingChangeStatusToFinishTransportationOrder,
        changeStatusToAnnulledTransportationOrder,
        fetchingChangeStatusToAnnulledTransportationOrder,
    } = useTransportationOrderService();


    // const requestChangeStatus = (valueStatus: string) => {
    //     if (showLoading) showLoading('loading', 'Cargando...');

    //     if (valueStatus == selected.id){
    //         if (hideLoading) hideLoading();
    //         return null;
    //     }
        
    //     const _status = statuses.find((status: TransportationOrderStatus) => status.id === valueStatus);
       
    //     if(!_status){
    //         if (hideLoading) hideLoading();
    //         return null;
    //     }
    //     setSelected(_status!);
    //     canChangeStatusTransportationOrder(transportationOrderId, valueStatus, {
    //         onSuccess: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();
    //             changeStatus(valueStatus, response.message);
    //         },
    //         onError: (response: ServiceResponse) => {
    //             setSelected(selectedStatus);
    //             if (hideLoading) hideLoading();
    //             useReactConfirmAlert().errorAlert({
    //                 title: 'Error',
    //                 message: response.message,
    //                 message_list: response.data?.message_list ?? []
    //             });
    //             toast.error(response.message);
    //         }
    //     });
    // };

    
    const changeStatusToActive = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado del pedido #${transportationOrderId} a ${status_value}.`;
     
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusToActiveTransportationOrder(transportationOrderId, _status.id, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                setSelected(_status ?? selected);

                                if (callbackSuccess) callbackSuccess();
                            },
                            onError: (response: ServiceResponse) => {
                                setSelected(selectedStatus);
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            setSelected(selectedStatus);
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado del pedido no se ha cambiado.'
                            });
                        }, 0);
                    }
                },
            },
            onClickOutsideFunction: () => {
                setSelected(selectedStatus);
            }
        });
    };


    const changeStatusToFinish = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado del pedido #${transportationOrderId} a ${status_value}.`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusToFinishTransportationOrder(transportationOrderId, _status.id, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                setSelected(_status ?? selected);

                                if (callbackSuccess) callbackSuccess();
                            },
                            onError: (response: ServiceResponse) => {
                                setSelected(selectedStatus);
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            setSelected(selectedStatus);
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado del pedido no se ha cambiado.'
                            });
                        }, 0);
                    }
                },
            },
            onClickOutsideFunction: () => {
                setSelected(selectedStatus);
            }
        });
    };

    const changeStatusToAnnulled = (_status: any) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de Anular el pedido #${transportationOrderId}, este cambiará a estado Activo.`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusToAnnulledTransportationOrder(transportationOrderId, _status.id, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                setSelected(_status ?? selected);

                                if (callbackSuccess) callbackSuccess();
                            },
                            onError: (response: ServiceResponse) => {
                                setSelected(selectedStatus);
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            setSelected(selectedStatus);
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado del pedido no se ha cambiado.'
                            });
                        }, 0);
                    }
                },
            },
            onClickOutsideFunction: () => {
                setSelected(selectedStatus);
            }
        });
    };

    const changeStatus = (value: string) => {
        if (value == selected.id) return null;
        const _status = statuses.find((status: TransportationOrderStatus) => status.id == value);

        if(_status?.id === TransportationOrderStatuses.ACTIVO){ 
      
            changeStatusToActive(_status);
        }
        else if(_status?.id === TransportationOrderStatuses.FINALIZADO){ 
            changeStatusToFinish(_status);
        }
        else if(_status?.id === TransportationOrderStatuses.ANULADO){ 
            changeStatusToAnnulled(_status);
        }
    }
    

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(String(e.target.value))}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses
                .filter((status) => status.id !== selectedStatus.id) 
                .map((status) => (
                <option key={status.id} value={status.id}>
                    {status.name}
                </option>
            ))}
        </select>
    );
};

export default TransportationOrderChangeStatus;
