import React from 'react';
import { PurchaseOrderDetailDataForm } from '../../../../app/models/PurchaseOrderDetail';
import { Items, ServiceType } from '../../../../app/shared/enums';
import { momentParseDateTime } from '../../../../helpers';

interface Props {
    purchaseOrderDetailDataForm: PurchaseOrderDetailDataForm;
    setPurchaseOrderDetailDataForm: (purchaseOrderDetail: PurchaseOrderDetailDataForm) => void;
    itemType: string;
}

const PurchaseOrderDetailFormShow = ({
    purchaseOrderDetailDataForm,
    itemType,
}: Props) => {
  
    if (!purchaseOrderDetailDataForm) return null;

    return (
        <>

            {
                itemType == Items.PRODUCT?

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Producto</label>
                        <input
                            className="form-control"
                            type="text"
                            name="item_name"
                            id="item_name"
                            value={purchaseOrderDetailDataForm.item_name}
                            readOnly={true}
                        />
                      
                    </div>
                </div>
                :
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Servicio</label>
                        <input
                            className="form-control"
                            type="text"
                            name="item_name"
                            id="item_name"
                            value={purchaseOrderDetailDataForm.item_name}
                            readOnly={true}
                        />
                      
                    </div>
                </div>
            }

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Fecha de entrega</label>
                        <input
                            className="form-control"
                            type="text"
                            name="delivery_date"
                            id="delivery_date"
                            value={purchaseOrderDetailDataForm.delivery_date}
                            readOnly={true}
                        />
                      
                    </div>
                </div>
                
                {/* DESCOMENTAR_PRODUCCION */}
                {/* <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Centro de costo</label>
                        <input
                            className="form-control"
                            type="text"
                            name="cost_center_name"
                            id="cost_center_name"
                            value={purchaseOrderDetailDataForm.cost_center_name}
                            readOnly={true}
                        />
                      
                    </div>
                </div> */}

            
            <div className="mb-2 row">
                    <label className="col-md-12 col-form-label">Precio</label>
                    <div className="col-md-12">
                        <div className="row g-1">
                            <div className="col-6">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="price"
                                    id="price"
                                    value={purchaseOrderDetailDataForm.price}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="currency_price"
                                    id="currency_price"
                                    value={purchaseOrderDetailDataForm.currency_price}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="unit_price"
                                    id="unit_price"
                                    value={purchaseOrderDetailDataForm.unit_price}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            
            
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Cantidad</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="number"
                                name="quantity"
                                id="quantity"
                                value={purchaseOrderDetailDataForm.quantity}
                                readOnly={true}
                            />
                         </div>

                         <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="unit"
                                id="unit"
                                value={purchaseOrderDetailDataForm.unit}
                                readOnly={true}
                            />
                         </div>

                    </div>
                </div>
            </div>

            { purchaseOrderDetailDataForm.service_type === ServiceType.TRANSPORT &&
                <>
                <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tramo</label>
                    <input
                        className="form-control"
                        type="text"
                        name="route_segment"
                        id="route_segment"
                        value={purchaseOrderDetailDataForm.route_segment}
                        readOnly={true}
                    />
                  
                </div>
                </div>
           
                </>
                
                
            }
    

        </>
       
    
    );
};

export default PurchaseOrderDetailFormShow;
