import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import TaxDocumentForm from './forms/TaxDocumentForm';
import { TaxDocumentPaymentDataForm } from '../../../app/models/TaxDocument';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    taxDocument: any;
    setTaxDocument: (taxDocument: any) => void;
    availableStatuses: TaxDocumentPaymentDataForm[];  
    errorFields?: any;
    updated: boolean;
    disabled: boolean;
    societies: Society[];
    clients: Client[];
    loadingClient?: boolean;
    loadingPaymentStatus?: boolean;
  }

const TaxDocumentFormContainer = ({
    fetching,
    action,
    cancel,
    taxDocument,
    setTaxDocument,
    availableStatuses,
    updated = false,
    disabled= false,
    errorFields = null,
    societies,
    clients,
    loadingClient,
    loadingPaymentStatus
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <TaxDocumentForm
                        taxDocument={taxDocument}
                        setTaxDocument={setTaxDocument}
                        societies={societies}
                        clients={clients}
                        availableStatuses={availableStatuses}  
                        errorFields={errorFields}
                        disabled={updated}
                        loadingClient={loadingClient}
                        loadingPaymentStatus={loadingPaymentStatus}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default TaxDocumentFormContainer;
