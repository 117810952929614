import React from 'react';
import { ServiceFilters } from '../../../../app/models/Service';
import { DailyCarrierFilters } from '../../../../app/models/DailyCarrier';

type Props = {
    filter: DailyCarrierFilters;
    setFilter: (filter: DailyCarrierFilters) => void;
};

const ServiceFilter = ({ filter, setFilter }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    
    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };


    return (
        <div className="row">
            <div className="row">
            <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="id">
                            ID
                        </label>
                        <input
                            title="Nro de Documento"
                            type="number"
                            id="id"
                            name="id"
                            className="form-control form-control-sm"
                            value={filter.id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider_rut">
                            Proveedor Rut
                        </label>
                        <input
                            title="provider_rut"
                            type="text"
                            id="provider_rut"
                            name="provider_rut"
                            className="form-control form-control-sm"
                            value={filter.provider_rut}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider_name">
                            Razón Social Proveedor
                        </label>
                        <input
                            title="provider_name ID"
                            type="text"
                            id="provider_name"
                            name="provider_name"
                            className="form-control form-control-sm"
                            value={filter.provider_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="carrier_rut">
                            Conductor Rut
                        </label>
                        <input
                            title="Rut Conductor"
                            type="text"
                            id="carrier_rut"
                            name="carrier_rut"
                            className="form-control form-control-sm"
                            value={filter.carrier_rut}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="carrier_name">
                            Conductor Nombre
                        </label>
                        <input
                            title="carrier_name"
                            type="text"
                            id="carrier_name"
                            name="carrier_name"
                            className="form-control form-control-sm"
                            value={filter.carrier_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck_id">
                            Camión ID
                        </label>
                        <input
                            title="Camión ID"
                            type="number"
                            id="truck_id"
                            name="truck_id"
                            className="form-control form-control-sm"
                            value={filter.truck_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck_patent">
                            Camión Patente
                        </label>
                        <input
                            title="Patente"
                            type="text"
                            id="truck_patent"
                            name="truck_patent"
                            className="form-control form-control-sm"
                            value={filter.truck_patent}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="hopper_id">
                            Tolva ID
                        </label>
                        <input
                            title="Tolva"
                            type="number"
                            id="hopper_id"
                            name="hopper_id"
                            className="form-control form-control-sm"
                            value={filter.hopper_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="hopper_patent">
                            Tolva Patente
                        </label>
                        <input
                            title="Tolva"
                            type="text"
                            id="hopper_patent"
                            name="hopper_patent"
                            className="form-control form-control-sm"
                            value={filter.hopper_patent}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            </div>
        </div>

        
    );
};

export default ServiceFilter;
