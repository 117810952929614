import { TaxDocumentDetail } from '../../../../app/models/TaxDocumentDetail';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import TaxDocumentFilter from './TaxDocumentFilter';
import { TaxDocumentDetailFilters } from '../../../../app/models/TaxDocumentDetail';
import { PaymentStatusDataForm, PaymentStatusesStatus } from '../../../../app/models/PaymentStatus';
import PaymentStatusChangeStatus from '../../../PaymentStatus/components/PaymentStatusChangeStatus';

interface Props {
    taxDocumentDetail: PaymentStatusDataForm[];
    totalRows: number;
    loading?: boolean;
    filter: TaxDocumentDetailFilters;
    setFilter: (filter: TaxDocumentDetailFilters) => void;
    edit?: (id: number) => void;
    destroy?: (id: number) => void;
    show?: (id: number) => void;
    callbackSuccess: () => void;
    statuses: PaymentStatusesStatus[];

}

const TaxDocumentDetailDataTable = ({
    taxDocumentDetail,
    totalRows,
    loading = false,
    filter,
    setFilter,
    edit,
    destroy,
    show,
    callbackSuccess,
    statuses,
}: Props) => {

    const columns = [
        // {
        //     name: 'Pos',
        //     selector: (row: TaxDocumentDetail) => row.id,
        //     sortable: true,
        //     sortField: 'id',
        //     cell: (row: TaxDocumentDetail) => <span>{row.id}</span>
        // },
        {
            name: 'Glosa',
            selector: (row: TaxDocumentDetail) => row.payment_status_id,
            sortable: true,
            sortField: 'payment_status_id',
            cell: (row: TaxDocumentDetail) => (
                <span>
                    {row.document_type === 'Note' ? `TDD #${row.id}` : `EDP #${row.id}`}
                </span>
            ),
        },
    ...(taxDocumentDetail.some((row) => row.document_type !== 'Note') ? [
        {
            name: 'Cant',
            selector: () => 1,
            sortable: true,
            cell: () => <span>1</span>
        }
    ] : []),
    ...(taxDocumentDetail.some((row) => row.document_type === 'Note') ? [
        {
            name: 'Cant',
            selector: (row: TaxDocumentDetail) => row.cant,
            sortable: true,
            sortField: 'cant',
            cell: (row: TaxDocumentDetail) => <span>{row.cant}</span>
        },
    ] : []),

        {
            name: 'Valor Un',
            selector: (row: TaxDocumentDetail) => row.total_unit,
            sortable: true,
            sortField: 'total_unit',
            cell: (row: TaxDocumentDetail) => <span>{row.total_unit}</span>
        },
        {
            name: 'Neto',
            selector: (row: TaxDocumentDetail) => row.total_neto,
            sortable: true,
            sortField: 'total_neto',
            cell: (row: TaxDocumentDetail) => <span>{row.total_neto}</span>
        },
        {
            name: 'Impuesto',
            selector: (row: TaxDocumentDetail) => row.total_tax,
            sortable: true,
            sortField: 'total_tax',
            cell: (row: TaxDocumentDetail) => <span>{row.total_tax}</span>
        },
        {
            name: 'Total',
            selector: (row: any) => row.total,
            sortable: true,
            sortField: 'total',
            cell: (row: any) => <span>{row.total}</span>
        },

         // Condicional para la columna Estado
        ...(taxDocumentDetail.some((row) => row.status) ? [
            {
                name: 'Estado',
                selector: (row: any) => row?.status,
                sortable: true,
                sortField: 'status',
                cell: (row: any) =>
                    row?.status ? (
                        <div>
                            <PaymentStatusChangeStatus
                                paymentStatusId={row.id!}
                                statuses={row.statuses || []}
                                selectedStatus={row.status}
                                callbackSuccess={callbackSuccess}
                            />
                        </div>
                    ) : (
                        <></>
                    ),
            }
        ] : []),
        {
            name: 'Acciones',
            selector: (row: TaxDocumentDetail) => row.id,
            sortable: false,
            cell: (row: TaxDocumentDetail) => (
                <div>
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            {/* <div className="row mt-3">
                <div className="col">
                    <TaxDocumentFilter filter={filter} setFilter={setFilter} />
                </div>
            </div> */}

            <DefaultDataTable
                columns={columns}
                data={taxDocumentDetail}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default TaxDocumentDetailDataTable;
